/** @format */

import React, { useState } from "react";
import "./addBranch.css";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";

export default function AddBranches() {
  const { hasPermission } = usePermissions();
  const userId = Cookies.get("employee_no");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const navigate = useNavigate();

  const [data, setData] = useState({
    branch_name: "",
    branch_address: "",
    branch_status: "",
    branch_description: "",
    branch_update_date: formattedDate,
    branch_update_user_id: userId,
  });

  const CancelHandler = () => {
    setData({
      branch_name: "",
      branch_address: "",
      branch_status: "",
      branch_description: "",
    });
    navigate(-1);
  };

  const SubmitHandler = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/branch/addBranch`,
        {
          branch_name: data.branch_name,
          branch_address: data.branch_address,
          branch_status: data.branch_status,
          branch_description: data.branch_description,
          branch_update_date: formattedDate,
          branch_update_user_id: userId,
        }
      );
      console.log(res.data);
      if (res.status === 200 || res.status === 201) {
        alert("Branch Added Successfully");
        CancelHandler();
      }
    } catch (error) {
      if (error.response.status === 409) {
        alert("Branch Already Exist");
      } else if (error.response.status === 400) {
        alert("Branch Not Added");
      } else if (error.response.status === 500) {
        alert("Internal Server Error");
      } else if (error.response.status === 404) {
        alert("Branch Not Found");
      } else if (error.response.status === 403) {
        alert("Forbidden");
      } else if (error.response.status === 401) {
        alert("Unauthorized");
      }
    }
  };
  return (
    <div className="p-10 bg-background">
      <p className="header-1"> ADD BRANCH</p>
      <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
        <p className="header-1 text-center">Enter Branch Details</p>
        <div className="w-full flex flex-wrap flex-col justify-center items-center gap-y-10 my-10">
          <div className="flex items-center justify-between w-[700px]">
            <p className="label-1">Branch Name</p>
            <p className="label-1">:</p>
            <input
              className="w-[434px] h-[46px] rounded-[23px] border border-[#00000036]"
              type="text"
              value={data.branch_name}
              onChange={(e) => {
                const Data = { ...data };
                Data.branch_name = e.target.value;
                setData(Data);
              }}
            />
          </div>
          <div className="flex items-center justify-between w-[700px]">
            <p className="label-1">Branch Address</p>
            <p className="label-1">:</p>
            <input
              className="w-[434px] h-[46px] rounded-[23px] border border-[#00000036]"
              type="text"
              value={data.branch_address}
              onChange={(e) => {
                const Data = { ...data };
                Data.branch_address = e.target.value;
                setData(Data);
              }}
            />
          </div>
          <div className="flex items-center justify-between w-[700px]">
            <p className="label-1">Branch Description</p>
            <p className="label-1">:</p>
            <input
              className="w-[434px] h-[46px] rounded-[23px] border border-[#00000036]"
              type="text"
              value={data.branch_description}
              onChange={(e) => {
                const Data = { ...data };
                Data.branch_description = e.target.value;
                setData(Data);
              }}
            />
          </div>
        </div>
        <div></div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={CancelHandler}
        >
          Cancel
        </button>

        <button
          className="w-[118px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={SubmitHandler}
        >
          Add
        </button>
      </div>
    </div>
  );
}
