/** @format */

import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import ApprovalTable from "../../../components/approval/approvalTable";

const approveTableData = [
  { empNo: "000001", name: "Testing Name", feedBack: "Test Feed Back" },
  { empNo: "000002", name: "Testing Name", feedBack: "Test Feed Back" },
  { empNo: "000003", name: "Testing Name", feedBack: "Test Feed Back" },
  { empNo: "000004", name: "Testing Name", feedBack: "Test Feed Back" },
  { empNo: "000005", name: "Testing Name", feedBack: "Test Feed Back" },
  { empNo: "000006", name: "Testing Name", feedBack: "Test Feed Back" },
];

const selectOptions = [
  { label: "Department", value: "department" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const monthOptions = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
];

const yearOptions = [
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
];

const Approval_1 = () => {
  const [formValues, setFormValues] = useState({
    selectOption: "department",
    search: "",
    joinedMonth: "1",
    joinedYear: "2021",
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  console.log("form Values => ", formValues);

  return (
    <div className="p-10 bg-background h-screen overflow-y-auto">
      <p className="header-1">Pos Bills</p>
      <div className="w-full flex items-center justify-between my-10">
        <div className="w-[350px] flex items-center justify-between">
          <div>
            <div className="w-[320px] flex items-center justify-between my-2">
              <select
                className="w-[228px] h-[53px] rounded-[46px] bg-white border border-[#797979] px-5 "
                id="select-department"
                name="selectOption"
                value={formValues.selectOption}
                onChange={handleFormChange}
              >
                {selectOptions.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
                {/* <option value={'department'}>Department</option> */}
              </select>
              <p className="label-1">Select</p>
            </div>

            <div className="w-[320px] flex items-center justify-between my-2">
              <input
                className="w-[228px] h-[53px] rounded-[46px] bg-white border border-[#797979] px-5 my-2"
                id="id-select"
                name="search"
                value={formValues.search}
                onChange={handleFormChange}
              />
              <p className="label-1">search</p>
            </div>

            <div className="w-[320px] flex items-center justify-between my-2">
              <select
                className="w-[228px] h-[53px] rounded-[46px] bg-white border border-[#797979] px-5 my-2"
                name="joinedMonth"
                value={formValues.joinedMonth}
                onChange={handleFormChange}
              >
                {monthOptions.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <p className="label-1">Select</p>
            </div>

            <div className="w-[320px] flex items-center justify-between my-2">
              <select
                className="w-[228px] h-[53px] rounded-[46px] bg-white border border-[#797979] px-5 my-2"
                id="my-select"
                name="joinedYear"
                value={formValues.joinedYear}
                onChange={handleFormChange}
              >
                {yearOptions.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <p className="label-1">Select</p>
            </div>
          </div>
        </div>

        <div>
          <div className="border rounded-[20px] px-6 min-h-126 font-bold text-xl w-[318px] h-[145px] bg-[#FFFFFF]">
            <p className="label-1">Upcoming Retirements</p>
            <button className="w-[138px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4 text-white font-bold text-xl px-5 my-6">
              Click here
            </button>
          </div>
        </div>
      </div>

      <ApprovalTable data={approveTableData} />
    </div>
  );
};

export default Approval_1;
