/** @format */

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import AsyncSelect from "react-select/async";
import Arrow from "./../../../../Assets/icons/down-arrow.png";
import Cookies from "js-cookie";

const Appoint_Members = () => {
  const [selectComType, setSelectComType] = useState("");
  const [appointDate, setAppointDate] = useState("");
  const [comTitle, setComTitle] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemArrow, setItemArrow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const userId = Cookies.get("employee_no");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];

  const handleItemSearch = async (inputValue) => {
    if (inputValue !== "") {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_ENDPOINT
          }/v1/hris/employees/get-by-name?employee_fullname=${encodeURIComponent(
            inputValue
          )}`
        );

        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Get items:", data);

        // Extract relevant data from response to populate options
        const options = data.map((item) => ({
          value: item.employee_no,
          label: `${item.employee_no} - ${item.employee_fullname}`,
          employee_fullname: item.employee_fullname, // Combining inventory_batch_id and item_name
        }));

        return options;
      } catch (error) {
        console.error(error);
      }
    } else {
      return [];
    }
  };

  const handleRoleChange = (index, selectedRole) => {
    const updatedData = [...tableData];
    updatedData[index].employee_role = selectedRole; // Update the role for the specific employee
    setTableData(updatedData); // Update the state with the new data
  };

  const handleItemSelect = (selectedOption) => {
    setSelectedItem(selectedOption);

    // Check if the item already exists in the tableData
    const itemExists = tableData.some(
      (item) => item.value === selectedOption.value
    );

    if (!itemExists) {
      // Add selected item to tableData
      setTableData((prevTableData) => [
        ...prevTableData,
        {
          employee_fullname: selectedOption.employee_fullname,
          employee_no: selectedOption.value,
        },
      ]);
    }
  };

  const handleSubmit = async () => {
    const members = tableData.map((member) => ({
      employee_id: member.employee_no,
      role: member.employee_role,
    }));
    // Define the committee data to be sent in the request body
    const committeeData = {
      committee_title: comTitle,
      committee_type: selectComType,
      appointing_date: appointDate,
      create_by: userId,
      status: "PENDING",
      members,
    };

    try {
      // Send POST request with JSON data
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/supplyChain/committee/members/add`,
        committeeData, // sending committee data as JSON
        {
          headers: {
            "Content-Type": "application/json", // Set appropriate headers
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Committee successfully created");
      handleReset(); // Reset form if necessary
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to create Committee");
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0]; // Format date to 'YYYY-MM-DD'
    setAppointDate(formattedDate);
  };

  // Reset function
  const handleReset = () => {
    setTableData([]);
    setSelectComType("");
    setAppointDate("");
    setComTitle("");
    setSelectedItem("");
  };

  const customStylesItems = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "transparent",
      borderRadius: "100px",
      width: "900px",
      borderColor: state.isFocused ? "red" : "blacks",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "item name",
      color: "white",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
      display: "none",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
      width: "122%",
      position: "absolute",
      zIndex: "100",
      top: "30px",
      left: "-160px",
      borderRadius: "20px",
      overflow: "hidden",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Appoint Committees Members
        </p>
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Add Committee Members</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Committee Title</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={comTitle}
              onChange={(e) => setComTitle(e.target.value)}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Committee Type</label>
            <label className="label-1">:</label>
            <select
              value={selectComType}
              onChange={(e) => setSelectComType(e.target.value)}
              className="input-1 border border-[#00000036]"
            >
              <option value="">Select Committee Type</option>
              <option value="OPENING COMMITTEE">Opening Committee</option>
              <option value="TECHNICAL EVALUATION COMMITTEE">
                Technical Evaluation Committee
              </option>
              <option value="SAMPLE TESTING COMMITTEE">
                Sample Testing Committee
              </option>
              <option value="TENDER COMMITTEE">Tender Committee</option>
            </select>
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Appointing Date</label>
            <label className="label-1">:</label>
            <DatePicker
              className="input-1 border border-[#00000036]"
              placeholderText="Enter Appointing Date"
              selected={appointDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
      </div>

      <p className="header-1 mb-10">Member Details</p>

      <div className="w-full flex items-center justify-center px-12">
        <div className="bg-PrimaryColor1 w-full h-[82px] rounded-[100px] mx-20 flex items-center justify-between">
          <p className="label-1 ml-10 text-white">Employee Name :</p>
          <AsyncSelect
            styles={customStylesItems}
            cacheOptions
            loadOptions={handleItemSearch}
            onMenuOpen={() => setItemArrow(true)}
            onMenuClose={() => setItemArrow(false)}
            onChange={handleItemSelect}
            value={selectedItem}
          />
          <img
            src={Arrow}
            alt="arrow"
            className={
              itemArrow
                ? "mr-7 size-6 transition duration-500 invert"
                : "mr-7 size-6 transition rotate-180 duration-500 invert"
            }
          />
        </div>
      </div>

      <div className="flex flex-col justify-start  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
        <div className="w-full h-[86px] flex items-center justify-around bg-[#74d4f4] rounded-[46px] mr-5">
          <p className="w-10 p1 text-[#656565] text-center">#</p>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Employee ID</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Employee Name
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Role</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Action</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
          {tableData.length > 0
            ? tableData.map((item, index) => (
                <div key={index} className="w-full  flex justify-around ">
                  <div className="w-10 flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {index + 1}
                    </p>
                  </div>
                  <div className="w-[350px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.employee_no}
                    </p>
                  </div>
                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.employee_fullname}
                    </p>
                  </div>
                  <div className="w-[200px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center">
                      <select
                        value={item.employee_role || ""} // set value to item's current role
                        onChange={(e) =>
                          handleRoleChange(index, e.target.value)
                        }
                        className="input-1 border border-[#00000036]"
                      >
                        <option value="">Select Role</option>
                        <option value="Chairman">Chairman</option>
                        <option value="Member">Member</option>
                        <option value="Other">Other</option>
                      </select>
                    </p>
                  </div>
                  <div className="w-[200px] flex justify-center text-center">
                    <button
                      className="w-[full] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4"
                      onClick={() => {
                        let temp = [...tableData];
                        temp.splice(index, 1);
                        setTableData(temp);
                      }}
                    >
                      <p className="p2 text-white">delete</p>
                    </button>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <div className="w-full flex items-center justify-end gap-x-6 my-10 px-16">
        <button
          className="text-white font-bold text-2xl py-2 px-4 rounded-lg bg-[#AA4343]"
          onClick={handleReset}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className=" bg-[#00adef] hover:bg-[#495e33] text-white font-bold text-2xl py-2 px-4 rounded-lg"
        >
          Create
        </button>
      </div>
    </div>
  );
};

export default Appoint_Members;
