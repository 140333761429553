/** @format */

import React, { useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cloudIcon from "../../../../../Assets/icons/cloud-add.png";
import { useLocation, useNavigate, Usenavigate } from "react-router-dom";
import axios from "axios";

const Create_Commitee_Meeting_Report = () => {
  const location = useLocation();
  const tenderId = location.state;
  const navigate = useNavigate();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [theNatureOfCommitee, setThenNatureOfCommitee] = useState("");
  const [procurmentItem, setProcurmentItem] = useState("");
  const [procurement_name, setProcurement_name] = useState("");
  const [meeting_number, setMeeting_number] = useState("");
  const [purpose, setPurpose] = useState("");
  const [members_in_attendance, setMembers_in_attendance] = useState([
    { role: "", name: "" },
  ]);
  const [date, setDate] = useState(new Date());
  const [previous_meeting_comments, setPreviousMeetingComments] = useState("");
  const [attendance_comments, setAttendanceComments] = useState("");
  const [procurement_comments, setProcurmentComments] = useState("");
  const [documents_discussed, setDocumentsDiscussed] = useState("");
  const [special_strategies, setSpecialStrategies] = useState("");
  const [key_decisions, setKeyDecisions] = useState("");
  const [bid_document_verification, setBidDocumentVerification] = useState("");
  const [applications_received, setApplicationsReceived] = useState("");
  const [award_recommendations, setAwardRecommendations] = useState("");
  const [interim_decision_reasons, setInterimDecisionReasons] = useState("");
  const [decision_justifications, setDecisionJustifications] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");

  const [imagePreview, setImagePreview] = useState(null);
  const [signatureFile, setSignatureFile] = useState(null);

  const handleRecommendationChange = (event) => {
    setThenNatureOfCommitee(event.target.value);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSignatureFile(file); // Save the file to state
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleAddInput = () => {
    setMembers_in_attendance([
      ...members_in_attendance,
      { role: "", name: "" },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedMembersAttendace = [...members_in_attendance];
    updatedMembersAttendace[index][field] = value;
    setMembers_in_attendance(updatedMembersAttendace);
  };

  const handleRemoveInput = (index) => {
    const updatedMembersAttendace = [...members_in_attendance];
    updatedMembersAttendace.splice(index, 1);
    setMembers_in_attendance(updatedMembersAttendace);
  };
  console.log("FIle path:", signatureFile);
  const handleSubmit = async () => {
    try {
      // Create a FormData object
      const formData = new FormData();
      console.log("Members:", members_in_attendance);

      // Append fields to the FormData object
      formData.append("tender_id", tenderId.pr_id);
      formData.append("committee_nature", theNatureOfCommitee);
      formData.append("procurement_item", procurmentItem);
      formData.append("procurement_name", procurement_name);
      formData.append("meeting_number", meeting_number);
      formData.append("date", selectedCurrentDate.toLocaleDateString("en-GB"));
      formData.append("purpose", purpose);
      // Append members_in_attendance array
      members_in_attendance.forEach((member, index) => {
        formData.append(`members_in_attendance[${index}][role]`, member.role);
        formData.append(`members_in_attendance[${index}][name]`, member.name);
      });
      formData.append("attendance_comments", attendance_comments);
      formData.append("previous_meeting_comments", previous_meeting_comments);
      formData.append("procurement_comments", procurement_comments);
      formData.append("documents_discussed", documents_discussed);
      formData.append("special_strategies", special_strategies);
      formData.append("key_decisions", key_decisions);
      formData.append("bid_document_verification", bid_document_verification);
      formData.append("applications_received", applications_received);
      formData.append("award_recommendations", award_recommendations);
      formData.append("interim_decision_reasons", interim_decision_reasons);
      formData.append("decision_justifications", decision_justifications);
      formData.append("name", name);
      formData.append("committee_role", role);
      formData.append("signature", signatureFile); // Append file directly
      formData.append("user_id", "EMP-00001");
      formData.append("report_date", date.toLocaleDateString("en-GB"));

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tec_report/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Bid Evaluation Summary Report successfully added");
      navigate("/committees/techical");
      // handleReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Bid Evaluation Summary Report");
    }
  };

  const [selectedCurrentDate, setSelecteCurrentdDate] = useState(new Date());
  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen ">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Create: Technical Evaluation Committee Meeting Report
      </p>
      <div className=" ml-[80%]">
        <label className="text-[22px] font-bold">PR ID:</label>
        <input
          className="w-40 rounded-lg ml-4 h-8"
          value={tenderId.pr_id}
          disabled
        ></input>
      </div>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="  ml-6 mt-6">
          <div className="flex justify-around">
            <div>
              <p className=" text-[22px] text-[#8691AB] font-bold">
                The Nature of the <br /> Committee:
              </p>
            </div>
            <div class=" text-[22px] text-[#8691AB] font-bold ml-16 flex">
              {/* Cabinet Memo */}
              <div>
                <label class="inline-flex items-center">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span class="mr-2">
                    Cabinet <br />
                    Memo
                  </span>

                  <input
                    type="radio"
                    className="form-radio h-10 w-10 text-blue-500"
                    name="recommendation"
                    value="Cabinet Memo"
                    checked={theNatureOfCommitee === "Cabinet Memo"}
                    onChange={handleRecommendationChange}
                    required
                  />
                </label>
              </div>
              {/* Department pro */}
              <div>
                <label class="inline-flex items-center ml-14">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span class="mr-2">
                    Department <br />
                    Pro.
                  </span>
                  <input
                    type="radio"
                    className="form-radio h-10 w-10 text-blue-500"
                    name="recommendation"
                    value="Department Pro"
                    checked={theNatureOfCommitee === "Department Pro"}
                    onChange={handleRecommendationChange}
                    required
                  />
                </label>
              </div>
              {/* Business pro */}
              <div>
                <label class="inline-flex items-center ml-14">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span class="mr-2">
                    Business <br />
                    Pro.
                  </span>
                  <input
                    type="radio"
                    className="form-radio h-10 w-10 text-blue-500"
                    name="recommendation"
                    value="Business Pro"
                    checked={theNatureOfCommitee === "Business Pro"}
                    onChange={handleRecommendationChange}
                    required
                  />
                </label>
              </div>
              {/* Office pro */}
              <div>
                <label class="inline-flex items-center ml-14">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span class="mr-2">
                    Office <br />
                    Pro.
                  </span>
                  <input
                    type="radio"
                    className="form-radio h-10 w-10 text-blue-500"
                    name="recommendation"
                    value="Office Pro"
                    checked={theNatureOfCommitee === "Office Pro"}
                    onChange={handleRecommendationChange}
                    required
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Name of the procurment item:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[50%] ml-10"
            value={procurmentItem}
            onChange={(e) => setProcurmentItem(e.target.value)}
          ></input>
        </div>
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Name of the procurment:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[50%] ml-20"
            value={procurement_name}
            onChange={(e) => setProcurement_name(e.target.value)}
          ></input>
        </div>
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            No of meeting:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10"
            value={meeting_number}
            onChange={(e) => setMeeting_number(e.target.value)}
          ></input>

          <p className=" text-[22px] text-[#8691AB] font-bold ml-[15%]">
            Date:
          </p>

          <DatePicker
            selected={selectedCurrentDate}
            showIcon
            icon={<FaCalendarAlt />}
            onChange={(date) => setSelecteCurrentdDate(date)}
            className=" rounded-lg border border-gray-300  w-4/2 h-10 ml-10"
          />
        </div>
        <div className="mt-4 ml-6 ">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Purpose/Purposes
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[70%] ml-20 mb-6 h-8"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
          ></input>
        </div>
      </div>

      {/* Second form part */}
      <div className="justify-center flex">
        {" "}
        <p className="text-[27px] font-sans ml-6 mt-6 font-bold text-[#071C50] items-center">
          Name & post of the Technical evaluation committee meeting member in
          attendance
        </p>
      </div>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="mt-4 ml-6 ">
          {members_in_attendance.map((member, index) => (
            <div key={index} className=" mt-2 grid grid-cols-1">
              <div>
                <input
                  type="text"
                  className="rounded-lg border border-gray-300 w-1/4 mr-2"
                  placeholder="Role"
                  value={member.role}
                  onChange={(e) =>
                    handleInputChange(index, "role", e.target.value)
                  }
                />
                <input
                  type="text"
                  className="rounded-lg border border-gray-300 w-1/4 mr-2"
                  placeholder="Name"
                  value={member.name}
                  onChange={(e) =>
                    handleInputChange(index, "name", e.target.value)
                  }
                />
                {index !== 0 && (
                  <button
                    type="button"
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={() => handleRemoveInput(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="button"
            className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
            onClick={handleAddInput}
          >
            Add Member
          </button>
        </div>
      </div>

      {/* Third form part */}
      <div className="justify-center flex">
        {" "}
        <p className="text-[27px] font-sans ml-6 mt-6 font-bold text-[#071C50] items-center">
          Key Observations
        </p>
      </div>
      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            Details of the meeting proceedings and comments on matters which
            arising from the previous meeting. (if any) :
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            value={previous_meeting_comments}
            onChange={(e) => setPreviousMeetingComments(e.target.value)}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            If any ideas on the attendance/ absence  of committee members
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            value={attendance_comments}
            onChange={(e) => setAttendanceComments(e.target.value)}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Comments on the entire procurement process
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            value={procurement_comments}
            onChange={(e) => setProcurmentComments(e.target.value)}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Documents presented and subjected to discussion
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            value={documents_discussed}
            onChange={(e) => setDocumentsDiscussed(e.target.value)}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Special strategies/methods used (if any)
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            value={special_strategies}
            onChange={(e) => setSpecialStrategies(e.target.value)}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Summary on key decisions taken
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8 mb-6"
            value={key_decisions}
            onChange={(e) => setKeyDecisions(e.target.value)}
          ></input>
        </div>
      </div>

      {/* Fourth form part */}
      <div className="justify-center flex">
        {" "}
        <p className="text-[27px] font-sans ml-6 mt-6 font-bold text-[#071C50] items-center">
          Essential Information
        </p>
      </div>
      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            For document verification: usage of standard bid documents and
            accuracy of the bid documents.
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            value={bid_document_verification}
            onChange={(e) => setBidDocumentVerification(e.target.value)}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Total number of applications received for pre-qualified and short
            listing names and addresses of bidders. 
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            value={applications_received}
            onChange={(e) => setApplicationsReceived(e.target.value)}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Recommendations for awarding of contracts. Name and address of
            successful bidder, value of contract ( if relevant/ in different
            currencies)  
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            value={award_recommendations}
            onChange={(e) => setAwardRecommendations(e.target.value)}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Clearly mention the reasons given to the procurement entity for
            interim decisions
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8"
            value={interim_decision_reasons}
            onChange={(e) => setInterimDecisionReasons(e.target.value)}
          ></input>
        </div>
        <div>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Justification for their decision/ decisions if the committee members
            desired to be included. 
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 h-8 mb-6"
            value={decision_justifications}
            onChange={(e) => setDecisionJustifications(e.target.value)}
          ></input>
        </div>
      </div>

      {/* Fifth form part */}

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="flex">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Name
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[30%] ml-10 h-8 mt-6"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
            Committee Role
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[30%] ml-10 h-8 mt-6"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          ></input>
        </div>
        {/* Yes NO  */}
        <div className="flex">
          <div>
            {" "}
            <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
              Whether agreed with the above decision.
            </p>
            <div className="flex mt-6">
              <div>
                <label class="inline-flex items-center ml-14">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span class="mr-2">Yes</span>
                  <input
                    type="radio"
                    class="form-radio text-blue-500 h-10 w-10"
                    name="recommendation"
                    value="no"
                  />
                </label>
              </div>
              <div>
                <label class="inline-flex items-center ml-20">
                  <div className="bg-[#8691AB] w-3 h-3 rounded-full mr-2"></div>
                  <span class="mr-2">No</span>
                  <input
                    type="radio"
                    class="form-radio text-blue-500 h-10 w-10"
                    name="recommendation"
                    value="no"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className=" ml-[10%]">
            {/* Drag and drop area */}
            <div
              className="flex flex-col items-center justify-center mt-6"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="w-40 h-40 rounded-lg mb-4"
                />
              ) : (
                <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
                  <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                    Add Signature
                  </p>
                  <img
                    src={cloudIcon}
                    alt="Cloud Icon"
                    className="w-16 h-16 mb-2"
                  />
                  <p className="text-[#8691AB] font-bold ml-4">
                    Drag and Drop Digital Signature
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6 mt-6 ml-6">
        <button
          className=" bg-[#00adef] text-[20px] font-bold text-white rounded-lg w-[8%] h-8"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Create_Commitee_Meeting_Report;
