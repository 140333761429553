/** @format */

import React, { useState, useEffect } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import Cookies from "js-cookie";

function CustomSlider({ levels, value, onChange }) {
  const handleDrag = (e) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const width = rect.width;
    const newValue = Math.round((offsetX / width) * (levels.length - 1));
    onChange(levels[newValue]);
  };

  const getBarColor = (level) => {
    switch (level) {
      case "Low":
        return "bg-green-400";
      case "Medium":
        return "bg-yellow-400";
      case "High":
        return "bg-orange-400";
      case "Very High":
        return "bg-red-400";
      default:
        return "";
    }
  };

  return (
    <div className="w-[600px] relative">
      <div
        className="h-3 w-full rounded-full bg-gray-300 relative"
        onMouseDown={handleDrag}
      >
        <div
          className={`h-3 ${getBarColor(
            value
          )} absolute top-0 left-0 rounded-full`}
          style={{
            width: `${(levels.indexOf(value) / (levels.length - 1)) * 100}%`,
          }}
        ></div>
      </div>
      <div className="flex justify-between mt-1">
        {levels.map((level, index) => (
          <span
            key={index}
            className={`text-xs ${
              level === value ? "font-bold text-gray-900" : "text-gray-600"
            }`}
          >
            {level}
          </span>
        ))}
      </div>
      <div
        className="absolute top-0 ml-5 h-4 w-2 bg-white border border-gray-300 rounded-full shadow cursor-pointer"
        style={{
          left: `${(levels.indexOf(value) / (levels.length - 1)) * 100 - 4}%`,
          transform: "translate(-50%, -50%)",
        }}
        onMouseDown={handleDrag}
      ></div>
    </div>
  );
}

function CreateJob() {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const levels = ["Low", "Medium", "High", "Very High"];
  const [unitOptions, setUnitOptions] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState("");
  const [recipe_id, setRecipeId] = useState(""); // State to hold the selected recipe ID
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedUnitId, setSelectedUnitId] = useState("");
  const userId = Cookies.get("employee_no");
  const [jobName, setJobName] = useState("");
  const [job_category, setJobCategory] = useState("");
  const [job_quarter, setJobQuarter] = useState("");
  const [priority_level, setPriority_level] = useState(levels[0]);
  const [checkIsOk, setCheckIsOk] = useState(false);
  const [limitExceed, setLimitExceed] = useState(false);
  const [totalJobQuantity, setTotalJobQuantity] = useState("");
  const [containerMaxQuantity, setContainerMaxQuantity] = useState("");

  // State to manage all form inputs
  const [data, setData] = useState({
    job_name: "",
    job_recipe_id: "",
    job_category: "",
    job_quarter: "",
    store_description: "",
    priority_level: "",

    entries: [{ quantity: "", job_unit_id: "" }],
  });

  useEffect(() => {
    fetchUnitOptions();
    fetchRecipes();
  }, []);

  const fetchRecipes = () => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/recipe/getAccordingtoStatus?status=APPROVE`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch recipes");
        }
        return response.json();
      })
      .then((data) => {
        setRecipes(data);
        // console.log("recipies", data);
      })
      .catch((error) => {
        console.error("Error fetching recipes:", error);
      });
  };

  const fetchUnitOptions = () => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/unit/getSecondary`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch unit options");
        }
      })
      .then((data) => {
        console.log("Fetching unit options:", data);
        setUnitOptions(
          data.map((unit) => ({
            id: unit.unit_production_id,
            name: unit.unit_name,
            baseUnitId: unit.unit_base_unit_id,
            createAt: unit.unit_create_at,
            createBy: unit.unit_create_by,
            operatorValue: unit.unit_operator_value,
            symbol: unit.unit_symbol,
            items: unit.items, // Array of items with item_id and quantity
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching unit options:", error);
      });
  };

  const handleSliderChange = (newValue) => {
    setPriority_level(newValue); // Update the priority_level state
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Prepare the items array from the selectedItems state
    const units = data.entries.map((item) => ({
      unit_production_id: item.job_unit_id, // Assuming subItem holds the item ID
      job_production_create_uint_quantity: item.quantity,
    }));

    const formData = {
      job_name: jobName,
      job_recipe_id: recipe_id, // Include the recipe_id here
      job_category: job_category,
      job_quater: job_quarter,
      job_status: "PENDING",
      job_created_by: userId,
      job_priority_level: priority_level,
      units,
    };

    console.log("Request Body:", formData);

    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/job/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Job added successfully");
          alert("Job Added Successfully");
          setShowSuccessMessage(true);
          setSelectedRecipe(""); // Reset the selected recipe
          setRecipeId("");
          setJobName("");
          setJobCategory("");
          setJobQuarter("");
          setData([]);
          setPriority_level(levels[0]); // Reset the priority level
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 2000);
        } else {
          // Parse the error response
          return response.json().then((errorData) => {
            console.error("Failed to add job:", errorData.message);
            alert(`Error: ${errorData.message}`); // Display the specific error message
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error occurred. Please try again."); // Display general error message
      });
  };

  const handleCheck = async (event) => {
    event.preventDefault();

    // Prepare the units array from the data entries
    const units = data.entries.map((item) => ({
      unit_production_id: item.job_unit_id,
      job_production_create_uint_quantity: item.quantity,
    }));

    const formData = {
      job_recipe_id: recipe_id, // Include the recipe_id
      units,
    };

    console.log("Check Body:", formData);

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/job/checkjobquantity-limit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const responseData = await response.json(); // Parse the JSON response

      if (response.ok) {
        // Success case: Show success message with details
        const { containerMaxQuantity, totalJobQuantity, message } =
          responseData;
        alert(
          `Success: ${message}\n` +
            `Total Job Quantity: ${totalJobQuantity}\n` +
            `Container Max Quantity: ${containerMaxQuantity}`
        );
        console.log("Job added successfully");
        setTotalJobQuantity(totalJobQuantity);
        setContainerMaxQuantity(containerMaxQuantity);
        setCheckIsOk(true);
        setLimitExceed(false);
      } else {
        // Error case: Show error message with details
        const { jobQuantity, MaxQuantity, message } = responseData;
        alert(
          `Error: ${message}\n` +
            `Job Quantity: ${jobQuantity}\n` +
            `Max Quantity: ${MaxQuantity}`
        );
        console.error("Failed to add job:", responseData);
        setTotalJobQuantity(jobQuantity);
        setContainerMaxQuantity(MaxQuantity);
        setLimitExceed(true);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again."); // Display a general error message
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    // Update the specific entry in the state
    setData((prevData) => {
      const updatedEntries = [...prevData.entries];
      updatedEntries[index] = {
        ...updatedEntries[index],
        [name]: value,
      };
      return { ...prevData, entries: updatedEntries };
    });
  };

  const addEntry = () => {
    setData((prevData) => ({
      ...prevData,
      entries: [...prevData.entries, { quantity: "", job_unit_id: "" }],
    }));
  };

  const removeEntry = (index) => {
    setData((prevData) => ({
      ...prevData,
      entries: prevData.entries.filter((_, i) => i !== index),
    }));
  };

  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#74d4f4] w-[100%] rounded-xl">
        Create Job
      </p>
      <div className="box-border w-full p-4 bg-white">
        <form>
          <div className="space-y-12">
            <div className="ml-8">
              {/* Job Name */}
              <div className="sm:col-span-3 flex flex-row items-center">
                <label
                  htmlFor="job_name"
                  className="text-sm font-medium leading-6 text-gray-900 mr-4"
                >
                  Job name
                </label>
                <input
                  type="text"
                  className="w-[50%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                  value={jobName}
                  onChange={(e) => setJobName(e.target.value)}
                />
              </div>

              {/* Job Recipe */}
              <div className="sm:col-span-3 flex flex-row items-center mt-5">
                <label
                  htmlFor="job_recipe_id"
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                >
                  Select Job Recipe
                </label>
                <select
                  id="job_recipe_id"
                  name="job_recipe_id"
                  className="pl-5 px-4 block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  value={recipe_id}
                  onChange={(e) => setRecipeId(e.target.value)}
                >
                  <option value="">Select Recipe</option>
                  {recipes?.map((recipe) => (
                    <option key={recipe.recipe_id} value={recipe.recipe_id}>
                      {recipe.recipe_name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Job Category */}
              <div className="sm:col-span-3 flex flex-row items-center mt-7">
                <label className="block text-sm font-medium leading-6 text-gray-900 mr-4">
                  Job Category
                </label>
                <div className="flex items-center gap-x-6 ml-[10px] mt-5">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="job_category"
                      value="End Product"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      checked={job_category === "End Product"}
                      onChange={(e) => setJobCategory(e.target.value)}
                    />
                    <label className="ml-2 text-sm text-gray-700">
                      End Product
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="job_category"
                      value="Semi Product"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      checked={job_category === "Semi Product"}
                      onChange={(e) => setJobCategory(e.target.value)}
                    />
                    <label className="ml-2 text-sm text-gray-700">
                      Semi Product
                    </label>
                  </div>
                </div>
              </div>

              {/* Quarter */}
              <div className="sm:col-span-3 flex flex-row items-center mt-5">
                <label
                  htmlFor="job_quater"
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                >
                  Choose a Quarter
                </label>
                <select
                  id="job_quater"
                  name="job_quater"
                  className="pl-5 px-4 block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  value={job_quarter}
                  onChange={(e) => setJobQuarter(e.target.value)}
                >
                  <option value="First Quarter">First Quarter</option>
                  <option value="Second Quarter">Second Quarter</option>
                  <option value="Third Quarter">Third Quarter</option>
                  <option value="Fourth Quarter">Fourth Quarter</option>
                </select>
              </div>

              {/* Render Quantity and Unit Inputs */}
              {data.entries?.length > 0 ? (
                data.entries.map((entry, index) => (
                  <div key={index} className="grid grid-cols-3 gap-3 mb-4 mt-5">
                    <div className="flex flex-col">
                      <label
                        htmlFor={`quantity_${index}`}
                        className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                      >
                        Unit Quantity
                      </label>
                      <input
                        type="number"
                        name="quantity"
                        id={`quantity_${index}`}
                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-5 px-4"
                        value={entry.quantity}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor={`job_unit_id_${index}`}
                        className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                      >
                        Unit
                      </label>
                      <select
                        id={`job_unit_id_${index}`}
                        name="job_unit_id"
                        className="pl-5 px-4 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        value={entry.job_unit_id}
                        onChange={(e) => handleInputChange(e, index)}
                      >
                        <option value="" disabled>
                          Select a Unit
                        </option>
                        {unitOptions.map((unit) => (
                          <option key={unit.id} value={unit.id}>
                            {unit.name} - {unit.id}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Button to remove this entry */}
                    <div className="flex items-center justify-start">
                      <IoMdCloseCircle
                        onClick={() => removeEntry(index)}
                        className="cursor-pointer ml-2"
                        size={20}
                      />
                    </div>
                  </div>
                ))
              ) : (
                // Default empty fields
                <div className="grid grid-cols-3 gap-3 mb-4 mt-5">
                  <div className="flex flex-col">
                    <label
                      htmlFor="quantity_default"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                    >
                      Unit Quantity
                    </label>
                    <input
                      type="number"
                      name="quantity"
                      id="quantity_default"
                      className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-5 px-4"
                      value={""} // Set an empty value for the default case
                      onChange={(e) => handleInputChange(e, 0)} // Adjust the index as necessary
                    />
                  </div>

                  <div className="flex flex-col">
                    <label
                      htmlFor="job_unit_id_default"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                    >
                      Unit
                    </label>
                    <select
                      id="job_unit_id_default"
                      name="job_unit_id"
                      className="pl-5 px-4 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      value={""} // Set an empty value for the default case
                      onChange={(e) => handleInputChange(e, 0)} // Adjust the index as necessary
                    >
                      <option value="" disabled>
                        Select a Unit
                      </option>
                      {unitOptions.map((unit) => (
                        <option key={unit.id} value={unit.id}>
                          {unit.name} - {unit.id}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Placeholder for remove button, can be hidden or disabled */}
                  <div className="flex items-center justify-start">
                    <IoMdCloseCircle
                      className="cursor-not-allowed ml-2"
                      size={20}
                    />
                  </div>
                </div>
              )}

              {/* Button to add a new entry */}
              <button
                type="button"
                className="rounded-md bg-[#D4E7C5] px-3 py-2 text-sm text-Black shadow-sm"
                onClick={addEntry}
              >
                Add Unit
              </button>

              <button
                type="button"
                className="rounded-md bg-[#D4E7C5] px-3 py-2 text-sm text-Black shadow-sm ml-5"
                onClick={handleCheck}
              >
                Check Unit
              </button>
              {containerMaxQuantity && totalJobQuantity && (
                <div className="grid grid-cols-2 gap-5 mt-4">
                  {" "}
                  {/* Grid layout with two columns */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="containerMaxQuantity"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                    >
                      Container Max Quantity
                    </label>
                    <input
                      className="block w-[30%] rounded-md border-0 py-1.5 text-red-400 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 pl-5 px-4"
                      value={containerMaxQuantity || ""} // Fallback for empty value
                      disabled
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="totalJobQty"
                      className={`block text-sm font-medium leading-6 mb-1 ${
                        limitExceed ? "text-red-600" : "text-gray-900"
                      }`} // Conditional class for red text
                    >
                      Total Job Quantity
                    </label>
                    <input
                      className={`block w-[30%] rounded-md border-0 py-1.5 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 pl-5 px-4 ${
                        limitExceed ? "text-red-600" : "text-green-400"
                      }`} // Conditional class for red input text
                      value={totalJobQuantity || ""} // Fallback for empty value
                      disabled
                    />
                  </div>
                </div>
              )}

              {/* Priority Level */}
              <div className="sm:col-span-3 flex flex-row items-center mt-5">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                >
                  Priority Level
                </label>
                <div className="flex flex-row items-center mt-5">
                  <CustomSlider
                    levels={levels}
                    value={priority_level}
                    onChange={handleSliderChange}
                  />
                </div>
              </div>

              {/* Submit Button */}
              <div className="mt-1 flex items-center gap-x-6">
                {checkIsOk && (
                  <button
                    onClick={handleSubmit}
                    className="rounded-md bg-[#D4E7C5] px-3 py-2 text-sm text-Black shadow-sm"
                  >
                    Submit
                  </button>
                )}
                {showSuccessMessage && (
                  <div className="text-green-600 text-sm mt-2">
                    File submitted successfully!
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateJob;
