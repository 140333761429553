/** @format */

import React, { useState } from "react";

function AddNewEmploymentQualification({ employee_no, setFetchData, onClose }) {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [employmentQualificationSections, setEmploymentQualificationSections] =
    useState(1);

  const academicQualifications = () => {
    const qualifications = [];
    const maxSections = Math.min(employmentQualificationSections, 4);

    for (let i = 0; i < maxSections; i++) {
      const qualificationData = {
        employee_acadamic_qualification: document.getElementById(
          `qualification_${i}`
        ).value,
      };
      qualifications.push(qualificationData);
    }

    return qualifications;
  };

  const handleAddEmploymentQualificationSection = () => {
    if (employmentQualificationSections < 4) {
      setEmploymentQualificationSections(employmentQualificationSections + 1);
    }
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    try {
      // Log the data being sent
      console.log("Data to be sent:", {
        employee_no: employee_no,
        employee_acadamic_qualification_details: academicQualifications(),
      });

      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/insertEmployeeAcademicQualifications`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            employee_no: employee_no,
            employee_acadamic_qualification_details: academicQualifications(), // Corrected key here
          }),
        }
      );
      const data = await response.json();
      console.log(data); // Handle the response data as needed
      onClose();
      setFetchData((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderEmploymentQualificationSections = () => {
    const sections = [];
    const maxSections = Math.min(employmentQualificationSections, 4);

    for (let i = 0; i < maxSections; i++) {
      sections.push(
        <div key={i} className="mt-5">
          <div className="">
            <form>
              <div className="flex mt-5 flex-col md:flex-row font-bold p-5">
                <div className="flex flex-col mr-10">
                  <label
                    htmlFor={`qualification_${i}`}
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Qualification {i + 1}
                  </label>
                  <textarea
                    name={`qualification_${i}`}
                    id={`qualification_${i}`}
                    autoComplete={`qualification_${i}`}
                    placeholder="Enter Qualification"
                    className="w-[300px] h-[100px] rounded-[10px] py-2 px-3 bg-[#74d4f4] text-gray-900 placeholder-white text-sm font-sans"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
    return sections;
  };

  return (
    <div className="flex  ">
      <div className="bg-[#ffffff] w-[600px] rounded-[35px] h-[700px] mt-[5%] text-[32px] ml-[5%] mr-[5%] font-bold overflow-y-auto">
        <p className="text-2xl mb-4 ml-5 mt-[8%] mr-10 font-bold text-[#797C80] ">
          Add Qualification{" "}
        </p>
        <div> {renderEmploymentQualificationSections()}</div>
        <div className="ml-5">
          <button
            type="button"
            onClick={handleAddEmploymentQualificationSection}
            className="rounded-full  bg-[#00adef] mb-5 px-3 py-2 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
            style={{ borderRadius: "45px" }}
          >
            Add Employment qualification
          </button>
        </div>
        <div className="flex gap-5 justify-end mr-5">
          <button
            onClick={onClose}
            className="rounded-full bg-[rgb(171,175,166)] w-[100px] h-[40px] mt-10 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
          >
            Cancel{" "}
          </button>
          <button
            onClick={handleSubmit}
            className="rounded-full  bg-[#00adef] w-[100px] h-[40px] mt-10 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
          >
            Submit{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddNewEmploymentQualification;
