/** @format */

import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import usePermissions from "../../../components/permissions/permission";

function WarehouseAdjustmentData({ jobId }) {
  const { hasPermission } = usePermissions();
  const [adjustments, setAdjustments] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    if (jobId) {
      fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/adjustment/getadjustmentbyJobID?adjustment_job_id=${jobId}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (!Array.isArray(data)) {
            throw new Error("Fetched data is not an array");
          }
          setAdjustments(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [jobId]);

  const handleApprovalChange = (adjustmentId, level, value) => {
    const updatedAdjustments = adjustments.map((adjustment) => {
      if (adjustment._id === adjustmentId) {
        adjustment[`job_approve_level_${level}`] = value;
        adjustment[`job_approved_level${level}_by`] = "EMP003"; // Set the approver for the level
      }
      return adjustment;
    });
    setAdjustments(updatedAdjustments);

    const putData = {
      [`job_approve_level_${level}`]: value,
      [`job_approved_level${level}_by`]: "EMP003", // Include the approver information
    };

    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/product/finalProductionQuantityAdjustmentUpdate?adjustment_job_id=${adjustmentId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(putData),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Approval status updated:", data);
      })
      .catch((error) => {
        console.error("Error updating approval status:", error);
      });
  };

  return (
    <div>
      <p className="text-[32px] ml-6 mt-5 bg-[#74d4f4] w-[100%] rounded-xl">
        Warehouse Adjustment Data
      </p>
      <div className="box-border h-screen w-full p-4 bg-[#FFFFFF] ml-9 mt-5">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            {/* Table Header */}
            <thead className="bg-[#74d4f4]">
              <tr>
                <th>Adjustment ID</th>
                <th>Adjustment Quantity</th>
                <th>Current Quantity</th>
                <th>Adjustment Reason</th>
                <th>Adjusted By</th>
                <th>Adjustment Date</th>
                <th>Unit Symbol</th>
                <th>Approval Level 1</th>
                <th>Approval Level 1 by</th>
                <th>Approval Level 2</th>
                <th>Approval Level 2 by</th>
                <th>Approval Level 3</th>
                <th>Approval Level 3 by</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody className="bg-white divide-y divide-gray-200">
              {adjustments.map((adjustment) => (
                <tr key={adjustment._id}>
                  <td>{adjustment._id}</td>
                  <td>{adjustment.adjustment_quantity}</td>
                  <td>{adjustment.current_quantity}</td>
                  <td>{adjustment.adjustment_reason}</td>
                  <td>{adjustment.adjust_by}</td>
                  <td>
                    <Moment format="YYYY/MM/DD">
                      {adjustment.adjustment_date}
                    </Moment>
                  </td>
                  <td>{adjustment.job_unit_symbol}</td>
                  <td>
                    {hasPermission(2521) && (
                      <select
                        value={adjustment.job_approve_level_1 || ""}
                        onChange={(e) =>
                          handleApprovalChange(
                            adjustment._id,
                            1,
                            e.target.value
                          )
                        }
                      >
                        <option value="">Pending</option>
                        <option value="APPROVE">Approve</option>
                        <option value="REJECT">Reject</option>
                      </select>
                    )}
                  </td>
                  <td>{adjustment.job_approved_level1_by}</td>
                  <td>
                    {hasPermission(2522) && (
                      <select
                        value={adjustment.job_approve_level_2 || ""}
                        onChange={(e) =>
                          handleApprovalChange(
                            adjustment._id,
                            2,
                            e.target.value
                          )
                        }
                      >
                        <option value="">Pending</option>
                        <option value="APPROVE">Approve</option>
                        <option value="REJECT">Reject</option>
                      </select>
                    )}
                  </td>
                  <td>{adjustment.job_approved_level2_by}</td>
                  <td>
                    {hasPermission(2523) && (
                      <select
                        value={adjustment.job_approve_level_3 || ""}
                        onChange={(e) =>
                          handleApprovalChange(
                            adjustment._id,
                            3,
                            e.target.value
                          )
                        }
                      >
                        <option value="">Pending</option>
                        <option value="APPROVE">Approve</option>
                        <option value="REJECT">Reject</option>
                      </select>
                    )}
                  </td>
                  <td>{adjustment.job_approved_level3_by}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default WarehouseAdjustmentData;
