/** @format */

import React, { useState, useEffect } from "react";
import moment from "moment";
import ViewMoreItems from "./viewmoreitems";

export default function DisposedItems() {
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [status, setStatus] = useState("");
  const [items, setItems] = useState([]);
  const [viewMoreData, setViewMoreData] = useState(null);
  const [viewMoreOpen, setViewMoreOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchDispose = async (startDate, endDate, status) => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/dispose/disposedItemsByDateRange/${startDate}/${endDate}/${status}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setItems(data);
    } catch (error) {
      console.error("Failed to fetch disposal:", error);
      setItems([]);
    } finally {
      setLoading(false);
    }
  };

  const SearchByDateRange = () => {
    const startDate =
      dateStart || moment().startOf("month").format("YYYY-MM-DD");
    const endDate = dateEnd || moment().endOf("month").format("YYYY-MM-DD");
    const selectedStatus = status || "PENDING";

    fetchDispose(startDate, endDate, selectedStatus);
  };

  useEffect(() => {
    SearchByDateRange();
  }, []);

  const handleViewMore = (dispose) => {
    setViewMoreData(dispose);
    setViewMoreOpen(true);
  };
  return (
    <div className="p-10 bg-background">
      <p className="header-1">Inventory Dispose</p>

      <div className="w-full flex justify-start items-center mt-10 gap-x-5">
        <div className="w-[500px] h-[82px] flex justify-between items-center  bg-[#F3F8FF] px-10 rounded-[46px] border border-[#00000036]">
          <p className="label-1">date start :</p>
          <input
            type="date"
            className="w-[250px] h-[50px] rounded-[46px] border border-[#00000036] text-center"
            value={dateStart}
            onChange={(e) => setDateStart(e.target.value)}
          />
        </div>
        <div className="w-[500px] h-[82px] flex justify-between items-center  bg-[#F3F8FF] px-10 rounded-[46px] border border-[#00000036]">
          <p className="label-1">date end :</p>
          <input
            type="date"
            className="w-[250px] h-[50px] rounded-[46px] border border-[#00000036] text-center"
            value={dateEnd}
            onChange={(e) => setDateEnd(e.target.value)}
          />
        </div>
        <select
          className="input rounded-full w-[200px] h-[5%] px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="">Select Status</option>
          <option value="PENDING">PENDING</option>
          <option value="APPROVED">APPROVED</option>
          <option value="REJECTED">REJECTED</option>
        </select>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4 p2 text-white"
          onClick={SearchByDateRange}
        >
          Search
        </button>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl item">
        <table className="table-auto border-none w-[95%] ">
          <thead>
            <tr className="bg-white">
              <th className="text-primary px-4 py-2">Disposed ID</th>
              <th className="text-primary px-4 py-2">Disposed Date</th>
              <th className="text-primary px-4 py-2">Disposed User</th>
              <th className="text-primary px-4 py-2">Approval Level One</th>
              <th className="text-primary px-4 py-2">
                Approval Level One Description
              </th>
              <th className="text-primary px-4 py-2">Approval Level Two</th>
              <th className="text-primary px-4 py-2">
                Approval Level Two Description
              </th>
              <th className="text-primary px-4 py-2">Approval Level Three</th>
              <th className="text-primary px-4 py-2">
                Approval Level Three Description
              </th>
              <th className="text-primary px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan="10" className="bg-white px-4 py-2 text-center">
                  Loading...
                </td>
              </tr>
            )}
            {/* Error state */}

            {/* No data state */}
            {items.length === 0 && (
              <tr>
                <td colSpan="10" className="bg-white px-4 py-2 text-center">
                  No data available
                </td>
              </tr>
            )}
            {/* Render data */}
            {items.map((dispose) => (
              <tr key={dispose.dispose_id} className="">
                <td className="bg-white px-4 py-2 text-center">
                  {dispose.dispose_id}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {new Date(dispose.dispose_date).toLocaleString()}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {dispose.dispose_user_id}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {dispose.approved_level_1_status}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {dispose.approved_level_1_description}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {dispose.approved_level_2_status}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {dispose.approved_level_2_description}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {dispose.approved_level_3_status}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {dispose.approved_level_3_description}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  <button
                    className="text-primary hover:underline"
                    onClick={() => handleViewMore(dispose)}
                  >
                    View More
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {viewMoreOpen && (
        <ViewMoreItems
          onClose={() => setViewMoreOpen(false)}
          disposeItems={viewMoreData.dispose_items}
        />
      )}
    </div>
  );
}
