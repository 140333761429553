/** @format */

import React, { useState, useEffect } from "react";
import Toggle from "./toggle-switch/toggle";
import fileIcon from "../../../../Assets/icons/png.png";

const Edit_Popup = ({ trainingData, onClose, fetchData }) => {
  const [trainingDetails, setTrainingDetails] = useState({ ...trainingData });
  const [trainingStatus, setTrainingStatus] = useState(
    trainingData.training_status === "COMPLETED"
  );
  const [selectedFile, setSelectedFile] = useState(null); // State to hold the selected file
  const { REACT_APP_API_ENDPOINT } = process.env;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrainingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    // Update the selected file state when a file is selected
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ensure the status is set based on the toggle state before submitting
    const status = trainingStatus ? "COMPLETED" : "UPCOMING";
    const updatedTrainingDetails = {
      ...trainingDetails,
      training_status: status, // Use the status determined by the toggle
    };
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/performance/training/updatetraining?training_id=${trainingDetails.training_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedTrainingDetails),
        }
      );
      if (response.ok) {
        uploadFeedbackFile(selectedFile); // Pass the selected file to uploadFeedbackFile function
        onClose();
        fetchData();
        alert("Training updated successfully!");
      } else {
        alert("Error updating training");
      }
    } catch (error) {
      console.error("Error updating training:", error);
    }
  };

  const uploadFeedbackFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("training_id", trainingDetails.training_id);
      formData.append("training_feedback", file);
      formData.append("training_name", trainingDetails.training_name);

      console.log("FormData:", formData); // Log FormData before sending the request

      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/performance/training/uploadtrainingfeedback`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        alert("Training updated successfully!");
        const responseData = await response.json();
        console.log("Files uploaded successfully:", responseData);
        onClose();
        fetchData();
      }
    } catch (error) {
      alert("Error updating training");

      console.error("Error uploading files", error);
    }
  };

  const handleToggleChange = (value) => {
    setTrainingStatus(value);
  };
  useEffect(() => {
    // Update the toggle state if the trainingData prop changes
    setTrainingStatus(trainingData.training_status === "COMPLETED");
  }, [trainingData]);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6">
        <p className="font-sans text-left text-[36px] font-bold mb-4 text-[#797C80]">
          Edit Training Details{" "}
          <hr className="line border-t border-gray-300 w-[35%]" />
        </p>

        {/* Form */}
        <div className="w-[500px]">
          <div className="grid grid-cols-2 gap-[80px]">
            {/* Section 01 */}
            <div>
              <label className="font-sans flex justify-start">
                Training Name
              </label>
              <div className="mb-5 flex justify-start">
                <input
                  type="text"
                  name="training_name"
                  value={trainingDetails.training_name}
                  onChange={handleChange}
                  required
                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                />
              </div>

              <div>
                <label className="font-sans flex justify-start">
                  Institute
                </label>
                <div>
                  <input
                    type="text"
                    name="institute"
                    value={trainingDetails.institute}
                    onChange={handleChange}
                    required
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>

              <div className="mt-5">
                <label className="font-sans flex justify-start">
                  Cost for the training (LKR)
                </label>
                <div>
                  <input
                    type="number"
                    name="cost_of_the_training"
                    value={trainingDetails.cost_of_the_training}
                    onChange={handleChange}
                    required
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
            </div>

            {/* Section 02 */}
            <div>
              <label className="font-sans flex justify-start">
                Training Type
              </label>
              <div className="mb-5">
                <input
                  type="text"
                  name="training_type"
                  value={trainingDetails.training_type}
                  onChange={handleChange}
                  required
                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                />
              </div>

              <div>
                <label className="font-sans flex justify-start">
                  Duration (Days)
                </label>
                <div>
                  <input
                    type="number"
                    name="duration"
                    value={trainingDetails.duration}
                    onChange={handleChange}
                    required
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>

              <div className="mt-5">
                <label className="font-sans flex justify-start">
                  Participated Employees
                </label>
                <div>
                  <input
                    type="number"
                    name="participated_employees"
                    value={trainingDetails.participated_employees}
                    onChange={handleChange}
                    required
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
            </div>

            <div className="mt-[-50px]">
              <div className="flex justify-start">
                <p>Select dates</p>
              </div>
              <div className="flex justify-start font-sans font-semibold">
                <div>
                  <input
                    type="date"
                    name="date"
                    value={trainingDetails.date}
                    onChange={handleChange}
                    required
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>

              <div className="mt-4">
                <div className="p-5">
                  {trainingDetails.training_feedback_file_path ? (
                    <div className="flex gap-5 items-center">
                      <img
                        src={fileIcon}
                        alt="File Icon"
                        className="w-7 h-7 cursor-pointer"
                      />
                      <div className="cursor-pointer">
                        {trainingDetails.training_feedback_file_name}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <span>No file available</span>
                      <div className="mt-2">
                        <input
                          className="h-10 rounded-[32px] border border-[#9CAC8B]-300 border-input bg-white text-sm text-gray-400 file:border-0 file:bg-[#74d4f4] file:text-white file:text-sm file:font-medium file:h-10"
                          type="file"
                          id=""
                          accept="application/pdf"
                          onChange={handleFileChange} // Attach handleFileChange function to handle file selection
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-start items-center mt-2">
          <div>
            <p className="text-headerColor">Select Completed or Not</p>
          </div>
          <div>
            <div>
              <Toggle status={trainingStatus} onChange={handleToggleChange} />
            </div>{" "}
          </div>
        </div>

        <div className="flex justify-end items-center mb-3">
          <div>
            <button
              onClick={onClose}
              className="text-black font-bold py-2 px-4 rounded-[32px]"
            >
              Close
            </button>
          </div>
          <div>
            <button
              onClick={handleSubmit}
              className="bg-primary p-1 w-[4rem] text-white rounded-[20px]"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//h

export default Edit_Popup;
