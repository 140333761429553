/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const ReturnSale = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [invoiceNumber, setInvoiceNumber] = useState(""); // Input for POS ID
  const [selectedBill, setSelectedBill] = useState(null); // Selected bill details
  const [billItems, setBillItems] = useState([]); // Selected bill items
  const [returnReason, setReturnReason] = useState(""); // Return reason input
  const [outstandingBalance, setOutstandingBalance] = useState(null); // Outstanding balance after return
  const [branchId, setBranchId] = useState(null); // Branch ID from cookie
  const [userId, setUserId] = useState(null); // User ID from cookie
  const [returnId, setReturnId] = useState(null); // Initialize returnId state
  const { REACT_APP_API_ENDPOINT } = process.env;

  // Fetch branch_id and user_id from cookie
  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const branch_id = getCookie("branch_id");
    const user_id = getCookie("user_id");

    setBranchId(branch_id);
    setUserId(user_id);
  }, []);

  // Fetch the bill details based on the entered POS ID
  const GetBill = async (id) => {
    if (id !== "") {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/pos/get-pos-bill-data?pos_id=${id}&branch_id=${branchId}`
        );
        const data = await response.json();

        const posDetails = data.posDetails;
        const posItems = data.posItems;

        setSelectedBill(posDetails); // Set selected bill details
        setBillItems(posItems); // Set bill items for the table

        console.log("Customer ID:", posDetails.customer_id); // Log customer_id in console
        console.log("Customer Name:", posDetails.customer_name); // Log customer_name in console
      } catch (error) {
        console.error("Error fetching bill data: ", error);
      }
    }
  };

  // Handle user input for invoice number and fetch the corresponding bill details
  const handleSearch = () => {
    GetBill(invoiceNumber); // Fetch bill data when the search button is clicked
  };

  // Handle deletion of an item from the bill
  const handleDelete = (inventory_batch_id) => {
    setBillItems(
      billItems.filter((item) => item.inventory_batch_id !== inventory_batch_id)
    );
  };

  // Handle the change in quantity for each item
  const handleQuantityChange = (inventory_batch_id, newQuantity) => {
    setBillItems((prevItems) =>
      prevItems.map((item) =>
        item.inventory_batch_id === inventory_batch_id
          ? { ...item, pos_item_qty: newQuantity }
          : item
      )
    );
  };

  // Handle the return process and send the return data to the backend
  const handleMakeReturn = async () => {
    const returnedItems = billItems.map((item) => ({
      inventory_batch_id: item.inventory_batch_id,
      return_qty: item.pos_item_qty, // Adjusted quantity
      branch_stock_id: item.inventory_batch_id,
    }));

    const returnData = {
      pos_id: selectedBill.pos_id,
      returned_items: returnedItems,
      return_reason: returnReason,
      processed_by: userId || "user_123", // Ensure processed_by is included correctly
    };

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/pos/return-item`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(returnData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      const { return_id, refund } = data; // Destructure the return_id and refund

      if (!refund) {
        throw new Error("Refund is undefined in the response.");
      }

      setReturnId(return_id[0]); // Set the first return_id in state if it's an array

      // Navigate to /return_pos_bill with returnId, refund, and customer_id in state
      navigate("/return_pos_bill", {
        state: {
          returnId: return_id[0],
          refund,
          customer_id: selectedBill.customer_id,
        },
      });
    } catch (error) {
      console.error("Error processing return: ", error.message);
      alert("Failed to process return: " + error.message);
    }
  };

  // Format currency in Sri Lankan Rupees (LKR)
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-LK", {
      style: "currency",
      currency: "LKR",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  // Calculate total of items
  const calculateTotal = () => {
    return billItems
      .reduce(
        (acc, item) =>
          acc + item.pos_item_qty * parseFloat(item.pos_items_price),
        0
      )
      .toFixed(2);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch(); // Trigger the search when Enter key is pressed
    }
  };

  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen p-10">
      <div className="mt-5 ml-6">
        <p className="font-bold text-[35px] mt-5">Return a Sale</p>

        {/* Invoice Number Search */}
        <div className="flex items-center gap-5 mt-5">
          <label className="font-bold text-[20px]">Invoice Number:</label>
          <input
            className="border border-black rounded-xl p-2 w-[300px]"
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)} // Set the input value
            onKeyPress={handleKeyPress} // Listen for Enter key press
            placeholder="Enter POS ID"
          />
          <button
            className="bg-blue-500 text-white p-2 rounded-lg"
            onClick={handleSearch} // Search when clicked
          >
            Load Bill
          </button>
        </div>

        {/* Display selected bill details */}
        {selectedBill && (
          <div className="mt-10">
            <div className="bg-white p-10 rounded-xl shadow-md">
              <p className="font-bold text-[20px]">
                POS ID: {selectedBill.pos_id}
              </p>
              <p className="font-bold text-[20px]">
                Customer ID: {selectedBill.customer_id}
              </p>
              <p className="font-bold text-[20px]">
                Customer Name: {selectedBill.customer_name}
              </p>
              <p className="font-bold text-[20px]">
                POS Date: {new Date(selectedBill.pos_date).toLocaleDateString()}
              </p>
              <p className="font-bold text-[20px]">
                POS Sub Total: {formatCurrency(selectedBill.pos_sub_total)}
              </p>
              <p className="font-bold text-[20px]">
                POS Discount: {formatCurrency(selectedBill.pos_discount)}
              </p>
              <p className="font-bold text-[20px]">
                POS Net Total: {formatCurrency(selectedBill.pos_net_total)}
              </p>
            </div>

            {/* Table to display bill items */}
            <div className="w-full flex items-center justify-center mt-5">
              <div className="bg-[#F3F8FF] w-full min-h-[300px] mx-16 rounded-t-[40px] rounded-b-[50px] p-4">
                <table className="w-full table-auto border-collapse">
                  <thead className="bg-PrimaryColor1 text-white">
                    <tr>
                      <th className="py-2 px-4">#</th>
                      <th className="py-2 px-4">Item Name</th>
                      <th className="py-2 px-4">Batch ID</th>
                      <th className="py-2 px-4">Quantity</th>
                      <th className="py-2 px-4">Price</th>
                      <th className="py-2 px-4">Total</th>
                      <th className="py-2 px-4">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billItems.length > 0 ? (
                      billItems.map((item, index) => (
                        <tr
                          key={item.inventory_batch_id}
                          className="text-center"
                        >
                          <td className="border px-4 py-2">{index + 1}</td>
                          <td className="border px-4 py-2">{item.item_name}</td>
                          <td className="border px-4 py-2">
                            {item.inventory_batch_id}
                          </td>
                          <td className="border px-4 py-2">
                            <input
                              type="number"
                              value={item.pos_item_qty}
                              onChange={(e) =>
                                handleQuantityChange(
                                  item.inventory_batch_id,
                                  parseInt(e.target.value)
                                )
                              }
                              className="border rounded-lg p-2 w-20"
                            />
                          </td>
                          <td className="border px-4 py-2">
                            {formatCurrency(item.pos_items_price)}
                          </td>
                          <td className="border px-4 py-2">
                            {formatCurrency(
                              item.pos_item_qty *
                                parseFloat(item.pos_items_price)
                            )}
                          </td>
                          <td className="border px-4 py-2">
                            <button
                              className="bg-red-500 text-white p-2 rounded-lg"
                              onClick={() =>
                                handleDelete(item.inventory_batch_id)
                              }
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-4">
                          No items found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Return Reason Input */}
            <div className="mt-5">
              <label className="font-bold text-[20px]">Return Reason:</label>
              <input
                className="border border-black rounded-xl p-2 w-[300px] ml-3"
                value={returnReason}
                onChange={(e) => setReturnReason(e.target.value)} // Set return reason
                placeholder="Enter return reason"
              />
            </div>

            {/* Make Return Button */}
            <div className="mt-5">
              <button
                className="bg-green-500 text-white p-3 rounded-lg"
                onClick={handleMakeReturn} // Make return when clicked
              >
                Make Return
              </button>
            </div>

            {/* Display outstanding balance and return ID after return */}
            {returnId && (
              <div className="mt-10 bg-white p-5 rounded-lg shadow-lg">
                <p className="font-bold text-[20px]">Return ID: {returnId}</p>
                <p className="font-bold text-[20px]">
                  Outstanding Balance: {formatCurrency(outstandingBalance)}
                </p>

                {/* Option to create a new POS bill based on the outstanding balance */}
                <button className="bg-blue-500 text-white p-3 rounded-lg mt-5">
                  Create New POS Bill
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReturnSale;
