/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Printer from "../../../../Assets/icons/print 1.png";
import IdGenerate from "../../../../utils/id_generate";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function ApproveItemReqFromBranch() {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedRecord = location.state?.selectedRecord || {};
  const [tableData, setTableData] = useState(selectedRecord.items || []);
  const [userId, setUserId] = useState("USER-000000");
  const [relID, setRelID] = useState("");

  console.log(selectedRecord);
  const [approvedQuantity, setApprovedQuantity] = useState(
    selectedRecord.approved_quantity || ""
  );
  useEffect(() => {
    setRelID(IdGenerate("REL"));
  }, []);
  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0]; // Format the date to ISO string

  const contentToPrint = useRef(null);
  // const handlePrint = useReactToPrint({
  //   content: () => contentToPrint.current,
  //   documentTitle: "Print This Document",
  //   onBeforePrint: () => console.log("before printing..."),
  //   onAfterPrint: () => console.log("after printing..."),
  //   removeAfterPrint: true,
  // });

  // const exportPDF = () => {
  //   const doc = new jsPDF();

  //   doc.text("Item Request From Branch", 14, 10);

  //   const tableColumn = [
  //     "Item Name",
  //     "Requested Qty",
  //     "Release Qty",
  //     "Remarks",
  //   ];
  //   const tableRows = [];

  //   tableData.forEach((item) => {
  //     const rowData = [
  //       item.item_name,
  //       item.inventory_request_item_qty,
  //       item.item_release_qty || "",
  //       item.inventory_release_item_description || "",
  //     ];
  //     tableRows.push(rowData);
  //   });

  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //     startY: 20,

  //     theme: "grid",
  //   });

  //   doc.save("Item Request From Branch.pdf");
  // };

  const exportToPDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Item Request From Branch", 14, 20);

    doc.setFontSize(12);
    doc.text(
      `Request Order Id: ${selectedRecord.inventory_request_id}`,
      14,
      30
    );
    doc.text(`RO Date: ${selectedRecord.inventory_request_date}`, 14, 40);

    const tableColumn = [
      "Item Name",
      "Requested Qty",
      "Release Qty",
      "Remarks",
    ];

    const tableRows = [];

    tableData.forEach((item) => {
      const rowData = [
        item.item_name,
        item.inventory_request_item_qty,
        item.item_release_qty || "",
        item.inventory_release_item_description || "",
      ];
      tableRows.push(rowData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 60,
    });

    doc.save("Item Request From Branch.pdf");
  };

  const SubmitHandler = async () => {
    const inventory_request_items = tableData.map((item) => ({
      item_id: item.item_id,
      item_name: item.item_name,
      inventory_request_item_qty: item.inventory_request_item_qty,
      inventory_release_item_qty: parseFloat(item.item_release_qty), // Ensure it's a number
      inventory_release_item_description:
        item.inventory_release_item_description,
    }));

    const data = {
      inventory_request_id: selectedRecord.inventory_request_id,
      inventory_request_user_id: userId,
      inventory_request_date: selectedRecord.inventory_request_date,
      inventory_release_date: formattedDate,
      inventory_request_status: "RELEASED",
      inventory_request_by: selectedRecord.inventory_request_by,
      inventory_release_id: relID,
      inventory_release_user_id: userId,
      inventory_request_items,
    };

    console.log(data);
    //check conditions

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/inventoryRequest/${data.inventory_request_id}`,
        data
      );
      // console.log(res.data);
      // console.log(res.status);

      if (res.status === 200 || res.status === 201) {
        console.log("hello:");
        console.log("data releaseed:", data);
        window.alert("Successfully Release Branch Item Request");
        navigate("/inventory/branch-item-req");
      }
    } catch (error) {
      setAlert({
        status: "error",
        alert: "Something Went Wrong",
        show: true,
      });
    }
  };

  const SubmitReject = async () => {
    const inventory_request_items = tableData.map((item) => ({
      item_id: item.item_id,
      item_name: item.item_name,
      inventory_request_item_qty: item.inventory_request_item_qty,
      inventory_release_item_qty: parseFloat(item.item_release_qty), // Ensure it's a number
      inventory_release_item_description:
        item.inventory_release_item_description,
    }));

    const data = {
      inventory_request_id: selectedRecord.inventory_request_id,
      inventory_request_user_id: userId,
      inventory_request_date: selectedRecord.inventory_request_date,
      inventory_release_date: formattedDate,
      inventory_request_status: "REJECTED",
      inventory_request_by: selectedRecord.inventory_request_by,
      inventory_release_id: relID,
      inventory_release_user_id: userId,
      inventory_request_items,
    };

    console.log(data);
    //check conditions

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/inventoryRequest/${data.inventory_request_id}`,
        data
      );
      // console.log(res.data);
      // console.log(res.status);

      if (res.status === 200 || res.status === 201) {
        console.log("data rejected:", data);
        window.alert("Successfully Rejected Branch Item Request");
        navigate("/inventory/branch-item-req");
      }
    } catch (error) {
      setAlert({
        status: "error",
        alert: "Something Went Wrong",
        show: true,
      });
    }
  };

  const Data = [...tableData]; // Create a copy of tableData
  Data.push({
    item_name: selectedRecord.item_name,
    item_id: selectedRecord.item_id,
    inventory_request_item_qty: selectedRecord.inventory_request_item_qty,
    item_release_qty: 0,
    inventory_release_item_description: " ",
  });

  return (
    <div className="p-10 bg-background">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">Item Request From Branch</p>
      <div>
        <div className="flex items-center ">
          <p className="label-1">Item Request Release Id</p>
          <p className="label-1">:</p>
          <p className="font-[22px] w-[310px] h-[46px] rounded-[23px] bg-[#F3F8FF] ml-10 border border-[#00000036] px-7 flex items-center ">
            {relID}
          </p>
        </div>
      </div>

      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1">Request Order Details</p>
          <div className="flex flex-col flex-wrap justify-between items-center">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Request Order Id :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_id}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">RO Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_date}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="header-1">Request Order Items</p>

      {/* table */}
      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Requested Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Release Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Remarks</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="">
            {tableData.length > 0
              ? tableData.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex items-start justify-around my-3  "
                  >
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] text-center">
                      {item.item_name}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.inventory_request_item_qty}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      <input
                        type="number"
                        value={item.item_release_qty || ""}
                        className={
                          item.item_release_qty >
                          item.inventory_request_item_qty
                            ? "border-[#ef0e0ef7] rounded-2xl w-full border-4 px-1 focus:outline-none"
                            : "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        }
                        onChange={(e) => {
                          let temp = [...tableData];
                          temp[index].item_release_qty = parseInt(
                            e.target.value,
                            10
                          );
                          setTableData(temp);
                        }}
                      />
                    </p>
                    <p className="w-[200px] p1 text-[#656565] text-center">
                      <textarea
                        className="w-[200px] border border-[#656565] my-2 rounded-2xl"
                        value={item.inventory_release_item_description}
                        onChange={(e) => {
                          const temp = [...tableData];
                          temp[index].inventory_release_item_description =
                            e.target.value;
                          setTableData(temp);
                        }}
                      />
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-start px-16 pt-3 ">
        <div className="w-[620px]  rounded-[23px] bg-[#F3F8FF] px-10 py-7 flex flex-col justify-between gap-y-7">
          <div className="w-full flex items-center gap-2">
            <p className="p1 text-[#656565] w-[500px]">Branch Owner Name:</p>
            <div className="relative w-[300px]">
              <p className="font-[22px] w-[310px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_by}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button
          className="w-[138px] h-[41px] flex items-center justify-between bg-white border rounded-[23px] px-5"
          onClick={() => {
            exportToPDF(null, () => contentToPrint.current);
          }}
        >
          <img src={Printer} alt="" />
          <p className="p2 text-[#656565]">Print</p>
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={SubmitReject}
        >
          Reject
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={SubmitHandler}
        >
          Release
        </button>
      </div>

      {/* Print layout */}

      {/* <div className="p-10 hidden">
        <div className="p-10" ref={contentToPrint}>
          <p className="header-1">Inventory Purchase (GRN)</p>

          <div id="printable" className="formal-document">
            <h1 className="document-title">Goods Received Note</h1>
            <div className="document-section">
              <h2 className="section-title">Purchase Order Details</h2>
              <div className="section-content">
                <p>
                  <strong>Purchase Order Id:</strong> {PoData[0].po_id}
                </p>
                <p>
                  <strong>Supplier:</strong> {PoData[0].supplier_name}
                </p>
                <p>
                  <strong>PO Date:</strong> {PoData[0].po_issue_date}
                </p>
              </div>
            </div>

            <div className="document-section">
              <h2 className="section-title">Purchasing Items</h2>
              <table className="items-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Item Name</th>
                    <th>Requested Qty</th>
                    <th>Supplied Qty</th>
                    <th>Unit</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {poItemsData.length > 0 &&
                    poItemsData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.item_name}</td>
                        <td>{item.requested_qty}</td>
                        <td>{item.supplied_qty}</td>
                        <td>{item.unit}</td>
                        <td>{item.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
