/** @format */

import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import Arrow from "./../../../Assets/icons/down-arrow.png";
import IdGenerate from "../../../utils/id_generate";
import axios from "axios";
import Cookies from "js-cookie";
import usePermissions from "../../../components/permissions/permission";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function InventoryAdjustment() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  // const [userId, setUserId] = useState("USER-000000");

  const [resultsShow, setResultShow] = useState(false);
  const userId = Cookies.get("employee_no");
  const [adjustmentId, setAdjustmentId] = useState(IdGenerate(""));
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [itemArrow, setItemArrow] = useState(false);
  const [adjustedQty, setAdjustedQty] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setAdjustmentId(IdGenerate("ADJUST"));
  }, []);

  const handleItemSearch = async (inputValue) => {
    if (inputValue !== "") {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_ENDPOINT
          }/v1/inventory/inventory/getInventoryBatchID/${encodeURIComponent(
            inputValue
          )}`
        );

        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Get items:", data);

        // Extract relevant data from response to populate options
        const options = data.map((item) => ({
          value: item.inventory_batch_id,
          label: `${item.inventory_batch_id} - ${item.item_name}`, // Combining inventory_batch_id and item_name
          inventory_item_id: item.inventory_item_id,
          shadow_qty: item.shadow_qty,
          location: item.location,
          store_id: item.store_id,
          item_name: item.item_name,
          item_measure_unit: item.item_measure_unit,
        }));

        return options;
      } catch (error) {
        console.error(error);
      }
    } else {
      return [];
    }
  };

  const handleItemSelect = (selectedOption) => {
    setSelectedItem(selectedOption);

    // Check if the item already exists in the tableData
    const itemExists = tableData.some(
      (item) => item.value === selectedOption.value
    );

    if (!itemExists) {
      // Add selected item to tableData
      setTableData((prevTableData) => [
        ...prevTableData,
        {
          item_name: selectedOption.item_name,
          item_batch_id: selectedOption.value,
          item_available_qty: selectedOption.shadow_qty,
          inventory_item_id: selectedOption.inventory_item_id,
          location: selectedOption.location,
          store_id: selectedOption.store_id,
          item_measure_unit: selectedOption.item_measure_unit, // Default value; should be updated based on actual available qty
        },
      ]);
    }
  };

  const SubmitHandler = async () => {
    const data = {
      adjustment_id: adjustmentId,
      user_id: userId,
      date: formattedDate,
      items: tableData.map((item) => ({
        item_id: item.item_batch_id,
        item_qty: item.batch_qty,
        item_description: item.adjustment_remarks,
      })),
    };
    console.log("send data:", data);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/adjustments/addAdjustment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const resData = await response.json();
        console.log(resData);
        alert("Adjustment Added Successfully");
        setTableData([]);
        setAdjustedQty([]);
        setResultShow(false);
      } else if (response.status === 409) {
        alert("Already Exist");
      } else if (response.status === 400) {
        alert("Adjustment Not Added");
      } else if (response.status === 500) {
        alert("Internal Server Error");
      } else if (response.status === 404) {
        alert("Adjustment Not Found");
      } else if (response.status === 403) {
        alert("Forbidden");
      } else if (response.status === 401) {
        alert("Unauthorized");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An unexpected error occurred");
    }
  };

  const customStylesItems = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "transparent",
      borderRadius: "100px",
      width: "900px",
      borderColor: state.isFocused ? "red" : "blacks",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "item name",
      color: "white",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
      display: "none",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
      width: "122%",
      position: "absolute",
      zIndex: "100",
      top: "30px",
      left: "-160px",
      borderRadius: "20px",
      overflow: "hidden",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  const handleClickCancel = () => {
    navigate(-1);
  };
  return (
    <div className="p-10 bg-background">
      <p className="header-1">Inventory Adjustment</p>

      <div className="w-full flex justify-start items-center mt-10 ">
        <div className="w-[670px] h-[82px] flex justify-between items-center gap-x-10 bg-[#F3F8FF] px-10 rounded-[46px] border border-[#00000036]">
          <p className="label-1">Adjustment Id :</p>
          <input
            className="input-1 border border-[#00000036]"
            type="text"
            disabled
            value={adjustmentId}
          />
        </div>
      </div>

      <p className="header-1 mb-10">Adjustment Item Details</p>

      <div className="w-full flex items-center justify-center px-12">
        <div className="bg-PrimaryColor1 w-full h-[82px] rounded-[100px] mx-20 flex items-center justify-between">
          <p className="label-1 ml-10 text-white">Item Batch ID :</p>
          <AsyncSelect
            styles={customStylesItems}
            cacheOptions
            loadOptions={handleItemSearch}
            onMenuOpen={() => setItemArrow(true)}
            onMenuClose={() => setItemArrow(false)}
            onChange={handleItemSelect}
            value={selectedItem}
          />
          <img
            src={Arrow}
            alt="arrow"
            className={
              itemArrow
                ? "mr-7 size-6 transition duration-500 invert"
                : "mr-7 size-6 transition rotate-180 duration-500 invert"
            }
          />
        </div>
      </div>

      <div className="flex flex-col justify-start  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
        <div className="w-full h-[86px] flex items-center justify-around bg-[#74d4f4] rounded-[46px] mr-5">
          <p className="w-10 p1 text-[#656565] text-center">#</p>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Batch ID
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Available Qty
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Adjusted Quantity
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Location</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Remarks</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Action</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
          {tableData.length > 0
            ? tableData.map((item, index) => (
                <div key={index} className="w-full  flex justify-around ">
                  <div className="w-10 flex items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {index + 1}
                    </p>
                  </div>
                  <div className="w-[350px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_batch_id}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_available_qty}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      <input
                        type="number"
                        className="w-full border border-[#797979] rounded-[40px] px-[10px]"
                        value={item.batch_qty}
                        onChange={(e) => {
                          let value = e.target.value;

                          let temp = [...tableData];
                          temp[index].batch_qty = value;
                          setAdjustedQty(temp);
                        }}
                      />
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.location}
                    </p>
                  </div>

                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      <textarea
                        className="w-full border border-[#797979] rounded-[40px] px-[10px]"
                        type="text"
                        value={item.adjustment_remarks}
                        onChange={(e) => {
                          let temp = [...tableData];
                          temp[index].adjustment_remarks = e.target.value;
                          setRemarks(temp);
                        }}
                      />
                    </p>
                  </div>

                  <div className="w-[200px] flex justify-center items-center">
                    {hasPermission(1240) ? (
                      <button
                        className="w-[full] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4"
                        onClick={() => {
                          let temp = [...tableData];
                          temp.splice(index, 1);
                          setTableData(temp);
                        }}
                      >
                        <p className="p2 text-white">delete</p>
                      </button>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <div className="w-full flex justify-end items-center gap-x-10 my-10">
        {hasPermission(1241) && (
          <button className="w-[118px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4">
            <p className="p2 text-white" onClick={SubmitHandler}>
              Submit
            </p>
          </button>
        )}
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4">
          <p className="p2 text-white" onClick={handleClickCancel}>
            Cancel
          </p>
        </button>
      </div>
    </div>
  );
}
