/** @format */

import React, { useState, useEffect } from "react";

const WarehouseCompleteTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [recipes, setRecipes] = useState([]);
  const [statusMap, setStatusMap] = useState({});
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/finalProduct/get-pricing-approved-product-warehouse?product_approve_status=APPROVE`
    )
      .then((response) => response.json())
      .then((data) => {
        const extractedRecipes = data.map((recipe) => ({
          id: recipe._id,
          productName: recipe.product_name,
          productCreateBy: recipe.product_create_by,
          productUnitQuantity: recipe.product_unit_quantity,
          productMeasureUnitSymbol: recipe.product_measure_unit_symbol,
          productFinalQuantity: recipe.product_final_quantity,
          productFinalUnitSymbol: recipe.product_final_unit_symbol,
          productCreateAt: recipe.product_create_at,
        }));
        setRecipes(extractedRecipes);
      })
      .catch((error) => {
        console.error("Error fetching recipes:", error);
      });
  }, []);

  const getStatusColor = (index) => {
    switch (statusMap[index]) {
      case "Approve":
        return "bg-[#F5E450] bg-opacity-50";
      case "Reject":
        return "bg-[#F55050] bg-opacity-50";
      default:
        return "";
    }
  };

  const totalPages = Math.ceil(recipes.length / itemsPerPage);
  const maxPageNumbers = 5;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md mr-2 ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = recipes.slice(startIndex, endIndex);

  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#74d4f4] w-[100%] rounded-xl">
        Complete Productions At Warehouse
      </p>
      <div className="flex mt-10  ml-5">
        <div className="flex mt-10 overflow-x-auto">
          {" "}
          <table className="border-collapse border border-gray-200 rounded-lg ml-6 w-[100%] ">
            <thead>
              <tr className="bg-[#74d4f4]">
                <th className="border border-gray-200 px-4 py-4 w-80 text-center">
                  product_name{" "}
                </th>
                <th className="border border-gray-200 px-4 py-4 w-80 text-center">
                  product_create_by{" "}
                </th>
                <th className="border border-gray-200 px-4 py-4 w-80 text-center">
                  product_unit_quantity{" "}
                </th>
                <th className="border border-gray-200 px-4 py-4 w-80 text-center">
                  product_measure_unit_symbol{" "}
                </th>
                <th className="border border-gray-200 px-4 py-4 w-80 text-center">
                  product_final_quantity{" "}
                </th>
                <th className="border border-gray-200 px-4 py-4 w-80 text-center">
                  product_final_unit_symbol{" "}
                </th>
                <th className="border border-gray-200 px-4 py-4 w-80 text-center">
                  product_create_at{" "}
                </th>
              </tr>
            </thead>

            <tbody>
              {recipes.map((recipe, index) => (
                <tr key={index} className="border border-gray-200 px-4 py-2">
                  <td className="text-center">{recipe.productName}</td>
                  <td className="text-center">{recipe.productCreateBy}</td>
                  <td className="text-center">
                    {recipe.productUnitQuantity}
                  </td>{" "}
                  <td className="text-center">
                    {recipe.productMeasureUnitSymbol}
                  </td>
                  <td className="text-center">{recipe.productFinalQuantity}</td>{" "}
                  <td className="text-center">
                    {recipe.productFinalUnitSymbol}
                  </td>
                  <td className="text-center">
                    {" "}
                    {new Date(recipe.productCreateAt).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevious}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200  cursor-pointer"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200  cursor-pointer"
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
      {/* End Pagination */}
    </div>
  );
};

export default WarehouseCompleteTable;
