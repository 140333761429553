/** @format */

import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import axios from "axios";

const Pos_Report = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [branch, setBranch] = useState(""); // New state for branch filter
  const [filteredData, setFilteredData] = useState([]);
  const [posData, setPosData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 12; // Limit rows per page to 12
  const { REACT_APP_API_ENDPOINT } = process.env;

  // API endpoint
  const apiEndpoint = `${REACT_APP_API_ENDPOINT}/v1/inventory/pos/getPosWithReturns`;

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiEndpoint);
        setPosData(response.data);
        setFilteredData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Filter the data based on date range, status, and branch
  const filterData = () => {
    const filtered = posData.filter((item) => {
      const itemDate = new Date(item.pos_date);

      const isWithinDateRange =
        (!startDate || new Date(startDate) <= itemDate) &&
        (!endDate || itemDate <= new Date(endDate));

      const matchesStatus = !status || item.pos_status === status;
      const matchesBranch = !branch || item.branch_id === branch;

      return isWithinDateRange && matchesStatus && matchesBranch;
    });

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page after filtering
  };

  // Automatically filter when startDate, endDate, status, or branch changes
  useEffect(() => {
    filterData();
  }, [startDate, endDate, status, branch]);

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  // Prepare CSV headers
  const headers = [
    { label: "POS ID", key: "pos_id" },
    { label: "Branch", key: "branch_id" },
    { label: "Date", key: "pos_date" },
    { label: "Status", key: "pos_status" },
    { label: "Customer", key: "customer_id" },
    { label: "Bill (Net Total)", key: "pos_net_total" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
  ];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div className="mx-6 mt-5">
        <p className="text-[25px] font-semibold">POS Report</p>

        {/* Date Range Filter */}
        <div className="flex space-x-4 my-4 items-center">
          <div>
            <label className="font-semibold text-[20px]">Start Date: </label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border p-3 rounded-lg"
            />
          </div>
          <div>
            <label className="font-semibold text-[20px]">End Date: </label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border p-3 rounded-lg"
            />
          </div>

          {/* Status Filter */}
          <div>
            <label>Status: </label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="border px-2 py-1 rounded-md"
            >
              <option value="">All</option>
              <option value="Closed">Closed</option>
              <option value="Pending">Pending</option>
              <option value="Return">Return</option>
              <option value="Rejected">Rejected</option>
              <option value="Approved">Approved</option>
            </select>
          </div>

          {/* Branch Filter */}
          <div>
            <label>Branch: </label>
            <select
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
              className="border px-2 py-1 rounded-md"
            >
              <option value="">All</option>
              <option value="Branch1">Branch1</option>
              <option value="Branch2">Branch2</option>
              <option value="Mobile POS">Mobile POS</option>
            </select>
          </div>

          {/* CSV Export Button */}
          <div className="ml-4">
            <CSVLink
              data={filteredData.map((item) => ({
                ...item,
                start_date: startDate || "N/A",
                end_date: endDate || "N/A",
              }))}
              headers={headers}
              filename={`pos_report_${startDate}_${endDate}.csv`}
              className="bg-primary text-white px-4 py-2 rounded-md"
            >
              Export CSV
            </CSVLink>
          </div>
        </div>

        {/* Table */}
        <div className="w-full flex items-center justify-center mt-5">
          <div className="bg-[#F3F8FF] w-full min-h-[300px] mx-16 rounded-t-[40px] rounded-b-[50px] p-4">
            <table className="w-full table-auto border-collapse">
              <thead className="bg-PrimaryColor1 text-white rounded-full">
                <tr>
                  <th className="py-2 px-4">POS ID</th>
                  <th className="py-2 px-4">Branch</th>
                  <th className="py-2 px-4">POS Type</th>
                  <th className="py-2 px-4">Date</th>
                  <th className="py-2 px-4">Status</th>
                  <th className="py-2 px-4">Customer</th>
                  <th className="py-2 px-4">Bill (Net Total)</th>
                </tr>
              </thead>
              <tbody>
                {currentRows.length > 0 ? (
                  currentRows.map((item, index) => (
                    <tr key={index} className="text-center">
                      <td className="border px-4 py-2">{item.pos_id}</td>
                      <td className="border px-4 py-2">{item.branch_id}</td>
                      <td className="border px-4 py-2">{item.pos_type}</td>
                      <td className="border px-4 py-2">
                        {new Date(item.pos_date).toLocaleDateString()}
                      </td>
                      <td className="border px-4 py-2">{item.pos_status}</td>
                      <td className="border px-4 py-2">{item.customer_id}</td>
                      <td className="border px-4 py-2">
                        L.K.R {item.pos_net_total}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      No items found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination */}
            {filteredData.length > rowsPerPage && (
              <div className="flex justify-center mt-4">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`px-4 py-2 mx-1 border rounded ${
                      index + 1 === currentPage
                        ? "bg-blue-500 text-white"
                        : "bg-white text-black"
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pos_Report;
