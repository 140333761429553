/** @format */

import React, { useState, useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import usePermissions from "../../../components/permissions/permission";
import { IoMdCloseCircle } from "react-icons/io";
import Cookies from "js-cookie";
import AsyncSelect from "react-select/async";

function Unit() {
  const { hasPermission } = usePermissions();
  const userId = Cookies.get("employee_no");
  const [showPopup, setShowPopup] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [shortName, setShortName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [baseUnits, setBaseUnits] = useState([]); // State to store base units
  const [selectedBaseUnitId, setSelectedBaseUnitId] = useState(""); // State to store selected base unit ID
  const [operatorValue, setOperatorValue] = useState(""); // State to store operator value
  const [currentPage, setCurrentPage] = useState(1);
  const [unitToDelete, setUnitToDelete] = useState(null); // State to store the unit to delete
  const itemsPerPage = 5; // Change the number of items per page as needed
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [rawItems, setRawItems] = useState([]);
  const [subItem, setSubItem] = useState("");
  const [quantity, setQuantity] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [rawItemIds, setRawItemIds] = useState({});
  const [selectItem, setSelectItem] = useState("");

  useEffect(() => {
    fetchData();
    fetchBaseUnits();
    fetchRawItems();
  }, []);

  // Define a function to fetch data
  const fetchRawItems = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/rawitem/allrawitem`
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      // Transform data to build the necessary state objects
      const rawItemDetails = data.reduce((acc, item) => {
        acc[item.raw_item_name] = {
          id: item.raw_item_id,
          unit: item.raw_item_measure_unit,
          name: item.raw_item_name,
        };
        return acc;
      }, {});

      // Map raw item names and IDs together
      const rawItemNames = data.map((item) => ({
        id: item.raw_item_id,
        name: item.raw_item_name,
      }));

      // Update state with the transformed data
      setRawItemIds(rawItemDetails);
      setRawItems(rawItemNames);
    } catch (error) {
      console.error("Error fetching raw items:", error);
    }
  };

  const handleItemSearch = async (inputValue) => {
    if (!inputValue) return []; // Return empty array if input is empty

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }/v1/inventory/item/finish-item-name-like?search=${encodeURIComponent(
          inputValue
        )}`
      );

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      const data = await response.json();

      // Transform data into select options
      return data.items.map((item) => ({
        value: item.item_id,
        label: item.item_name,
      }));
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const handleItemSelect = (selectedOption) => {
    setSelectItem(selectedOption); // Update local state
  };

  const fetchData = () => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/unit/getSecondary`)
      .then((response) => response.json())
      .then((data) => {
        const mappedData = data.map(
          ({
            unit_name,
            unit_symbol,
            unit_base_unit_id,
            unit_operator_value,
          }) => ({
            unitName: unit_name,
            shortName: unit_symbol,
            unitId: unit_base_unit_id, // Assuming _id is the unique identifier for units
            Operation: unit_operator_value,
          })
        );
        setTableData(mappedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const fetchBaseUnits = () => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/unit/get`)
      .then((response) => response.json())
      .then((data) => {
        setBaseUnits(data);
      })
      .catch((error) => console.error("Error fetching base units:", error));
  };

  useEffect(() => {
    if (tableData.length && baseUnits.length) {
      const updatedTableData = tableData.map((item) => {
        const baseUnit = baseUnits.find((unit) => unit._id === item.unitId);
        return {
          ...item,
          baseUnitSymbol: baseUnit ? baseUnit.unit_symbol : "",
        };
      });
      setTableData(updatedTableData);
    }
  }, [baseUnits]);

  const handleCreateClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const submitForm = () => {
    // Prepare the items array from the selectedItems state
    const items = selectedItems.map((item) => ({
      item_id: item.id, // Assuming subItem holds the item ID
      quantity: item.quantity,
    }));

    // Construct the formData object with the items included
    const formData = {
      unit_name: selectItem.label,
      unit_symbol: selectItem.label,
      unit_create_by: userId, // Change as necessary
      unit_base_unit_id: selectedBaseUnitId,
      unit_operator_value: operatorValue,
      items, // Add the items array here
    };

    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/unit/addSecondary`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          alert("Unit Added Successfully");
          setUnitName("");
          setShortName("");
          setSelectedBaseUnitId("");
          setOperatorValue("");
          setSelectedItems([]);
          setShowPopup(false);
          fetchData();
        } else {
          console.error("Failed to add unit");
          alert("Failed to add unit");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error occurred. Please try again.");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  const handleDelete = (index, unitId) => {
    setUnitToDelete({ index, unitId });
  };

  const confirmDelete = () => {
    if (unitToDelete) {
      const { index, unitId } = unitToDelete;
      fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/unit/delete?unit_id=${unitId}`,
        {
          method: "DELETE",
        }
      )
        .then((response) => {
          if (response.ok) {
            console.log("Unit deleted successfully");
            const updatedData = [...tableData];
            updatedData.splice(index, 1);
            setTableData(updatedData);
          } else {
            console.error("Failed to delete unit");
            alert("Failed to delete unit");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Error occurred. Please try again.");
        })
        .finally(() => {
          setUnitToDelete(null); // Reset unitToDelete state after delete operation
        });
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    const maxPageNumbers = 5;
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md mr-2 ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleAddSubItem = () => {
    if (subItem && quantity > 0) {
      const selectedItem = rawItems.find((item) => item.id === subItem);

      if (selectedItem && !selectedItems.some((item) => item.id === subItem)) {
        setSelectedItems((prevSelectedItems) => [
          ...prevSelectedItems,
          {
            id: selectedItem.id, // Store raw item ID
            name: selectedItem.name, // Store raw item name
            quantity: parseInt(quantity), // Ensure quantity is a number
          },
        ]);
        setSubItem(""); // Reset after adding
        setQuantity(""); // Reset after adding
      }
    }
  };

  const handleDeleteSubItem = (index) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((_, i) => i !== index)
    );
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = tableData.slice(startIndex, endIndex);

  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-10 mt-5 bg-[#74d4f4] w-[95%] rounded-xl">
        Make Units
      </p>
      {hasPermission(1400) && (
        <button
          onClick={handleCreateClick}
          className="bg-[#04acec] py-2 px-4 rounded ml-10 mt-5"
        >
          Create
        </button>
      )}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg relative w-1/2">
            <h2 className="text-lg font-bold mb-4">Create Unit</h2>
            <button
              onClick={handleClosePopup}
              className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700"
            >
              Close
            </button>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Select Unit Name
                </label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleItemSearch}
                  value={selectItem}
                  onChange={handleItemSelect}
                  placeholder="Search and select an Item"
                  className="w-full"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Select Base Unit
                </label>
                <select
                  value={selectedBaseUnitId}
                  onChange={(e) => setSelectedBaseUnitId(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                  required
                >
                  <option value="">Select a base unit</option>
                  {baseUnits.map((unit) => (
                    <option key={unit._id} value={unit._id}>
                      {unit.unit_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Select Item and Enter Quantity
                </label>
                <div className="flex items-center gap-2">
                  <select
                    className="border border-[#00000036] p-2 w-full"
                    value={subItem}
                    onChange={(e) => setSubItem(e.target.value)}
                  >
                    <option value="">Select Raw Item</option>
                    {rawItems.map((rawItem, index) => (
                      <option key={index} value={rawItem.id}>
                        {rawItem.name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="number"
                    className="border border-gray-300 p-2 w-24"
                    placeholder="Qty"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={handleAddSubItem}
                    className="bg-[#74d4f4] text-white h-[30px] w-[70px] rounded-md"
                  >
                    Add
                  </button>
                </div>
              </div>
              {/* Display selected items with quantities */}
              <div className="w-full flex flex-wrap gap-2 mb-8">
                {selectedItems.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center text-[12px] bg-[#74d4f4] justify-between rounded-lg p-1"
                  >
                    <div>
                      {item.name} - {item.quantity}
                    </div>
                    <IoMdCloseCircle
                      onClick={() => handleDeleteSubItem(index)}
                      className="text-black cursor-pointer ml-2"
                      size={20}
                    />
                  </div>
                ))}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Enter Operator Value
                </label>
                <input
                  type="number"
                  value={operatorValue}
                  onChange={(e) => setOperatorValue(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-[#04acec] py-2 px-4 rounded mt-4"
              >
                Create
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="mt-6 mx-5 ml-10 overflow-x-auto">
        <table className="min-w-full table-auto border border-gray-200">
          <thead>
            <tr className="bg-[#74d4f4]">
              <th className="border border-gray-200 px-10 py-4 text-left">
                Unit Name
              </th>
              <th className="border border-gray-200 px-10 py-4 text-left">
                Short Name
              </th>
              <th className="border border-gray-200 px-10 py-4 text-left">
                Base Unit
              </th>
              <th className="border border-gray-200 px-10 py-4 text-left">
                Operator Value
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.length > 0 ? (
              paginatedData.map((item, index) => (
                <tr key={index} className="text-center">
                  <td className="border border-gray-200 px-10 py-4">
                    {item.unitName}
                  </td>
                  <td className="border border-gray-200 px-10 py-4">
                    {item.shortName}
                  </td>
                  <td className="border border-gray-200 px-10 py-4">
                    {item.baseUnitSymbol}
                  </td>
                  <td className="border border-gray-200 px-10 py-4">
                    {item.Operation}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No units found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mt-5 ml-10">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className="bg-gray-200 px-3 py-1 rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <div className="flex space-x-2">{renderPageNumbers()}</div>
        <button
          onClick={handleNext}
          disabled={currentPage === Math.ceil(tableData.length / itemsPerPage)}
          className="bg-gray-200 px-3 py-1 mr-10 rounded-md disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {unitToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg relative w-1/2">
            <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this unit?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setUnitToDelete(null)}
                className="bg-gray-300 px-4 py-2 rounded-md mr-2"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="bg-red-600 text-white px-4 py-2 rounded-md"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Unit;
