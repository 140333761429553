/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { IoCalendarOutline } from "react-icons/io5";
import "react-datepicker/dist/react-datepicker.css";
import cloudiccon from "../../../../Assets/icons/cloud-add.png";

const Acceptance_Letter = () => {
  const [proIt, setproIt] = useState("");
  const [cat, setcat] = useState("");
  const [TenId, setTenId] = useState("");
  const [conId, setconId] = useState("");
  const [tName, settName] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [files, setFiles] = useState({});
  const addFile = (file) => {
    const newFiles = { ...files };
    newFiles[file.name] = file;
    setFiles(newFiles);
  };

  const handleSubmit = () => {
    alert(`Submitted Files:\n${JSON.stringify(files)}`);
    console.log(files);
  };
  const handleCancel = () => {
    setFiles({});
  };
  const handleFileInputChange = (e) => {
    const fileList = e.target.files;
    for (const file of fileList) {
      addFile(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const fileList = e.dataTransfer.files;
    for (const file of fileList) {
      addFile(file);
    }
  };

  // Reset function
  const handleReset = () => {
    setconId("");
    setcat("");
    setTenId("");
    setproIt("");
    settName("");
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div className="bg-white min-w-full min-h-16 flex justify-end items-center">
        <div className="bg-[#D6E5C6] min-w-[44px] min-h-[44px] rounded-full"></div>
        <p className=" text-[16px] text-[#071C50] font-bold ml-4 mr-4">
          Sahansa
        </p>
      </div>
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Acceptance Letter
        </p>
      </div>
      <form class="form relative ml-6">
        <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
          <svg
            class="w-5 h-5 text-gray-700"
            aria-labelledby="search"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            height="16"
            width="17"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="1.333"
              stroke="currentColor"
              d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
            ></path>
          </svg>
        </button>
        <input
          type="text"
          required=""
          placeholder="Search..."
          class="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
        />
        <button className="btn btn-primary text-[#797C80] hover:bg-gray-100">
          RESET
        </button>
      </form>
      <div className="w-1/2 pr-4 flex items-center ml-6">
        <DatePicker
          showIcon
          icon={<IoCalendarOutline className="right-0" />}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          className=" rounded-[18px]"
        />
        <label className="ml-4 text-[#797C80] font-poppins font-bold text-[18px]">
          Select Date
        </label>
      </div>

      <div>
        {/* Add deadline part */}
        <p className="text-[40px] font-sans ml-6 mt-10 font-bold text-[#656565] ">
          Add Deadline
        </p>
      </div>
      <div className="square absolute ml-6 mt-[1%] w-full sm:w-4/5 rounded-[46px] min-h-16 border border-black bg-[#F3F8FF]">
        <div className="flex flex-wrap ml-6 mt-2">
          <div className="w-1/2 pr-4 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              Tender ID:
            </label>
            <input
              type="text"
              value={TenId}
              onChange={(e) => setTenId(e.target.value)}
              placeholder="xxxxx"
              className="border border-gray-300 rounded-lg px-2 w-2/6 text-[23px] font-bold"
            />
          </div>
          <div className="w-1/2 pr-4 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              Tender Name:
            </label>
            <input
              type="text"
              value={TenId}
              onChange={(e) => setTenId(e.target.value)}
              placeholder="xxxxx"
              className="border border-gray-300 rounded-lg px-2 w-2/6 text-[23px] font-bold"
            />
          </div>
        </div>
      </div>
      <div className=" absolute right-6">
        <button
          onClick={handleSubmit}
          className=" bg-[#00adef] hover:bg-[#495e33] text-white font-bold text-2xl mt-6 py-2 px-4 rounded-[22px]"
        >
          Add
        </button>
      </div>

      {/* Upload Document part */}
      <p className="text-[40px] font-sans ml-6 mt-[8%] font-bold text-[#656565] ">
        Upload Acceptance Document
      </p>

      <div className="bg-white justify-center items-center rounded-[26px] min-h-[400px] w-[40%] h-[80%] flex-shrink-0 mt-5 ml-6">
        <main className="container mx-auto max-w-screen-lg h-full">
          {/* File upload modal */}
          <article className="relative h-full flex flex-col rounded-md">
            {/* Scroll area */}
            <section className="h-full overflow-auto p-8 w-full flex flex-col">
              <div className="flex items-center">
                <img
                  src={cloudiccon}
                  alt="Cloud Icon"
                  className="h-10 w-10 rounded-full border border-gray-600"
                />

                <div>
                  <p className="text-gray-800 font-inter font-medium text-lg ml-2">
                    Upload files
                  </p>
                  <p className="text-dialogSubmenu-500 text-gray-400 font-inter font-medium text-sm ml-2">
                    Select and upload the files of your choice
                  </p>
                </div>
              </div>
              <hr className="  w-[100%] border-solid border-b-2 border-grayColor mt-2"></hr>

              <header
                className="border-dashed border-2 bg-dialogboxFileup border-gray-400 py-12 flex flex-col justify-center items-center mt-2 rounded-[26px]"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center items-center flex-col">
                  <div>
                    <img
                      src={cloudiccon}
                      alt="Cloud Icon"
                      className="h-10 w-10 rounded-full"
                    />
                  </div>
                  <span>Choose a PDF file or drag & drop it here</span>
                  &nbsp;
                  <span className="text-gray-400">files anywhere or</span>
                </p>
                <input
                  id="hidden-input"
                  type="file"
                  multiple
                  className="hidden"
                  onChange={handleFileInputChange}
                />
                <button
                  id="button"
                  className="mt-2 rounded-[16px] px-3 py-1 bg-white hover:bg-gray-300 focus:shadow-outline focus:outline-none"
                  onClick={() =>
                    document.getElementById("hidden-input").click()
                  }
                >
                  Browse File
                </button>
              </header>

              {/* Uploaded files list */}
              <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                Uploaded Files
              </h1>

              <ul className="flex flex-1 flex-wrap -m-1">
                {Object.values(files).map((file, index) => (
                  <li
                    key={index}
                    className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24"
                  >
                    <article
                      tabIndex="0"
                      className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm"
                    >
                      <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                        <h1 className="flex-1 group-hover:text-blue-800">
                          {file.name}
                        </h1>
                        <div className="flex">
                          <span className="p-1 text-blue-800">
                            <i>
                              <svg
                                className="fill-current w-4 h-4 ml-auto pt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                              </svg>
                            </i>
                          </span>
                          <p className="p-1 size text-xs text-gray-700">
                            {file.size > 1024
                              ? file.size > 1048576
                                ? Math.round(file.size / 1048576) + "mb"
                                : Math.round(file.size / 1024) + "kb"
                              : file.size + "b"}
                          </p>
                        </div>
                      </section>
                    </article>
                  </li>
                ))}
              </ul>
            </section>
          </article>
        </main>
      </div>
      <div className="absolute ml-6 space-x-4 mt-6">
        <button
          onClick={handleSubmit}
          className=" bg-[#00adef] hover:bg-[#495e33] text-white font-bold text-2xl py-2 px-4 rounded-lg mb-4"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Acceptance_Letter;
