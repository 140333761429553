/** @format */

import React, { useEffect, useState, useRef } from "react";
import AsyncSelect from "react-select/async";
import { useReactToPrint } from "react-to-print";
import Arrow from "../../Assets/icons/drop down correct 1.png";

import "./addItemManually.css";

import axios from "axios";
import IdGenerate from "../../utils/id_generate";
import usePermissions from "../../components/permissions/permission";
// import { socket } from '../../socket';

// import TopNaw from '../../components/top nav/topNaw';

export default function AddItemManually() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0]; // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");
  const [userName, setUserName] = useState("");
  const [currentQty, setCurrentQty] = useState("");
  const [shadowQty, setShadowQty] = useState("");
  const [dateFrom, setDateFrom] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);
  const [storeOptions, setStoreOptions] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [Po_id, setPoId] = useState(IdGenerate("INVENTORY"));

  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });

  const GetItem = async (id) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/inventoryRequest/ItemForBranchRequestByID`;
    console.log("Request URL:", url);
    try {
      const res = await fetch(`${url}?itemID=${id}`);
      const data = await res.json();

      console.log("Response data:", data);

      // Ensure that res.data is not empty and has the expected structure
      if (data && Array.isArray(data) && data.length > 0) {
        const itemData = data[0];
        console.log("Item data:", itemData);

        const Data = [...tableData]; // Create a copy of tableData
        Data.push({
          item_name: itemData.item_name,
          inventory_id: itemData.item_id,
          item_available_qty: itemData.total_shadow_qty,
          item_qty: 0,
          item_measure_unit: itemData.item_measure_unit,
        });

        console.log("Updated Data:", Data);

        setTableData(Data); // Update the state with the new data
      } else {
        console.error("Unexpected response data format or empty data array.");
      }
    } catch (error) {
      console.error("Error fetching item data:", error);
    }
  };

  const [itemArrow, setItemArrow] = useState(false);

  const EnterHandler = async () => {
    const data = {
      po_id: "PO1235",
      inventory_relese_user_id: userId,
      inventory_purchase_user_id: "USER456",
      inventory_release_date: formattedDate, // Assuming you want to use the current date
      items: tableData.map((item) => ({
        added_qty: item.item_qty,
        current_qty: currentQty,
        shadow_qty: shadowQty,
        store_id: item.store_id, // Replace with actual store ID if available
        location: item.location,
        inventory_item_description: "Item Description 1", // Replace with actual description if available
        inventory_item_status: "ACTIVE", // Replace with actual status if available
        item_id: item.inventory_id, // Assuming `inventory_id` is the item ID
        selling_item: item.isActive,
        batch_price: parseFloat(item.item_price),
        batch_cost_price: parseFloat(item.item_cost_price),
      })),
    };
    console.log("hello", data);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/item/FillStock`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const contentType = response.headers.get("content-type");

      if (response.ok) {
        if (contentType && contentType.includes("application/json")) {
          const resData = await response.json();
          console.log(resData);
          window.alert("Items Added Successfully");
        } else {
          const textData = await response.text();
          console.log(textData);
          window.alert(textData); // It will alert the response text like "Inventory Items Added successfully"
        }
        ResetHandler();
        setPoId(IdGenerate("ADDITEM"));
      } else if (response.status === 409) {
        window.alert("Already Exist");
      } else if (response.status === 400) {
        window.alert("Items Not Added");
      } else if (response.status === 500) {
        window.alert("Internal Server Error");
      } else if (response.status === 404) {
        window.alert("Items Not Found");
      } else if (response.status === 403) {
        window.alert("Forbidden");
      } else if (response.status === 401) {
        window.alert("Unauthorized");
      }
    } catch (error) {
      console.error("Error:", error);
      window.alert("An unexpected error occurred");
    }
  };
  const ResetHandler = () => {
    setPoId(""); // Reset the request ID with a new generated ID
    setUserId("USER-000000");
    setDateFrom("");
    setSelectedItem(null);
    setStoreOptions(null);
    setTableData([]);
  };

  // Search Item
  const handleItemSearch = async (inputValue) => {
    console.log("Searching for:", inputValue); // Debugging log
    if (inputValue !== "") {
      try {
        const url = new URL(
          `${process.env.REACT_APP_API_ENDPOINT}/v1/inventory/inventoryRequest/ItemForBranchRequest`
        );
        url.searchParams.append("search", inputValue); // Append the query parameter

        const response = await fetch(url.toString(), {
          method: "GET", // Optional, as GET is the default method
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        console.log("Response Data:", data);

        // Extract relevant data from response to populate options
        const options = data.map((item) => ({
          value: item.item_id,
          label: item.item_name, // Customize label as needed
        }));
        // setItemOptions(options);
        return options;
      } catch (error) {
        console.error(error);
      }
    } else {
      // setItemOptions([]);
      return [];
    }
  };
  const handleItemSelect = (selectedOption) => {
    // console.log(selectedOption);
    setSelectedItem(selectedOption);
    console.log("options :", selectedOption.value);
    // Perform any other actions you need with the selected item
    GetItem(selectedOption.value);
  };

  //Search Stores
  const handleStoreSelect = (index, event) => {
    const selectedOption = storeOptions.find(
      (option) => option.value === event.target.value
    );
    let temp = [...tableData];
    temp[index].store_id = selectedOption ? selectedOption.value : null;
    setTableData(temp);
  };

  const handleToggle = (index) => {
    let temp = [...tableData];
    temp[index].isActive = !temp[index].isActive;
    setTableData(temp);
  };

  const handleLocationChange = (index, event) => {
    let temp = [...tableData];
    temp[index].location = event.target.value;
    setTableData(temp);
  };

  const customStylesItems = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "transparent",
      borderRadius: "100px",
      width: "900px",
      borderColor: state.isFocused ? "red" : "blacks",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "item name",
      color: "white",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
      display: "none",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
      width: "122%",
      position: "absolute",
      zIndex: "100",
      top: "30px",
      left: "-160px",
      borderRadius: "20px",
      overflow: "hidden",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  const GetUser = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/${id}`);
    const userData = await res.json();
    return userData;
  };

  const loadAllStores = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/store`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched Data:", data); // For debugging

        // Assuming 'data' contains a 'store' array
        const stores = data.map((storeOptions) => ({
          value: storeOptions.store_id,
          label: storeOptions.store_name,
        }));

        console.log("Store Options:", stores); // Verify the store options
        setStoreOptions(stores);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch stores:", errorData);
      }
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  };
  useEffect(() => {
    console.log("Store Options State:", storeOptions); // Verify state after loading stores
  }, [storeOptions]);

  useEffect(() => {
    // Assume userId is available as userId
    GetUser(userId)
      .then((user) => {
        setUserName(user.name);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });

    loadAllStores();
  }, []);

  return (
    <div className="p-10 bg-background h-[100%]">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">Add Item Manually</p>

      <div className="w-full flex items-center justify-center px-12">
        <div className="bg-PrimaryColor1 w-full h-[82px] rounded-[100px] mx-20 flex items-center justify-between">
          <p className="label-1 ml-10 text-white">Item name :</p>
          <AsyncSelect
            styles={customStylesItems}
            cacheOptions
            loadOptions={handleItemSearch}
            onMenuOpen={() => setItemArrow(true)}
            onMenuClose={() => setItemArrow(false)}
            onChange={handleItemSelect}
            value={selectedItem}
          />
          <img
            src={Arrow}
            alt="arrow"
            className={
              itemArrow
                ? "mr-7 size-6 transition duration-500 fill-white"
                : "mr-7 size-6 transition rotate-180 duration-500"
            }
          />
        </div>
      </div>

      {/* table */}
      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-30 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[320px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Store</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Location</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Available Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Selling Item
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[110px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Selling Price (LKR)
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[110px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Cost Price (LKR)
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Quantity</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Action</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="">
            {tableData.length > 0
              ? tableData.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex items-start justify-around my-3 "
                  >
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] ">
                      {item.item_name}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      <select
                        onChange={(e) => handleStoreSelect(index, e)}
                        className={
                          "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        }
                        value={item.store_id || ""} // Ensure value defaults to an empty string if undefined
                      >
                        {storeOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </p>
                    <p className="w-[170px] p1 text-[#656565] text-center">
                      <input
                        className={
                          "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        }
                        type="text"
                        value={item.location}
                        onChange={(e) => handleLocationChange(index, e)}
                      />
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.item_available_qty}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      <div className="flex items-center justify-center">
                        <button
                          onClick={() => handleToggle(index)}
                          className={`w-10 h-5 rounded-full mt-3 ${
                            item.isActive ? "bg-green-500" : "bg-gray-300"
                          } flex items-center px-1 transition-colors duration-300`}
                        >
                          <div
                            className={`w-4 h-4 bg-white rounded-full shadow-md transform ${
                              item.isActive ? "translate-x-5" : "translate-x-0"
                            } transition-transform duration-300`}
                          />
                        </button>
                      </div>
                    </p>
                    <p className="w-[150px] p1 text-[#656565] text-center">
                      <input
                        type="number"
                        value={item.item_price}
                        className={
                          "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        }
                        onChange={(e) => {
                          let temp = [...tableData];
                          temp[index].item_price = e.target.value; // Convert to float
                          temp[index].item_total =
                            temp[index].item_qty * temp[index].item_price;
                          setTableData(temp);
                        }}
                      />
                    </p>
                    <p className="w-[150px] p1 text-[#656565] text-center">
                      <input
                        type="number"
                        value={item.item_cost_price}
                        className={
                          "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        }
                        onChange={(e) => {
                          let temp = [...tableData];
                          temp[index].item_cost_price = e.target.value; // Convert to float
                          setTableData(temp);
                        }}
                      />
                    </p>
                    <p className="w-[120px] p1 text-[#656565] text-center">
                      <input
                        type="number"
                        value={item.item_qty}
                        className={
                          item.item_available_qty < item.item_qty
                            ? "border-[#ef0e0ef7] rounded-2xl w-full border-4 px-1 focus:outline-none"
                            : "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        }
                        onChange={(e) => {
                          let temp = [...tableData];
                          console.log(tableData);
                          temp[index].item_qty = e.target.value; // Convert to float
                          temp[index].item_total =
                            temp[index].item_qty * temp[index].item_price;
                          setTableData(temp);
                          setCurrentQty(temp[index].item_qty);
                          setShadowQty(temp[index].item_qty);
                        }}
                      />
                    </p>
                    <p className="w-[120px] p1 text-[#656565] text-center">
                      <button
                        className="w-[120px] h-[46px] rounded-[100px] bg-[#EDC2C2]"
                        onClick={() => {
                          let temp = [...tableData];
                          temp.splice(index, 1);
                          setTableData(temp);
                        }}
                      >
                        Delete
                      </button>
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        {hasPermission(1290) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4 text-white font-bold text-xl"
            onClick={(e) => EnterHandler()}
          >
            Add
          </button>
        )}
      </div>
    </div>
  );
}
