/** @format */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import CSV from "../../../../Assets/icons/csv.png";
import axios from "axios";
import { FiFile } from "react-icons/fi";
import Cookies from "js-cookie";
import usePermissions from "../../../../components/permissions/permission";
import Papa from "papaparse";

const Pending_Tender_Approval_List = ({ itemReq }) => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedItemReq, setselectedItemReq] = useState([]);
  const [filterOption, setFilterOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const userId = Cookies.get("employee_no");

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async () => {
    console.log("user iD", userId);
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/get-for-committee-autority-approve`,
        {
          params: {
            Type: "TENDER",
            member: userId,
          },
        }
      );
      setdata(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setdata([]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const HandleOnClick = (itemReq) => {
    // Navigate to the new page and pass the object
    navigate("", {
      state: { selectedRecord: itemReq },
    });
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredEmployees = data.filter((item) => {
    const searchMatch =
      !searchQuery ||
      item.inventory_request_by
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      item.inventory_request_id
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

    return searchMatch;
  });

  const currentItems = filteredEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const handleExportCSV = () => {
  //   const csvContent =
  //     "data:text/csv;charset=utf-8," +
  //     items.map((row) => Object.values(row).join(",")).join("\n");

  //   const encodedUri = encodeURI(csvContent);
  //   const link = document.createElement("a");
  //   link.setAttribute("href", encodedUri);
  //   link.setAttribute("download", "employee_data.csv");
  //   document.body.appendChild(link);
  //   link.click();

  //   setTimeout(() => {
  //     window.alert("Successfully Downloaded");
  //   }, 100);
  // };

  const exportToCSV = (data) => {
    const csvData = data.map((item) => ({
      "Purchase Request No": item.pr_id,
      "Quotation No": item.qoutation_id,
      "Supplier No": item.supplier_id,
      "Supplier Name": item.supplier_name,
      "Net Total": item.net_total,
      "Decision Doc": item.decision_file_path
        ? item.decision_file_path.split("/").pop()
        : "No Decision Document",
      "Quotation Doc": item.qoutation_document_path
        ? item.qoutation_document_path.split("/").pop()
        : "No Quotation Document",
    }));

    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Pending_Tender_Approval_List.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const openDialog = async (item) => {
    // Navigate to the new page and pass the object
    navigate("/supply/approve_tender", {
      state: { selectedRecord: item },
    });
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        View Pending Tender Approval Quotation Lists
      </p>
      <div className="flex justify-between overflow-hidden pb-6">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <input
                type="text"
                placeholder="Search by Purchase Request No"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px] ">
        <table className="table-auto border-none overflow-x-auto w-full">
          <thead>
            <tr className="bg-white font-sans text-[16px]">
              <th className="text-primary px-6 py-4">Purchase Request No</th>
              <th className="text-primary px-6 py-4">Quotation No</th>
              <th className="text-primary px-6 py-4">Supplier No</th>
              <th className="text-primary px-6 py-4">Supplier Name</th>
              <th className="text-primary px-6 py-4">Net Total</th>
              <th className="text-primary px-6 py-4">Decision Doc</th>
              <th className="text-primary px-6 py-4">Quotation Doc</th>
              <th className="text-primary px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody className="font-bold">
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.pr_id}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.qoutation_id}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.supplier_id}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.supplier_name}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.net_total}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.decision_file_path ? (
                      <div className="flex items-center justify-center bg-[#E7F1FF] rounded-lg p-2">
                        <a
                          href={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/get-desition-doc-by-name/${item.decision_file_path}`}
                          download={item.decision_file_path}
                          className="flex items-center cursor-pointer"
                        >
                          <FiFile className="text-gray-600 mr-2" />
                          <span>
                            {item.decision_file_path.split("/").pop()}
                          </span>
                        </a>
                      </div>
                    ) : (
                      <span>No decision document</span>
                    )}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {item.qoutation_document_path ? (
                      <div className="flex items-center justify-center bg-[#E7F1FF] rounded-lg p-2">
                        <a
                          href={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/openingcommittee/files/${item.qoutation_document_path}`}
                          download={item.qoutation_document_path}
                          className="flex items-center cursor-pointer"
                        >
                          <FiFile className="text-gray-600 mr-2" />
                          <span>
                            {item.qoutation_document_path.split("/").pop()}
                          </span>
                        </a>
                      </div>
                    ) : (
                      <span>No Quotation document</span>
                    )}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {hasPermission(2960) && (
                      <button
                        className=" bg-[#00adef] hover:bg-[#495e33] p-2 text-white font-sans items-center rounded-md w-[150px]"
                        onClick={() => openDialog(item)}
                      >
                        View
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="bg-white px-6 py-4 text-center">
                  No pending tender approvals
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredEmployees.length / itemsPerPage))].map(
          (page, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredEmployees.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
      <div className="flex justify-end mb-4 mr-4">
        <button className="bg-primary text-white font-sans p-3 rounded-[20px]">
          <div className="flex items-center" onClick={exportToCSV}>
            <div onClick={exportToCSV} className="font-semibold">
              Export CSV
            </div>
            <div>
              <img className="h-8 w-8" src={CSV} alt="logo-csv" />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Pending_Tender_Approval_List;
