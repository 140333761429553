/** @format */

import React, { useState } from "react";
import axios from "axios";
import "./adddesignation.css";

export default function AddDesignation() {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [formData, setFormData] = useState({
    department: "",
    designation: "",
    shortLeaveCount: "",
    halfDayCount: 0,
    annualLeaveCount: 0,
    casualLeaveCount: 0,
    medicalLeaveCount: 0,
    workingDaysType: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddDesignation = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/hris/designations/adddesignation`,
        formData
      );
      console.log("Designation added successfully:", response.data);
      alert("Designation added successfully!");
      // Clear input fields
      setFormData({
        department: "",
        designation: "",
        shortLeaveCount: "",
        halfDayCount: 0,
        annualLeaveCount: 0,
        casualLeaveCount: 0,
        medicalLeaveCount: 0,
        workingDaysType: "",
      });
    } catch (error) {
      console.error("Error adding designation:", error);
      alert("Error adding designation. Please try again.");
    }
  };

  return (
    <div className="p-10 bg-background">
      <p className="header-1">Add Designation</p>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Enter Designation Details</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <p className="label-1">Department</p>
            <p className="label-1">:</p>
            <input
              className="input-1 border border-[#00000036]"
              name="department"
              value={formData.department}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <p className="label-1">Designation Name</p>
            <p className="label-1">:</p>
            <input
              className="input-1 border border-[#00000036]"
              name="designation"
              value={formData.designation}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <p className="label-1">Working Days Type</p>
            <p className="label-1">:</p>
            <select
              id="workingDaysType"
              name="workingDaysType"
              autoComplete="off"
              className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
              value={formData.workingDaysType}
              onChange={handleInputChange}
            >
              <option className="font-sans-bold" value="">
                Select Working Days Type
              </option>
              <option value="5 Days">5 Days</option>
              <option value="5 1/2 Days">5 1/2 Days</option>
            </select>
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <p className="label-1">Short Leave Count</p>
            <p className="label-1">:</p>
            <input
              className="input-1 border border-[#00000036]"
              name="shortLeaveCount"
              value={formData.shortLeaveCount}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <p className="label-1">Half Day Count</p>
            <p className="label-1">:</p>
            <input
              className="input-1 border border-[#00000036]"
              type="number"
              name="halfDayCount"
              value={formData.halfDayCount}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <p className="label-1">Annual Leave Count</p>
            <p className="label-1">:</p>
            <input
              className="input-1 border border-[#00000036]"
              type="number"
              name="annualLeaveCount"
              value={formData.annualLeaveCount}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <p className="label-1">Casual Leave Count</p>
            <p className="label-1">:</p>
            <input
              className="input-1 border border-[#00000036]"
              type="number"
              name="casualLeaveCount"
              value={formData.casualLeaveCount}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <p className="label-1">Medical Leave Count</p>
            <p className="label-1">:</p>
            <input
              className="input-1 border border-[#00000036]"
              type="number"
              name="medicalLeaveCount"
              value={formData.medicalLeaveCount}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 mb-10 px-[280px]">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#AB2626] border rounded-[23px] px-4"
          onClick={() => {
            // Optionally handle cancel action here
          }}
        >
          <p className="p2 text-white">Cancel</p>
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4"
          onClick={handleAddDesignation}
        >
          <p className="p2 text-white">Add</p>
        </button>
      </div>
    </div>
  );
}
