/** @format */

import React, { useState, useEffect } from "react";
import AddImage from "./add_image";
import { IoCloseSharp } from "react-icons/io5";

const handleScrollUp = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function Emp_onBoard() {
  const [profileIsOpen, sethandleToggleprofile] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [disciplinaryUploadedFiles, setDisciplinaryUploadedFiles] = useState(
    []
  );
  const [personalUploadedFiles, setPersonalUploadedFiles] = useState([]);
  const [trainingtype, settrainingtype] = useState([]);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [TraningSections, setTraningSections] = useState(1);
  const [traningSectionsCounts, settraningSectionsCounts] = useState([1]);
  const [itemSections, setItemSections] = useState(1);
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [filteredDesignations, setFilteredDesignations] = useState([]);
  const [totalDependents, setTotalDependents] = useState(0);
  const [isOpenSections, setIsOpenSections] = useState([]);
  const [isOpenSectionsEmpQly, setIsOpenSectionsEmpQly] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [timetables, setTimetables] = useState([]);
  const [timetableId, setTimetableId] = useState(null); // Default state to null or a valid integer

  useEffect(() => {
    // Fetch timetables
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/timetable/gettimetable`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched timetables:", data); // Check the fetched data
        setTimetables(data);
      })
      .catch((error) => console.error("Error fetching timetables:", error));
  }, []);

  useEffect(() => {
    // Fetch supervisors
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/supervisors/getSupervisors`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched supervisors:", data); // Log to check the fetched data
        setSupervisors(data); // Store the fetched data in state
      })
      .catch((error) => console.error("Error fetching supervisors:", error));
  }, []);

  const [
    isOpenSectionsRenderTraningSections,
    setIsOpenSectionsRenderTraningSections,
  ] = useState([]);
  const [
    isOpenDisciplinaryActionsSections,
    setIsOpenDisciplinaryActionsSections,
  ] = useState([]); // State to manage the popup visibility
  const [, setSelectedPrimaryRawItems] = useState(Array(itemSections).fill(""));
  const [, setSelectedAlternativeRawItems] = useState(
    Array(itemSections).fill("")
  );
  const [, setDependentCounts] = useState([1]);
  const [employmentQualificationSections, setEmploymentQualificationSections] =
    useState(1);
  const [
    employmentQualificationSectionsCounts,
    setEmploymentQualificationSectionsCounts,
  ] = useState([1]);
  const [
    disciplinaryActionsSectionsCounts,
    setDisciplinaryActionsSectionsCounts,
  ] = useState([1]);
  const [DisciplinaryActionsSections, setDisciplinaryActionsSections] =
    useState(1);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddItemSection = () => {
    setItemSections((prev) => prev + 1);
    setSelectedPrimaryRawItems((prev) => [...prev, ""]);
    setSelectedAlternativeRawItems((prev) => [...prev, ""]);
    setDependentCounts((prev) => [...prev, prev.length + 1]);
    setTotalDependents((prev) => prev + 1);
    setIsOpenSections((prev) => [...prev, true]); // Add new section as open
  };

  const handleCloseSection = (index) => {
    setIsOpenSections((prev) => {
      const newIsOpenSections = [...prev];
      newIsOpenSections[index] = false;
      return newIsOpenSections;
    });
  };
  const handleCloseSectionEmpQualification = (index) => {
    setIsOpenSectionsEmpQly((prev) => {
      const newIsOpenSections = [...prev];
      newIsOpenSections[index] = false;
      return newIsOpenSections;
    });
  };
  const handleCloseSectionRenderTraningSections = (index) => {
    setIsOpenSectionsRenderTraningSections((prev) => {
      const newIsOpenSections = [...prev];
      newIsOpenSections[index] = false;
      return newIsOpenSections;
    });
  };
  const handleCloseDisciplinaryActionsSections = (index) => {
    setIsOpenDisciplinaryActionsSections((prev) => {
      const newIsOpenSections = [...prev];
      newIsOpenSections[index] = false;
      return newIsOpenSections;
    });
  };

  const handleAddEmploymentQualificationSection = () => {
    setEmploymentQualificationSections((prev) => prev + 1);
    setEmploymentQualificationSectionsCounts((prev) => [
      ...prev,
      prev.length + 1,
    ]);
    setIsOpenSectionsEmpQly((prev) => [...prev, true]);
  };
  const handleAddDisciplinaryActionsSections = () => {
    setDisciplinaryActionsSections((prev) => prev + 1);
    setDisciplinaryActionsSectionsCounts((prev) => [...prev, prev.length + 1]);
    setIsOpenDisciplinaryActionsSections((prev) => [...prev, true]);
  };

  const handleTraningSection = () => {
    setTraningSections((prev) => prev + 1);
    settraningSectionsCounts((prev) => [...prev, prev.length + 1]);
    setIsOpenSectionsRenderTraningSections((prev) => [...prev, true]);
  };

  const renderTraningSections = () => {
    const sections = [];
    const maxSections = Math.min(TraningSections, 10);
    let visibleCount = 0;

    for (let i = 0; i < maxSections; i++) {
      if (!isOpenSectionsRenderTraningSections[i]) continue;
      visibleCount++;
      sections.push(
        <div key={i} className="mt-5">
          <div className="flex items-center justify-center">
            <div className="w-[50%] bg-[#F3F8FF] rounded-xl p-8 ">
              <div className="flex justify-end">
                <button
                  onClick={() => handleCloseSectionRenderTraningSections(i)}
                >
                  <div className="bg-[#EFA5A5] rounded-full p-2">
                    <IoCloseSharp />
                  </div>
                </button>
              </div>
              <p className="text-4xl mb-4 font-bold text-[#797C80]">
                Training Development {traningSectionsCounts[i]}
              </p>
              <hr className="line border-t border-gray-300 w-[25%]"></hr>
              <div className="mt-10">
                <form>
                  <div className="flex mt-5 flex-col md:flex-row font-bold">
                    <div className="flex flex-col mr-10">
                      <label
                        htmlFor="type_of_training"
                        className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                      >
                        Type of Training
                      </label>
                      <select
                        id={`type_of_training_${i}`}
                        name={`type_of_training_${i}`}
                        className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                      >
                        <option value="">Select</option>
                        {trainingtype.map((trainingtype) => (
                          <option
                            key={trainingtype.training_name}
                            value={trainingtype.training_name}
                          >
                            {trainingtype.training_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return sections;
  };

  const renderDisciplinaryActionsSections = () => {
    const sections = [];
    const maxSections = Math.min(DisciplinaryActionsSections, 10);
    let visibleCount = 0;

    for (let i = 0; i < maxSections; i++) {
      if (!isOpenDisciplinaryActionsSections[i]) continue;
      visibleCount++;
      sections.push(
        <div key={i} className="mt-5">
          <div className="flex items-center justify-center">
            <div className="w-[50%] bg-[#F3F8FF] rounded-xl p-8 mt-5">
              <div className="flex">
                <p className="text-4xl mb-4 font-bold text-[#797C80]">
                  Disciplinary Actions
                </p>
              </div>
              <div className="flex justify-end -mt-10">
                <button
                  onClick={() => handleCloseDisciplinaryActionsSections(i)}
                >
                  <div className="bg-[#EFA5A5] rounded-full p-2">
                    <IoCloseSharp />
                  </div>
                </button>
              </div>
              <hr className="line border-t border-gray-300 w-[25%]"></hr>
              <div className="mt-10">
                <form>
                  <div className="flex mt-5 flex-col md:flex-row font-bold">
                    <div className="flex flex-col w-[50%] mr-10">
                      <label
                        htmlFor="name"
                        className="text-xl mb-2 text-[#797C80] font-sans"
                      >
                        Date of Explanation Call{" "}
                      </label>
                      <input
                        type="date"
                        name={`employee_disciplinary_call_date_${i}`}
                        id={`employee_disciplinary_call_date_${i}`}
                        autoComplete={`employee_disciplinary_call_date_${i}`}
                        placeholder="Enter Type"
                        className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="nic_number"
                        className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                      >
                        Upload Letter{" "}
                      </label>
                      <div className="grid w-full max-w-xs items-center gap-1.5">
                        <input
                          className="flex w-full h-10 rounded-md border border-[#9CAC8B]-300 border-input bg-white text-sm text-gray-400 file:border-0 file:bg-[#74d4f4] file:text-gray-50 file:text-sm file:font-medium file:h-10"
                          type="file"
                          id={`disfile_${i}`}
                          onChange={handleDisciplinaryFileUpload}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return sections;
  };

  const renderEmploymentQualificationSections = () => {
    const sections = [];
    const maxSections = Math.min(employmentQualificationSections, 4);
    let visibleCount = 0;

    for (let i = 0; i < maxSections; i++) {
      if (!isOpenSectionsEmpQly[i]) continue;
      visibleCount++;
      sections.push(
        <div key={i} className="mt-5">
          <div className="flex justify-end -mt-10">
            <button onClick={() => handleCloseSectionEmpQualification(i)}>
              <div className="bg-[#EFA5A5] rounded-full p-2">
                <IoCloseSharp />
              </div>
            </button>
          </div>
          <div className="flex">
            <form>
              <div className="flex mt-5 flex-col md:flex-row">
                <div className="flex flex-col mr-10">
                  <label
                    htmlFor={`qualification_${i}`}
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Qualification {employmentQualificationSectionsCounts[i]}
                  </label>
                  <textarea
                    name={`qualification_${i}`}
                    id={`qualification_${i}`}
                    autoComplete={`qualification_${i}`}
                    placeholder="Enter Qualification"
                    className="w-[100%] h-[100px] rounded-[10px] py-2 px-3 bg-[#74d4f4] text-gray-50 placeholder-white text-[20px] font-sans"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
    return sections;
  };

  const renderDependentSections = () => {
    const sections = [];
    const maxSections = Math.min(itemSections, 6);
    let visibleCount = 0;

    for (let i = 0; i < maxSections; i++) {
      if (!isOpenSections[i]) continue;
      visibleCount++;
      sections.push(
        <div key={i} className="mt-5">
          <div className="flex items-center justify-center">
            <div className="w-[50%] bg-[#F3F8FF] rounded-xl p-8 mt-5">
              <div className="flex justify-end">
                <button onClick={() => handleCloseSection(i)}>
                  <div className="bg-[#EFA5A5] rounded-full p-2">
                    <IoCloseSharp />
                  </div>
                </button>
              </div>
              <p className="text-4xl mb-4 font-bold text-[#797C80]">
                Dependent {i + 1}
              </p>

              <hr className="line border-t border-gray-300 w-[25%]" />
              <div className="mt-10">
                <form>
                  <div className="flex flex-col w-[50%] font-bold">
                    <label
                      htmlFor={`employee_dependent_relationship_${i}`}
                      className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                    >
                      Relationship
                    </label>
                    <input
                      type="text"
                      name={`employee_dependent_relationship_${i}`}
                      id={`employee_dependent_relationship_${i}`}
                      autoComplete={`employee_dependent_relationship_${i}`}
                      placeholder="Spouse / Child"
                      className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                    />
                  </div>
                  <div className="flex mt-5 gap-5 flex-col md:flex-row font-bold">
                    <div className="flex flex-col w-[50%]">
                      <label
                        htmlFor={`employee_dependent_name_${i}`}
                        className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name={`employee_dependent_name_${i}`}
                        id={`employee_dependent_name_${i}`}
                        autoComplete={`employee_dependent_name_${i}`}
                        placeholder="Enter name"
                        className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                      />
                    </div>
                    <div className="flex w-[50%] flex-col">
                      <label
                        htmlFor={`employee_dependent_nic_${i}`}
                        className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                      >
                        NIC
                      </label>
                      <input
                        type="text"
                        name={`employee_dependent_nic_${i}`}
                        id={`employee_dependent_nic_${i}`}
                        autoComplete={`employee_dependent_nic_${i}`}
                        placeholder="Enter NIC number"
                        className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-[50%] font-bold mt-5">
                    <label
                      htmlFor={`employee_dependent_dob_${i}`}
                      className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      name={`employee_dependent_dob_${i}`}
                      id={`employee_dependent_dob_${i}`}
                      autoComplete={`employee_dependent_dob_${i}`}
                      placeholder="Enter Date of Birth"
                      className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return sections;
  };

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/designations/getdesignation`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setDesignations(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/performance/training/gettraining`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        settrainingtype(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/designations/getdesignation`
        );
        const data = await response.json();
        setDesignations(data);

        const uniqueDepartments = [
          ...new Set(data.map((item) => item.department)),
        ];
        setDepartments(uniqueDepartments);
      } catch (error) {
        console.error("Error fetching designations:", error);
      }
    };

    fetchDesignations();
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      const filtered = designations.filter(
        (item) => item.department === selectedDepartment
      );
      setFilteredDesignations(filtered);
    } else {
      setFilteredDesignations([]);
    }
  }, [selectedDepartment, designations]);

  const handleSubmit = () => {
    const employeeNo = document.getElementById("employee_no").value;
    const fullName = document.getElementById("employee_fullname").value;
    const nameInitial = document.getElementById("employee_name_initial").value;
    const nic = document.getElementById("employee_nic").value;
    const dob = document.getElementById("employee_dob").value;
    const gender = document.getElementById("employee_gender").value;
    const maritalStatus = document.getElementById(
      "employee_marital_status"
    ).value;
    const contactNo = document.getElementById("employee_contact_no").value;
    const permanentAddress = document.getElementById(
      "employee_permanent_address"
    ).value;
    const temporaryAddress = document.getElementById(
      "employee_temporary_address"
    ).value;
    const policeDivision = document.getElementById(
      "employee_police_division"
    ).value;
    const gramaSewaDivision = document.getElementById(
      "employee_gramasewa_division"
    ).value;
    const email = document.getElementById("employee_email").value;

    const dateOfAppointment =
      document.getElementById("date_of_appoinment").value;
    const probationComplete =
      document.getElementById("probation_complete").value;
    const permanentAppointmentDate = document.getElementById(
      "permanent_appointment_date"
    ).value;
    const firstEBCompletionDate = document.getElementById(
      "first_eb_completion_date"
    ).value;
    const secondEBCompletionDate = document.getElementById(
      "second_eb_completion_date"
    ).value;
    const thirdEBCompletionDate = document.getElementById(
      "third_eb_completion_date"
    ).value;
    const epfNo = document.getElementById("employee_epf_no").value;
    const basicsalary = document.getElementById("employee_basic_salary").value;
    const accountNo = document.getElementById("employee_account_no").value;
    const bankName = document.getElementById("employee_bank_name").value;
    const branchName = document.getElementById("employee_branch_name").value;
    const bankCode = document.getElementById("employee_bank_code").value;
    const branchCode = document.getElementById("employee_branch_code").value;
    const designationgrade = document.getElementById("designation_grade").value;
    const salarycode = document.getElementById("salary_code").value;
    const sladcallowance = document.getElementById("sladc_allowance").value;
    const ajtallowance = document.getElementById("ajt_allowance").value;
    const costofallowance = document.getElementById("cost_of_allowance").value;
    const departmentDesignationId = document.getElementById(
      "employee_designation"
    ).value;

    // Fetch timetable_id and supervisor_id as integers
    const timetableSelectValue = document.querySelector(
      "select[name='timetable_id']"
    ).value;
    const timetableId = parseInt(timetableSelectValue, 10);
    const supervisorId = parseInt(
      document.querySelector("select[name='supervisor_id']").value,
      10
    );

    // Ensure that timetableId and supervisorId are valid numbers
    if (isNaN(timetableId)) {
      console.error("Invalid timetable_id");
      alert("Please select a valid timetable.");
      return; // Prevent submission if timetable_id is not valid
    }

    if (isNaN(supervisorId)) {
      console.error("Invalid supervisor_id");
      alert("Please select a valid supervisor.");
      return; // Prevent submission if supervisor_id is not valid
    }

    const createRequestBody = () => {
      const dependents = [];
      const maxSections = Math.min(itemSections, 6);

      for (let i = 0; i < maxSections; i++) {
        const dependentName = document.getElementById(
          `employee_dependent_name_${i}`
        );
        const dependentRelationship = document.getElementById(
          `employee_dependent_relationship_${i}`
        );
        const dependentNIC = document.getElementById(
          `employee_dependent_nic_${i}`
        );
        const dependentDOB = document.getElementById(
          `employee_dependent_dob_${i}`
        );

        if (
          dependentName &&
          dependentRelationship &&
          dependentNIC &&
          dependentDOB
        ) {
          const dependentData = {
            employee_dependent_name: dependentName.value,
            employee_dependent_relationship: dependentRelationship.value,
            employee_dependent_nic: dependentNIC.value,
            employee_dependent_dob: dependentDOB.value,
          };
          dependents.push(dependentData);
        }
      }
      return dependents;
    };

    const academicQualifications = () => {
      const qualifications = [];
      const maxSections = Math.min(employmentQualificationSections, 4);

      for (let i = 0; i < maxSections; i++) {
        const employee_acadamic_qualification = document.getElementById(
          `qualification_${i}`
        );
        if (employee_acadamic_qualification) {
          const qualificationData = {
            employee_acadamic_qualification:
              employee_acadamic_qualification.value,
          };
          qualifications.push(qualificationData);
        }
      }
      return qualifications;
    };

    const createTrainingDevelopmentRequestBody = () => {
      const trainingDevelopment = [];
      const maxSections = Math.min(TraningSections, 10);

      for (let i = 0; i < maxSections; i++) {
        const type_of_training = document.getElementById(
          `type_of_training_${i}`
        );
        if (type_of_training) {
          const trainingData = {
            type_of_training: type_of_training.value,
          };
          trainingDevelopment.push(trainingData);
        }
      }
      return trainingDevelopment;
    };

    const data = {
      employee_no: employeeNo,
      employee_fullname: fullName,
      employee_name_initial: nameInitial,
      employee_nic: nic,
      employee_dob: dob,
      employee_gender: gender,
      employee_marital_status: maritalStatus,
      employee_contact_no: contactNo,
      employee_permanent_address: permanentAddress,
      employee_temporary_address: temporaryAddress,
      employee_police_division: policeDivision,
      employee_gramasewa_division: gramaSewaDivision,
      employee_email: email,
      department_designation_id: departmentDesignationId,
      date_of_appointment: dateOfAppointment,
      probation_complete: probationComplete,
      permanant_appoinment_date: permanentAppointmentDate,
      first_eb_completion_date: firstEBCompletionDate,
      second_eb_completion_date: secondEBCompletionDate,
      third_eb_completion_date: thirdEBCompletionDate,
      employee_epf_no: epfNo,
      employee_basic_salary: basicsalary,
      employee_dependent_details: createRequestBody(),
      employee_acadamic_qualification_details: academicQualifications(),
      employee_training_details: createTrainingDevelopmentRequestBody(),
      employee_account_no: accountNo,
      employee_bank_name: bankName,
      employee_branch_name: branchName,
      employee_bank_code: bankCode,
      employee_branch_code: branchCode,
      salary_code: salarycode,
      designation_grade: designationgrade,
      cost_of_allowance: costofallowance,
      sladc_allowance: sladcallowance,
      ajt_allowance: ajtallowance,
      timetable_id: timetableId, // Pass timetable_id as integer
      supervisor_id: supervisorId, // Pass supervisor_id as integer
    };

    console.log("Data to be sent:", data);

    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/employees/addemployee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        handleuploadfile();
        handleDisciplinarySubmit();
        alert("Form Submitted successfully!");
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Form Submission Unsuccessful!", error);
      });
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const newUploadedFiles = [...personalUploadedFiles];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type === "application/pdf") {
        newUploadedFiles.push(file);
      } else {
        setShowAlertMessage(true);
      }
    }
    setPersonalUploadedFiles(newUploadedFiles);
  };

  const handleuploadfile = async () => {
    try {
      const formData = new FormData();
      const employeeNo = document.getElementById("employee_no").value;

      formData.append("employee_no", employeeNo);

      personalUploadedFiles.forEach((file) => {
        formData.append("employee_upload_files", file);
      });

      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/uploadfiles`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("Personal Files uploaded successfully:", responseData);
      }
    } catch (error) {
      console.error("Error uploading Personal files", error);
    }
  };

  const handleDisciplinaryFileUpload = (event) => {
    const files = event.target.files;
    const newUploadedFiles = [...disciplinaryUploadedFiles];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type === "application/pdf") {
        newUploadedFiles.push(file);
      }
    }
    setDisciplinaryUploadedFiles(newUploadedFiles);
  };

  const handleDisciplinarySubmit = async () => {
    try {
      const formData = new FormData();
      const employeeNo = document.getElementById("employee_no").value;

      formData.append("employee_no", employeeNo);

      disciplinaryUploadedFiles.forEach((file, index) => {
        formData.append(`employee_disciplinary_files`, file);

        const disciplinaryCallDate = document.getElementById(
          `employee_disciplinary_call_date_${index}`
        ).value;

        formData.append(
          `employee_disciplinary_call_date`,
          disciplinaryCallDate
        );
      });

      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/uploaddisfiles`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("Files uploaded successfully:", responseData);
      } else {
        console.error("Error uploading files:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading files", error);
    }
  };

  const handleToggleprofile = () => {
    sethandleToggleprofile(!profileIsOpen);
  };

  const handlePhoneNumber = (event) => {
    let newNumber = event.target.value;
    // Remove any non-digit characters from the input
    newNumber = newNumber.replace(/\D/g, "");
    setPhoneNumber(newNumber);
    // Check if the cleaned number is exactly 10 digits long
    if (newNumber.length !== 10) {
      setErrorMessage("Your phone number should be exactly 10 digits long!");
    } else {
      setErrorMessage("Phone Number is valid!");
    }
  };

  return (
    <div className="overflow-y-auto bg-[#E5EDF9] font-sans">
      <div>
        <p className="text-[32px] ml-6 mt-5 w-[100%] rounded-xl font-bold text-[#797C80]">
          Onboarding Form
        </p>
      </div>
      <div className="flex items-center justify-center h-screen mb-[170px] mt-[8%]">
        <div className="w-[50%] bg-[#F3F8FF] rounded-xl p-8 mb-[100px]">
          <div className="flex items-start justify-between">
            <div>
              <p className="text-4xl mb-4 font-bold text-[#797C80]">
                Personal Details
              </p>
              <p className="text-sm text-red-800 font-bold lowercase">
                *PLEASE FILL IN BLOCK LETTERS
              </p>
              <hr className="border-t border-gray-300 w-1/4"></hr>
            </div>
            <div className="w-80 h-88 flex"></div>
          </div>
          <div className="mt-10">
            <form>
              <div className="flex justify-between">
                <div className="flex flex-col w-[75%] font-bold">
                  <label
                    htmlFor="employee_no"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Employee Number
                  </label>
                  <input
                    type="text"
                    id="employee_no"
                    autoComplete="employee_no"
                    placeholder="Enter Employee Number"
                    className="w-[50%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
              <div className="flex flex-col font-bold mt-5">
                <label
                  htmlFor="full_name"
                  className="text-xl mb-2 text-[#797C80] font-sans"
                >
                  Full name
                </label>
                <input
                  type="text"
                  name="employee_fullname"
                  id="employee_fullname"
                  placeholder="Enter full name"
                  autoComplete="employee_fullname"
                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                />
              </div>
              <div className="flex mt-5 flex-col md:flex-row font-bold w-[100%]">
                <div className="flex flex-col w-[50%] mr-10">
                  <label
                    htmlFor="name_with_initials"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans placeholder:opacity-30"
                  >
                    Name with Initials{" "}
                  </label>
                  <input
                    type="text"
                    name="employee_name_initial"
                    id="employee_name_initial"
                    autoComplete="employee_name_initial"
                    placeholder="Enter name with initials"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="nic_number"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    NIC Number
                  </label>
                  <input
                    type="text"
                    name="employee_nic"
                    id="employee_nic"
                    autoComplete="employee_nic"
                    placeholder="Enter NIC number"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
              <div className="flex mt-5 flex-col md:flex-row w-[100%] font-bold">
                <div className="flex flex-col w-[50%] mr-10">
                  <label
                    htmlFor="name_with_initials"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    name="employee_dob"
                    id="employee_dob"
                    autoComplete="employee_dob"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder-opacity-50 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="nic_number"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Gender{" "}
                  </label>
                  <select
                    id={`employee_gender`}
                    name={`employee_gender`}
                    autoComplete="employee_gender"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  >
                    <option>Select Gender</option>
                    <option value={"Male"}> Male</option>
                    <option value={"Female"}>Female</option>
                    <option value={"Other"}>Other</option>
                  </select>
                </div>
              </div>
              <div className="flex mt-5 flex-col md:flex-row w-[100%] font-bold">
                <div className="flex flex-col w-[50%] mr-10">
                  <label
                    htmlFor="name_with_initials"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Marital Status{" "}
                  </label>
                  <select
                    id={`employee_marital_status`}
                    name={`employee_marital_status`}
                    autoComplete="employee_marital_status"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  >
                    <option>Select Marital Status</option>
                    <option value={"Married"}>Married</option>
                    <option value={"Unmarried"}>Unmarried</option>
                  </select>
                </div>
                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="contact_number"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans "
                  >
                    Contact Number
                  </label>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={handlePhoneNumber}
                    name="employee_contact_no"
                    id="employee_contact_no"
                    placeholder="Enter your phone number"
                    maxLength="10"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
              <div className="flex flex-col font-bold mt-5">
                <label
                  htmlFor="full_name"
                  className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                >
                  Permanent Address{" "}
                </label>
                <input
                  type="text"
                  name="employee_permanent_address"
                  id="employee_permanent_address"
                  placeholder="Enter Permanent Address"
                  autoComplete="employee_permanent_address"
                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                />
              </div>
              <div className="flex flex-col font-bold mt-5">
                <label
                  htmlFor="full_name"
                  className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                >
                  Temporary Address{" "}
                </label>
                <input
                  type="text"
                  name="employee_temporary_address"
                  id="employee_temporary_address"
                  placeholder="Enter Temporary Address"
                  autoComplete="employee_temporary_address"
                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                />
              </div>
              <div className="flex mt-5 flex-col md:flex-row font-bold">
                <div className="flex flex-col mr-10 w-[50%]">
                  <label
                    htmlFor="police_division"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Police Division
                  </label>
                  <input
                    type="text"
                    name="employee_police_division"
                    id="employee_police_division"
                    autoComplete="employee_police_division"
                    placeholder="Enter Police Division"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="gramasewa_division"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Gramasewa Division{" "}
                  </label>
                  <input
                    type="text"
                    name="employee_gramasewa_division"
                    id="employee_gramasewa_division"
                    autoComplete="employee_gramasewa_division"
                    placeholder="Enter Gramasewa Division"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
              <div className="flex flex-col font-bold mt-5">
                <label
                  htmlFor="gramasewa_division"
                  className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="employee_email"
                  id="employee_email"
                  autoComplete="employee_email"
                  placeholder="Enter email"
                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="mt-5">{renderDependentSections()}</div>
      <div className="ml-[25%] mt-5">
        <button
          type="button"
          onClick={handleAddItemSection}
          className="rounded-full  bg-[#00adef] mb-5 px-3 py-2 text-sm text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
          style={{ borderRadius: "45px" }}
        >
          Add a dependant
        </button>
      </div>
      <div className="flex items-center justify-center">
        <div className="w-[50%] bg-[#F3F8FF] rounded-xl p-8">
          <p className="text-4xl mb-4 font-bold text-[#797C80]">
            Employment Details
          </p>
          <hr className="line border-t border-gray-300 w-[25%]"></hr>
          <div className="mt-10">
            <form>
              <div className="flex mt-5 flex-col md:flex-row gap-5 font-bold">
                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="employee_department"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Department
                  </label>
                  <select
                    id="employee_department"
                    name="employee_department"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-gray-50 placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                    value={selectedDepartment}
                    onChange={(e) => setSelectedDepartment(e.target.value)}
                  >
                    <option>Select</option>
                    {departments.map((department, index) => (
                      <option key={index} value={department}>
                        {department}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex w-[50%] flex-col">
                  <label
                    htmlFor="employee_designation"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Designation
                  </label>
                  <select
                    id="employee_designation"
                    name="employee_designation"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  >
                    <option value="">Select</option>
                    {filteredDesignations.map((designation) => (
                      <option key={designation.id} value={designation.id}>
                        {designation.designation}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex mt-5 flex-col md:flex-row gap-5 w-[100%] font-bold">
                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="date_of_appoinment"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Date of Appointment
                  </label>
                  <input
                    type="date"
                    name="date_of_appoinment"
                    id="date_of_appoinment"
                    autoComplete="date_of_appoinment"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label
                    htmlFor="name_with_initials"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Probation Period (Complete or Not){" "}
                  </label>
                  <select
                    id={`probation_complete`}
                    name={`probation_complete`}
                    autoComplete="probation_complete"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  >
                    <option>Select Probation Status</option>
                    <option value={"COMPLETED"}>Completed</option>
                    <option value={"NOT-COMPLETED"}>Not Completed</option>
                  </select>
                </div>
              </div>
              <div className="flex mt-5 flex-col md:flex-row w-[100%] gap-5 font-bold">
                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="contact_number"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Permanent Appointment Date{" "}
                  </label>
                  <input
                    type="date"
                    name="permanent_appointment_date"
                    id="permanent_appointment_date"
                    autoComplete="first_eb_completion_date"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label
                    htmlFor="name_with_initials"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    1st EB Completion Date
                  </label>
                  <input
                    type="date"
                    name="first_eb_completion_date"
                    id="first_eb_completion_date"
                    autoComplete="first_eb_completion_date"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
              <div className="flex mt-5 w-[100%] gap-5 flex-col md:flex-row font-bold">
                <div className="flex w-[50%] flex-col">
                  <label
                    htmlFor="contact_number"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    2nd EB Completion Date{" "}
                  </label>
                  <input
                    type="date"
                    name="second_eb_completion_date"
                    id="second_eb_completion_date"
                    autoComplete="second_eb_completion_date"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label
                    htmlFor="name_with_initials"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    3rd EB Completion Date{" "}
                  </label>
                  <input
                    type="date"
                    name="third_eb_completion_date"
                    id="third_eb_completion_date"
                    autoComplete="third_eb_completion_date"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
              <div className="flex mt-5 flex-col md:flex-row w-[100%] gap-5 font-bold">
                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="contact_number"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    EPF No{" "}
                  </label>
                  <input
                    type="text"
                    name="employee_epf_no"
                    id="employee_epf_no"
                    autoComplete="employee_epf_no"
                    placeholder="Enter EPF Number"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label
                    htmlFor="employee_basic_salary"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Basic Salary
                  </label>
                  <input
                    type="number"
                    name="employee_basic_salary"
                    id="employee_basic_salary"
                    autoComplete="third_eb_completion_date"
                    placeholder="Enter Basic Salary"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
              <div className="flex mt-5 flex-col md:flex-row w-[100%] gap-5 font-bold">
                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="designation_grade"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Designation Grade
                  </label>
                  <input
                    type="text"
                    name="designation_grade"
                    id="designation_grade"
                    autoComplete="designation_grade"
                    placeholder="Enter Designation Grade"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label
                    htmlFor="salary_code"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Salary Code
                  </label>
                  <input
                    type="number"
                    name="salary_code"
                    id="salary_code"
                    autoComplete="salary_code"
                    placeholder="Enter Salary Code"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
              <div className="flex mt-5 flex-col md:flex-row w-[100%] gap-5 font-bold">
                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="sladc_allowance"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Sladc Allowance
                  </label>
                  <input
                    type="number"
                    name="sladc_allowance"
                    id="sladc_allowance"
                    autoComplete="sladc_allowance"
                    placeholder="Enter Sladc Allowance Number"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label
                    htmlFor="ajt_allowance"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Ajt Allowance{" "}
                  </label>
                  <input
                    type="number"
                    name="ajt_allowance"
                    id="ajt_allowance"
                    autoComplete="ajt_allowance"
                    placeholder="Enter Ajt Allowance"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
              <div className="flex w-[50%] flex-col mt-5">
                <label
                  htmlFor="cost_of_allowance"
                  className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                >
                  Cost of Allowance
                </label>
                <input
                  type="number"
                  name="cost_of_allowance"
                  id="cost_of_allowance"
                  autoComplete="cost_of_allowance"
                  placeholder="Enter Cost of Allowance"
                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                />
              </div>

              <label className="text-xl mb-2 font-bold text-[#797C80] font-sans">
                Supervisor
              </label>

              <select
                name="supervisor_id"
                defaultValue="" // Ensures "Select Supervisor" is the default value
                className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
              >
                <option value="" disabled>
                  Select Supervisor
                </option>
                {supervisors.map((supervisor) => (
                  <option key={supervisor.id} value={supervisor.id}>
                    {supervisor.supervisor_fullname}
                  </option>
                ))}
              </select>

              <label className="text-xl mb-2 font-bold text-[#797C80] font-sans">
                Timetable
              </label>

              <select
                name="timetable_id"
                id="timetable_id"
                onChange={(e) => setTimetableId(parseInt(e.target.value))} // Save the selected timetable ID as an integer
                className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white sm:text-[20px] px-2 font-normal font-sans"
              >
                <option value="" disabled>
                  Select Timetable
                </option>
                {timetables.map((timetable) => (
                  <option
                    key={timetable.TimetableID}
                    value={timetable.TimetableID}
                  >
                    {timetable.TimetableName}
                  </option>
                ))}
              </select>
            </form>
          </div>
        </div>
      </div>
      <div className="ml-[25%] mb-5 mt-5">
        <div className="w-[67%] bg-[#F3F8FF] rounded-xl p-8">
          <p className="text-4xl mb-4 font-bold text-[#797C80]">
            Employment qualification
          </p>
          <hr className="line border-t border-gray-300 w-[25%]"></hr>
          {renderEmploymentQualificationSections()}
          <div className="mt-10"></div>
        </div>
        <button
          type="button"
          onClick={handleAddEmploymentQualificationSection}
          className="rounded-full  bg-[#00adef] mt-5 mb-5 px-3 py-2 text-sm text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
          style={{ borderRadius: "45px" }}
        >
          Add Employment qualification
        </button>
      </div>
      <div className="mt-5">{renderTraningSections()}</div>
      <div className="ml-[25%] mt-5">
        <button
          className="rounded-full  bg-[#00adef] mb-5 px-3 py-2 text-sm text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
          onClick={handleTraningSection}
        >
          Add Training Section
        </button>
      </div>
      <div className="mt-5">{renderDisciplinaryActionsSections()}</div>
      <div className="ml-[25%] mt-5">
        <button
          type="button"
          onClick={handleAddDisciplinaryActionsSections}
          className="rounded-full  bg-[#00adef] mb-5 px-3 py-2 text-sm text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
          style={{ borderRadius: "45px" }}
        >
          Add Disciplinary Actions
        </button>
      </div>
      <div className="flex items-center justify-center">
        <div className="w-[50%] bg-[#F3F8FF] rounded-xl p-8 mt-5">
          <p className="text-4xl mb-4 font-bold text-[#797C80]">Bank Details</p>
          <hr className="line border-t border-gray-300 w-[25%]"></hr>
          <div className="mt-10">
            <form>
              <div className="flex mt-5 flex-col md:flex-row gap-5 font-bold">
                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="name"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Account No{" "}
                  </label>
                  <input
                    type="number"
                    name="employee_account_no"
                    id="employee_account_no"
                    autoComplete="employee_account_no"
                    placeholder="Enter Account No"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label
                    htmlFor="employee_bank_name"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Bank Name{" "}
                  </label>
                  <input
                    type="text"
                    name="employee_bank_name"
                    id="employee_bank_name"
                    autoComplete="employee_bank_name"
                    placeholder="Enter Bank"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
              <div className="flex mt-5 flex-col md:flex-row gap-5 font-bold">
                <div className="flex flex-col w-[50%]">
                  <label
                    htmlFor="employee_branch_name"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Branch Name{" "}
                  </label>
                  <input
                    type="text"
                    name="employee_branch_name"
                    id="employee_branch_name"
                    autoComplete="employee_branch_name"
                    placeholder="Enter Branch Name"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label
                    htmlFor="nic_number"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Bank code{" "}
                  </label>
                  <input
                    type="text"
                    name="employee_bank_code"
                    id="employee_bank_code"
                    autoComplete="employee_bank_code"
                    placeholder="Enter Bank Code"
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>
              <div className="flex w-[48.5%] mt-5 flex-col">
                <label
                  htmlFor="nic_number"
                  className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                >
                  Branch code{" "}
                </label>
                <input
                  type="text"
                  name="employee_branch_code"
                  id="employee_branch_code"
                  autoComplete="employee_branch_code"
                  placeholder="Enter Branch Code"
                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-gray-50 placeholder-white placeholder:opacity-30 sm:text-[20px] px-2 font-normal font-sans"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="w-[50%] bg-[#F3F8FF] rounded-xl p-8 mt-5">
          <p className="text-4xl font-bold text-[#797C80]">
            Upload Personal Documents
          </p>
          <hr className="line border-t border-gray-300 w-[25%]"></hr>
          <div className="flex items-center justify-center">
            <div className="w-[90%] h-screen-auto bg-white rounded-xl p-8 mt-5">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  style={{
                    height: "40px",
                    fill: "#000000",
                    marginRight: "1rem",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      fill=""
                    ></path>
                  </g>
                </svg>
                <div>
                  <p className="text-1xl mb-1 font-bold text-[#797C80]">
                    Upload File
                  </p>
                  <p className="text-1xl mb-2 font-bold text-[#797C80] opacity-30">
                    Select and upload the files of your choice
                  </p>
                </div>
              </div>
              <hr className="line border-t border-gray-300 w-[100%] items-center justify-center"></hr>
              <div className="flex items-center justify-center">
                <label
                  htmlFor="file"
                  style={{
                    height: "300px",
                    width: "97%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    cursor: "pointer",
                    border: "2px dashed #000000",
                    backgroundColor: "#E1E6DC",
                    opacity: "53%",
                    padding: "1.5rem",
                    borderRadius: "10px",
                    boxShadow: "0px 48px 35px -48px #e8e8e8",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      style={{ height: "80px", fill: "#000000" }}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {/* SVG Path */}
                    </svg>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginBottom: "10px" }}>
                        <span style={{ fontWeight: "400", color: "#000000" }}>
                          Choose a PDF file or drag & drop it here
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontWeight: "400",
                            color: "#000000",
                            opacity: "50%",
                          }}
                        >
                          Up to 50MB
                        </span>
                      </div>
                      <input
                        id="file"
                        type="file"
                        style={{ display: "none" }}
                        multiple
                        onChange={handleFileUpload}
                      />
                      <button
                        type="button"
                        className="rounded-full bg-[#FFFFFF] mb-5 px-3 py-2 text-sm text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
                        style={{ borderRadius: "45px" }}
                        onClick={() => document.getElementById("file").click()}
                      >
                        Browse File
                      </button>
                      {showAlertMessage && (
                        <div className="text-red-600 text-sm2 mt-2">
                          Please upload only PDF files.
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </div>
              <div className="mt-5">
                {personalUploadedFiles.map((file, index) => (
                  <div key={index} className="flex items-center mt-2">
                    {file.type === "application/pdf" && (
                      <svg
                        height="50px"
                        width="50px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 56 56"
                      >
                        <path
                          style={{ fill: "#E9E9E0" }}
                          d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
      c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"
                        />
                        <path
                          style={{ fill: "#CC4B4C" }}
                          d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326
      c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75
      c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172
      c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562
      c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16
      c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822
      c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198
      c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z
      M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415
      c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174
      c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z
      M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"
                        />
                        <path
                          style={{ fill: "#CC4B4C" }}
                          d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"
                        />
                        <g>
                          <path
                            style={{ fill: "#FFFFFF" }}
                            d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
      c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
      c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
      s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
      c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032
      c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492
      c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"
                          />
                          <path
                            style={{ fill: "#FFFFFF" }}
                            d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937
      s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035
      c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z
       M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
      c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"
                          />
                          <path
                            style={{ fill: "#FFFFFF" }}
                            d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"
                          />
                        </g>
                        <polygon
                          style={{ fill: "#D9D7CA" }}
                          points="37.5,0.151 37.5,12 49.349,12 	"
                        />
                      </svg>
                    )}
                    <div>{file.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-[25%] mt-5 flex">
        <div className="mr-5">
          <button
            type="button"
            onClick={handleSubmit}
            className="rounded-full  bg-[#00adef] mb-5 px-3 py-2 text-sm text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
            style={{ borderRadius: "45px" }}
          >
            Submit
          </button>
        </div>
      </div>
      <div>
        <div className="ml-[75%] mr-5">
          <button
            type="button"
            onClick={handleScrollUp}
            className="rounded-full  bg-[#00adef] mb-5 px-3 py-2 text-sm text-gray-50 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
            style={{ borderRadius: "45px" }}
          >
            ^
          </button>
        </div>
      </div>
    </div>
  );
}

export default Emp_onBoard;
