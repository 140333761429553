/** @format */

import React, { useState, useEffect, useRef } from "react";
import IdGenerate from "../../../../utils/id_generate";
import Alert from "../../../../components/alert";
import useDebounce from "../../../../utils/useDebounce";
import { useReactToPrint } from "react-to-print";
import Printer from "../../../../Assets/icons/print 1.png";
import Switch from "react-switch";
import Cookies from "js-cookie"; // Import js-cookie to retrieve the return_id
import { useLocation } from "react-router-dom"; // Import useLocation

export default function Pos() {
  const location = useLocation();
  const [returnId, setReturnId] = useState(null); // Define returnId state
  const [customerName, setCustomerName] = useState(""); // Define customerName state
  const [customerId, setCustomerId] = useState(""); // To store the customer ID
  const { refund } = location.state;

  useEffect(() => {
    if (location.state && location.state.returnId) {
      setReturnId(location.state.returnId); // Set returnId from location state
    }

    if (location.state && location.state.refund) {
      console.log("Refund received:", location.state.refund); // Debug print for refund
    } else {
      console.error("Refund is missing from location.state.");
    }

    if (location.state && location.state.customer_id) {
      setCustomerName(location.state.customer_id); // Set customer ID in the input
    }
  }, [location.state]);

  // The rest of your component logic...

  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });
  useEffect(() => {
    // Retrieve the return_id from the location state if present
    if (location.state && location.state.returnId) {
      setReturnId(location.state.returnId); // Use returnId instead of return_id
    } else {
      // Retrieve the returnId from cookies when the component mounts
      const savedReturnId = Cookies.get("returnId");
      if (savedReturnId) {
        setReturnId(savedReturnId);
      }
    }
  }, [location.state]); // Ensure this runs when location.state changes

  const [discounts, setDiscounts] = useState([]);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [userId, setUserId] = useState("USER-000000");
  const [userName, setUserName] = useState("unknown");
  const [creditBalance, setCreditBalance] = useState(0);
  const [creditLimit, setCreditLimit] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [cash, setCash] = useState(null);
  const [credit, setCredit] = useState(null);
  const [balance, setBalance] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState(IdGenerate("INVOICE"));
  const [branch, setBranch] = useState("BRANCH-0001");
  const [customershow, setCustomerShow] = useState(false);
  const [itemSearchTerm, setItemSearchTerm] = useState("");
  const [items, setItems] = useState([]);
  const debouncedItemSearchTerm = useDebounce(itemSearchTerm, 500);
  const [highlightedItemIndex, setHighlightedItemIndex] = useState(-1);

  const [customer_payment_method, setCustomerPaymentMethod] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const contentToPrint = useRef(null);
  const [id, setId] = useState(IdGenerate("CUSTOMER"));
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [dob, setDob] = useState("");
  const [mail, setMail] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash"); // Default to "cash"
  const [cashPayment, setCashPayment] = useState(0);
  const [creditPayment, setCreditPayment] = useState(netTotal); // Default credit to net total
  const [cardPayment, setCardPayment] = useState(0);
  const [isPartialPayment, setIsPartialPayment] = useState(false); // Controls partial payment toggle
  const { REACT_APP_API_ENDPOINT } = process.env;

  const data = {
    // other fields...
    pos_cash:
      customer_payment_method === "cash"
        ? paymentMethod === "cash"
          ? cashPayment
          : 0
        : 0,
    pos_card:
      customer_payment_method === "cash"
        ? paymentMethod === "card"
          ? cardPayment
          : 0
        : cardPayment,
    pos_credit: customer_payment_method === "credit" ? creditPayment : 0,
    // other fields...
  };

  // Handle cash payment input change
  const handleCashPaymentChange = (e) => {
    const cashValue = parseFloat(e.target.value) || 0;
    setCashPayment(cashValue);
    // Adjust credit payment accordingly
    setCreditPayment(netTotal - cashValue);
  };

  const handlePaymentMethodToggle = () => {
    setPaymentMethod(paymentMethod === "cash" ? "card" : "cash");
  };
  useEffect(() => {
    const totalPaid =
      cashPayment +
      cardPayment +
      (customer_payment_method === "credit" ? creditPayment : 0);
    setBalance(netTotal - totalPaid);
  }, [cashPayment, cardPayment, creditPayment, netTotal]);

  const handleCardPaymentChange = (e) => {
    const cardValue = parseFloat(e.target.value) || 0;
    setCardPayment(cardValue);
    setCreditPayment(netTotal - (cashPayment + cardValue));
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      const fetchCustomers = async () => {
        try {
          const response = await fetch(
            `${REACT_APP_API_ENDPOINT}/v1/inventory/pos/get-all-coustomer`
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          if (Array.isArray(data)) {
            const filteredCustomers = data.filter((customer) =>
              customer.customer_name
                .toLowerCase()
                .includes(debouncedSearchTerm.toLowerCase())
            );
            setCustomers(filteredCustomers);
          } else {
            console.error("Unexpected response format: ", data);
          }
        } catch (error) {
          console.error("Error fetching customer data:", error);
        }
      };

      fetchCustomers();
    } else {
      setCustomers([]); // Clear customers when search term is empty
    }
  }, [debouncedSearchTerm]);

  const CustomerAddHandler = () => {
    setCustomerShow(true);
  };

  const AddCustomer = async () => {
    const data = {
      customer_id: id,
      customer_name: name,
      customer_contact_no: contact,
      customer_dob: dob,
      customer_email: mail,
      category: "unregistered",
      customer_update_user_id: userId,
      customer_update_date: formattedDate,
      discount: [],
    };

    if (name === "" || contact === "" || dob === "" || mail === "") {
      setAlert({
        status: "error",
        alert: "Please fill the required fields",
        show: true,
      });
    } else {
      try {
        const res = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/customer/add`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        if (res.status === 201 || res.status === 200) {
          setAlert({
            status: "success",
            alert: "Customer added successfully",
            show: true,
          });
          setId(IdGenerate("CUSTOMER"));
          setName("");
          setContact("");
          setDob("");
          setMail("");
          setCustomerShow(false);
        }
      } catch (error) {
        setAlert({
          status: "error",
          alert: "Customer already exists or an error occurred",
          show: true,
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (customers.length > 0) {
      if (e.key === "ArrowDown") {
        setHighlightedIndex((prevIndex) =>
          prevIndex < customers.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (e.key === "ArrowUp") {
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
      } else if (e.key === "Enter" && highlightedIndex >= 0) {
        handleCustomerSelect(customers[highlightedIndex]);
      }
    }
  };

  const handleCashOrCreditKeyDown = (e) => {
    if (e.key === "Enter") {
      if (balance < 0) {
        setAlert({
          status: "error",
          alert: "Settle the bill first.",
          show: true,
        });
      } else if (balance >= 0 && customerId && netTotal > 0) {
        // Trigger the Save button functionality
        EnterHandler();
      }
    }
  };

  const preparePostData = () => {
    let postData = {
      branch_id: Cookies.get("branch"),
      pos_user_id: Cookies.get("employee_no"),
      pos_type: "BRANCH",
      pos_status: balance === 0 ? "CLOSED" : "PENDING",
      customer_id: Cookies.get("customer_id"),
      pos_sub_total: subTotal,
      pos_discount: discount,
      pos_net_total: netTotal,
      payment_method: isPartialPayment
        ? "PARTIAL"
        : paymentMethod.toUpperCase(),
      pos_items: tableData.map((item) => ({
        id_of_branch_stock: item.id,
        inventory_batch_id: item.inventory_batch_id,
        pos_item_qty: item.item_qty,
        pos_items_price: item.item_price,
      })),
    };

    if (customer_payment_method === "credit") {
      // Credit customer: always include split payments if partial, else full amount as credit
      postData.pos_cash = isPartialPayment ? cashPayment : 0;
      postData.pos_card = isPartialPayment ? cardPayment : 0;
      postData.pos_credit = isPartialPayment ? creditPayment : netTotal;
    } else {
      // Cash customer
      if (isPartialPayment) {
        // Include both cash and card for partial payments
        postData.pos_cash = cashPayment;
        postData.pos_card = cardPayment;
        postData.pos_credit = 0; // Make sure to send zero if no credit is used in partial payment
      } else {
        // Normal full payment, either cash or card depending on the method chosen
        postData.pos_cash = paymentMethod === "cash" ? netTotal : 0;
        postData.pos_card = paymentMethod === "card" ? netTotal : 0;
        postData.pos_credit = 0; // Ensure no credit value is sent for cash customers in full payment
      }
    }

    return postData;
  };

  const EnterHandler = async () => {
    const postData = preparePostData();

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/pos/branch/addBranchPos`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );

      if (response.ok) {
        handlePrint();
        setAlert({
          status: "success",
          alert: "Bill Added Successfully",
          show: true,
        });
        resetForm(); // Reset the form on success
      } else {
        const errorText = await response.text();
        setAlert({
          status: "error",
          alert: `Something went wrong: ${errorText}`,
          show: true,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setAlert({
        status: "error",
        alert: "Something went wrong. Please try again.",
        show: true,
      });
    }
  };

  // Helper function to reset the form after submission
  const resetForm = () => {
    setCustomerName("");
    setCustomerId("");
    setInvoiceNo(IdGenerate("INVOICE"));
    setBranch("BRANCH-0001");
    setDiscount(0);
    setNetTotal(0);
    setCash(0);
    setCredit(0);
    setBalance(0);
    setTableData([]);
  };

  const CancelHandler = () => {
    setCustomerName("");
    setCustomerId("");
    setInvoiceNo(IdGenerate("INVOICE"));
    setBranch("BRANCH-0001");
    setSubTotal(0);
    setDiscount(0);
    setNetTotal(0);
    setCash(0);
    setBalance(0);
    setCredit(0);
    setTableData([]);
  };

  useEffect(() => {
    const branch = Cookies.get("branch");

    if (debouncedItemSearchTerm && branch) {
      const fetchItems = async () => {
        try {
          const endpoint = `${REACT_APP_API_ENDPOINT}/v1/inventory/pos/getBranchStockBatchByBatchId/${branch}/${debouncedItemSearchTerm}`;

          const response = await fetch(endpoint);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setItems(data);
        } catch (error) {
          console.error("Error fetching item data:", error);
        }
      };

      fetchItems();
    } else {
      setItems([]); // Clear items when search term is empty
    }
  }, [debouncedItemSearchTerm]);

  const handleItemSelect = (item) => {
    setTableData((prevData) => [
      ...prevData,
      {
        id: item.id, // Store the id from the selected item
        inventory_batch_id: item.inventory_batch_id, // Store the inventory_batch_id
        item_name: item.item_name,
        item_qty: 1,
        item_price: item.batch_unit_price,
        item_available_qty: item.current_qty,
        item_measure_unit: item.item_measure_unit,
        item_total: item.batch_unit_price,
      },
    ]);
    setItemSearchTerm("");
    setItems([]);
  };

  const handleItemKeyDown = (e) => {
    if (items.length > 0) {
      if (e.key === "ArrowDown") {
        setHighlightedItemIndex((prevIndex) =>
          prevIndex < items.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (e.key === "ArrowUp") {
        setHighlightedItemIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
      } else if (e.key === "Enter" && highlightedItemIndex >= 0) {
        handleItemSelect(items[highlightedItemIndex]);
      }
    }
  };

  useEffect(() => {
    const newSubTotal = tableData.reduce(
      (total, item) => total + item.item_total,
      0
    );
    setSubTotal(newSubTotal);

    const newNetTotal = newSubTotal - discount;
    setNetTotal(newNetTotal);
  }, [tableData, discount]);

  useEffect(() => {
    let calculatedBalance = 0;

    if (customer_payment_method === "cash") {
      calculatedBalance = cash - netTotal;
    } else if (customer_payment_method === "credit") {
      calculatedBalance = 0.0; // Set balance to 0.00 for credit transactions
    }

    setBalance(calculatedBalance);
  }, [cash, netTotal, customer_payment_method]);

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Invoice",
    onBeforePrint: () => console.log("Printing..."),
    onAfterPrint: () => console.log("Print complete."),
  });

  const GetCustomerDiscount = async (id) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/customer/discounts/${id}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data && Array.isArray(data.discounts)) {
        setDiscounts(data.discounts);
      } else {
        console.error("Unexpected data format:", data);
        setDiscounts([]); // Ensure discounts is always an array
      }
    } catch (error) {
      console.error("Error fetching discount:", error);
      setDiscounts([]);
    }
  };
  // Initial state for discount information
  const [discountInfo, setDiscountInfo] = useState({
    amount: 0,
    rate: 0,
  });
  const handleCustomerSelect = (customer) => {
    setCustomerName(customer.customer_name);
    setCustomerId(customer.customer_id);
    setCreditBalance(parseFloat(customer.available_credit_limit) || 0);
    setCreditLimit(parseFloat(customer.credit_limit) || 0);
    setCustomerPaymentMethod(customer.customer_payment_method);

    // Load discounts and apply
    setDiscounts(customer.discounts || []);
    applyAppropriateDiscount(subTotal);
    // Fetch discounts for the selected customer
    GetCustomerDiscount(customer.customer_id);
    setCustomers([]); // Clear the list after selection
    setSearchTerm("");
  };
  const applyAppropriateDiscount = (newSubTotal) => {
    const foundDiscount = discounts.find((discount) => {
      const [min, max] = discount.value_range.split("-").map(Number);
      return newSubTotal >= min && newSubTotal <= max;
    });

    if (foundDiscount) {
      const discountAmount = (newSubTotal * foundDiscount.approved_rate) / 100;
      setDiscountInfo({
        amount: discountAmount,
        rate: foundDiscount.approved_rate,
      });
    } else {
      setDiscountInfo({ amount: 0, rate: 0 }); // Reset if no range matches
    }
  };

  useEffect(() => {
    applyAppropriateDiscount(subTotal); // Apply discount whenever the subtotal changes
  }, [subTotal, discounts]);

  useEffect(() => {
    const newNetTotal = subTotal - discountInfo.amount;
    setNetTotal(newNetTotal);
  }, [subTotal, discountInfo.amount]); // Make sure to depend on discountInfo.amount
  // Handle toggling partial payment fields
  const handlePartialPaymentToggle = () => {
    setIsPartialPayment(!isPartialPayment); // Toggle visibility
    if (!isPartialPayment) {
      setCashPayment(0); // Reset cash payment when toggled
      setCardPayment(0); // Reset card payment when toggled
      setCreditPayment(netTotal); // Reset credit to netTotal when opened
    }
  };
  useEffect(() => {
    if (isPartialPayment && customer_payment_method === "credit") {
      const totalPayment = cashPayment + cardPayment;
      const newCreditPayment = Math.max(0, netTotal - totalPayment);
      setCreditPayment(newCreditPayment);
    }
  }, [
    cashPayment,
    cardPayment,
    netTotal,
    isPartialPayment,
    customer_payment_method,
  ]);

  useEffect(() => {
    let adjustedSubTotal = subTotal;

    // Check if subTotal exceeds refund
    if (refund && subTotal <= refund) {
      adjustedSubTotal = 0; // Set to 0 if subtotal doesn't exceed refund
    } else if (refund && subTotal > refund) {
      adjustedSubTotal = subTotal - refund; // Subtract refund from subtotal if it exceeds
    }

    const newNetTotal = adjustedSubTotal - discountInfo.amount;
    setNetTotal(newNetTotal >= 0 ? newNetTotal : 0); // Ensure netTotal is never negative
  }, [subTotal, discountInfo.amount, refund]);

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <Alert
        show={alert.show}
        alert={alert.alert}
        status={alert.status}
        ok={() => {
          setAlert({
            status: "",
            alert: "",
            show: false,
          });
        }}
      />

      <p className="text-[20px] font-bold ml-10 mt-2"> Point of Sale</p>

      <div className="flex items-center gap-3 ml-5">
        <p className="text-[20px] font-bold ">Return ID</p>
        <input
          className="border border-black rounded-lg p-2"
          value={returnId} // Display the returnId
          readOnly
        />
      </div>

      <div className="w-full flex gap-x-10 items-center justify-between px-16 mt-2 ">
        <div className=" flex items-center content-between gap-x-10"></div>
      </div>

      {customers.length > 0 && (
        <div className="w-full flex flex-col items-center justify-center px-12 ">
          <div className="relative w-[20%] ml-[70%]">
            <div className="absolute top-0 left-0 right-0 bg-white rounded-[10px] shadow-md mx-auto w-[80%] z-10">
              {customers.map((customer, index) => (
                <div
                  key={customer.customer_id}
                  className={`px-4 py-2 cursor-pointer hover:bg-gray-200 ${
                    index === highlightedIndex ? "bg-gray-300" : ""
                  }`}
                  onClick={() => handleCustomerSelect(customer)}
                >
                  {customer.customer_name}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="w-full flex items-center justify-center px-12 -scroll-mt-10 mt-3 ">
        <div className="bg-PrimaryColor1 w-full h-[62px] rounded-[100px] mx-20 flex items-center">
          <p className="text-[15px] font-bold ml-[50px] text-white">
            Item name :
          </p>
          <div>
            <input
              id="itemSearchInput"
              type="text"
              className="h-10 p-2 rounded-xl mr-5 ml-5 w-[400px]"
              placeholder="Search Item..."
              value={itemSearchTerm}
              onChange={(e) => setItemSearchTerm(e.target.value)}
              onKeyDown={handleItemKeyDown}
            />

            {items.length > 0 && (
              <div className="w-full items-center mt-2">
                <div className="relative">
                  <div className="absolute top-0 left-0 right-0 bg-white rounded-[10px] shadow-md mx-auto w-full z-10">
                    {items.map((item, index) => (
                      <div
                        key={item.inventory_batch_id}
                        className={`px-4 py-2 cursor-pointer hover:bg-gray-200 ${
                          index === highlightedItemIndex ? "bg-gray-300" : ""
                        }`}
                        onClick={() => handleItemSelect(item)}
                      >
                        {item.item_name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-center my-3">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-[40px] bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 text-[20px] font-bold text-[#656565] text-center">
              #
            </p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full text-[20px] font-bold text-[#656565] text-center ">
                Item Name
              </p>
              <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] "></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full text-[20px] font-bold text-[#656565] text-center ">
                Available Qty
              </p>
              <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] "></div>
            </div>
            <div className="w-[110px] flex flex-col items-center">
              <p className="w-full text-[20px] font-bold text-[#656565] text-center ">
                Price
              </p>
              <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] "></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full text-[20px] font-bold text-[#656565] text-center ">
                Quantity
              </p>
              <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] "></div>
            </div>
            <div className="w-[70px] flex flex-col items-center">
              <p className="w-full text-[20px] font-bold text-[#656565] text-center ">
                Unit
              </p>
              <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] "></div>
            </div>
            <div className="w-[150px] flex flex-col items-center">
              <p className="w-full text-[20px] font-bold text-[#656565] text-center ">
                Total
              </p>
              <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] "></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full text-[20px] font-bold text-[#656565] text-center ">
                Action
              </p>
              <div className="border-[2px] border-[#656565] w-[67px] rounded-[100px] "></div>
            </div>
          </div>
          <div className="overflow-y-auto max-h-[300px]">
            {" "}
            {/* Add scrollable div here */}
            {tableData.length > 0 &&
              tableData.map((item, index) => (
                <div
                  key={index}
                  className="w-full flex items-start justify-around my-3 "
                >
                  <p className="w-10 text-[15px] font-bold text-[#656565] text-center">
                    {index + 1}
                  </p>
                  <p className="w-[350px] text-[15px] font-bold text-[#656565] ">
                    {item.item_name}
                  </p>
                  <p className="w-[160px] text-[15px] font-bold text-[#656565] text-center">
                    {item.item_available_qty}
                  </p>
                  <p className="w-[110px] text-[15px] font-bold text-[#656565] text-center">
                    {item.item_price}
                  </p>
                  <p className="w-[120px] text-[15px] font-bold text-[#656565] text-center">
                    <input
                      type="number"
                      value={item.item_qty}
                      className={
                        item.item_available_qty < item.item_qty
                          ? "border-[#ef0e0ef7] rounded-2xl w-full border-4 px-1 focus:outline-none"
                          : "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                      }
                      onChange={(e) => {
                        let temp = [...tableData];
                        let newValue = parseFloat(e.target.value) || 0;

                        // Prevent negative numbers
                        if (newValue < 0) {
                          newValue = 0;
                        }

                        temp[index].item_qty = newValue;
                        temp[index].item_total =
                          temp[index].item_qty * temp[index].item_price;
                        setTableData(temp);
                      }}
                    />
                  </p>
                  <p className="w-[70px] text-[20px] font-bold text-[#656565] text-center">
                    {item.item_measure_unit}
                  </p>
                  <p className="w-[150px] text-[15px] font-bold text-[#656565] text-center">
                    {item.item_total ? item.item_total.toFixed(2) : "0.00"}
                  </p>
                  <p className="w-[120px] text-[15px] font-bold text-[#656565] text-center">
                    <button
                      className="w-[80px] p-1 rounded-[100px] bg-[#EDC2C2]"
                      onClick={() => {
                        let temp = [...tableData];
                        temp.splice(index, 1);
                        setTableData(temp);
                      }}
                    >
                      Delete
                    </button>
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-start px-16 ">
        <div className=" rounded-[23px] bg-[#F3F8FF] px-5 py-4 flex flex-col justify-between gap-y-7">
          <div className="w-full flex items-center justify-center px-12 -scroll-mt-10 mt-3">
            <div className="w-full flex items-center justify-between gap-10">
              <p className="text-[20px] font-bold text-[#656565] w-[500px]">
                Customer ID:
              </p>
              <input
                className="text-[20px] font-bold text-[#656565] w-70 border-2 border-[#00000059] h-9 rounded-[100px] px-7 bg-white overflow-hidden"
                value={customerName} // Display customer ID here
                readOnly
              />
            </div>
          </div>

          {/* <div className="w-full flex items-center justify-between">
            <p className="text-[20px] font-bold text-[#656565]">
              Credit Limit :
            </p>
            <p className="text-[20px] font-bold text-[#656565] border-2 border-[#00000059] h-9 w-70 rounded-[100px] px-7 bg-white">
              {creditLimit.toFixed(2)} LKR
            </p>
          </div> */}
          {/* <div className="w-full flex items-center justify-between">
            <p className="text-[20px] font-bold text-[#656565]">
              Credit Balance :
            </p>
            <p className="text-[20px] font-bold text-[#656565] border-2 border-[#00000059] h-9 w-70 rounded-[100px] px-7 bg-white">
              {creditBalance.toFixed(2)} LKR
            </p>
          </div> */}
        </div>
        <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
          <div className="flex items-center justify-between">
            <p className="text-[20px] font-bold text-[#656565]">Sub Total</p>
            <p className="text-[20px] font-bold text-[#656565]">
              {subTotal > refund ? (subTotal - refund).toFixed(2) : "0.00"}
            </p>
          </div>

          <div className="flex items-center justify-between">
            <p className="text-[20px] font-bold text-[#656565]">Outstanding</p>
            <p className="font-semibold text-[20px]">
              LKR : {refund?.toFixed(2) || "0.00"}{" "}
            </p>
          </div>

          <div className="flex items-center justify-between">
            <p className="text-[20px] font-bold text-[#656565]">Net Total</p>
            <p className="text-[20px] font-bold text-[#656565]">
              {netTotal.toFixed(2)}
            </p>
          </div>

          <button
            onClick={handlePartialPaymentToggle}
            className="partial-payment-button"
          >
            {isPartialPayment ? "Close Partial Payment" : "Partial Payment"}
          </button>

          {customer_payment_method === "cash" && !isPartialPayment && (
            <div className="w-[90%] flex items-center justify-between mb-4 mt-10">
              <p className="text-[20px] font-bold text-[#656565] ">Method:</p>
              <Switch
                onChange={handlePaymentMethodToggle}
                checked={paymentMethod === "card"}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={30}
                uncheckedIcon={
                  <div className="p-5 mr-12 font-bold mb-2">Cash</div>
                }
                checkedIcon={
                  <div className="p-5 mr-8 font-bold mb-2">Card</div>
                }
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                className="react-switch"
                id="material-switch"
              />
            </div>
          )}

          {paymentMethod === "cash" &&
            customer_payment_method !== "credit" &&
            !isPartialPayment && (
              <>
                <div className="flex items-center justify-between">
                  <p className="text-[20px] font-bold text-[#656565]"> Cash</p>
                  <input
                    className="w-[130px] font-bold h-7 rounded-[100px] border-[#00000059] border-2 bg-white px-2 text-end"
                    type="number"
                    value={cash || ""}
                    onChange={(e) => {
                      let newValue = parseFloat(e.target.value) || 0;

                      if (newValue < 0) {
                        newValue = 0;
                      }

                      setCash(newValue);
                    }}
                    onKeyDown={handleCashOrCreditKeyDown}
                  />
                </div>

                {/* <div className="flex items-center justify-between">
                <p className="text-[20px] font-bold text-[#656565]"> Balance</p>
                <p className="text-[20px] font-bold text-[#656565]">
                  {balance.toFixed(2)}
                </p>
              </div>

              {balance >= 0 && customerId && netTotal > 0 && (
                <button
                  className="w-[118px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4 ml-[65%] mt-10"
                  onClick={EnterHandler}
                >
                  <p className="p2 text-white">Save</p>
                </button>
              )} */}
              </>
            )}

          {isPartialPayment && (
            <>
              <div className="flex items-center justify-between">
                <p className="text-[20px] font-bold text-[#656565]"> Cash</p>
                <input
                  className="w-[130px] font-bold h-7 rounded-[100px] border-[#00000059] border-2 bg-white px-2 text-end"
                  type="number"
                  value={cashPayment}
                  onChange={handleCashPaymentChange}
                  min="0"
                />
              </div>
              <div className="flex items-center justify-between">
                <p className="text-[20px] font-bold text-[#656565]"> Card</p>
                <input
                  className="w-[130px] font-bold h-7 rounded-[100px] border-[#00000059] border-2 bg-white px-2 text-end"
                  type="number"
                  value={cardPayment}
                  onChange={handleCardPaymentChange}
                  min="0"
                />
              </div>
              {customer_payment_method === "credit" && (
                <div className="flex items-center justify-between">
                  <p className="text-[20px] font-bold text-[#656565]">
                    {" "}
                    Credit
                  </p>
                  <input
                    className="w-[130px] font-bold h-7 rounded-[100px] border-[#00000059] border-2 bg-white px-2 text-end"
                    type="number"
                    value={creditPayment.toFixed(2)}
                    readOnly
                  />
                </div>
              )}
            </>
          )}
          <button
            className="w-[118px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4 ml-[65%] mt-10"
            onClick={EnterHandler}
          >
            <p className="p2 text-white">Save</p>
          </button>
        </div>
      </div>
      <div
        className={
          customershow
            ? "w-full h-[100%] absolute top-0 bg-[#3F3F3F5E] z-20 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#F3F8FF] px-20 py-10 rounded-[46px] flex flex-col items-center gap-y-10 ">
          <p className="p1">Add Customer</p>
          <div className="flex items-center justify-between w gap-x-10 ">
            <p className="label-1 w-[300px] text-start">Customer Name :</p>
            <input
              className="w-[434px] h-[46px] rounded-[23px] border border-[#3F3F3F5E] px-5 font-bold"
              placeholder="Enter Customer Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w gap-x-10 ">
            <p className="label-1 w-[300px] text-start">Contact Number :</p>
            <input
              className="w-[434px] h-[46px] rounded-[23px] border border-[#3F3F3F5E] px-5 font-bold"
              placeholder="Enter Phone Number"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w gap-x-9">
            <p className="label-1 w-[300px] text-start">DoB :</p>
            <input
              type="date"
              className="w-[434px] h-[46px] rounded-[23px] border border-[#3F3F3F5E] px-5 font-bold"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w gap-x-10 ">
            <p className="label-1 w-[300px] text-start">Email :</p>
            <input
              className="w-[434px] h-[46px] rounded-[23px] border border-[#3F3F3F5E] px-5 font-bold"
              placeholder="Enter Email"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
          </div>

          <div className="w-full flex items-center justify-end gap-x-8">
            <button
              className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
              onClick={() => {
                setCustomerShow(false);
              }}
            >
              Cancel
            </button>
            <button
              className="w-[118px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4 text-white font-bold text-xl"
              onClick={AddCustomer}
            >
              Add
            </button>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-end gap-x-6 my-4 px-16">
        <button
          className="w-[138px] h-[41px] flex items-center justify-between bg-white border rounded-[23px] px-5"
          onClick={handlePrint}
        >
          <img src={Printer} alt="" />
          <p className="p2 text-[#656565]">Print</p>
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4"
          onClick={CancelHandler}
        >
          <p className="p2 text-white">Cancel</p>
        </button>

        {customer_payment_method === "credit" && (
          <>
            <button
              className="w-[118px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4"
              onClick={EnterHandler}
            >
              <p className="p2 text-white">Save</p>
            </button>
          </>
        )}
      </div>

      <div style={{ display: "none" }}>
        <div ref={contentToPrint}>
          <div className="bill-container">
            <div className="header">
              <h1>Demo Company</h1>
              <p>No.94 test Sri Lanka</p>
              <p>Phone: 011111111 Email: test@gmail.com</p>
              <p>Website: www.test.lk</p>
            </div>
            <hr />
            <div className="invoice-details">
              <p>Invoice No: {invoiceNo}</p>
              <p>Date: {formattedDate}</p>
              <p>Cashier: {userName}</p>
              <p>Payment Method: {paymentMethod}</p>
            </div>
            <hr />
            <div className="customer-details">
              <p>Customer Name: {customerName}</p>
            </div>
            <hr />
            <table className="product-table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 &&
                  tableData.map((product, index) => (
                    <tr key={index}>
                      <td>{product.item_name}</td>
                      <td>{product.item_qty}</td>
                      <td>{product.item_price}</td>
                      <td>{product.item_price * product.item_qty}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <hr />
            <div className="totals">
              <p>Outstabding : LKR : {refund?.toFixed(2) || "0.00"}</p>
              <p>Subtotal: {subTotal.toFixed(2)}</p>
              <p>Discount: {discount}</p>
              <hr />
              <p>Grand Total: {netTotal.toFixed(2)}</p>
            </div>
            <hr />
            <div className="footer">
              <p>Please check the products prior to purchase.</p>
              <p>Payments are non-refundable under any circumstance.</p>
              <p>
                We are not responsible for any damages or discrepancies reported
                after products have been dispatched.
              </p>
              <p>
                We do not accept returns or exchanges after the purchase has
                been completed.
              </p>
              <p>For any inquiries Contact us at: 0112850229</p>
              <h2>Thank you!</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
