/** @format */

import React from "react";
import "./card1.css";

export default function Card1(props) {
  return (
    <div className="w-[375px] h-[220px] bg-[#F3F8FF] rounded-[22px] flex flex-col px-5 2">
      <p className="font-bold text-[28px] text-[#797C80]">{props.title1}</p>

      <div className="flex justify-between items-center ">
        <div className="flex items-center gap-5">
          <p className="font-bold text-[28px] w-[200px]  text-[#797C80]">
            {props.title2}
          </p>
          <p className="font-bold text-[20px] w-[100px] mt-2 text-[#797C80]">
            {props.subTitle}
          </p>
        </div>
      </div>

      <div className="flex items-center justify-between mt-5">
        <div className="p-3 h-[64px] rounded-[20%] bg-[#74d4f4] flex items-center justify-center">
          <p className="font-bold text-2xl text-white">{props.value}</p>
        </div>
        <div>
          <img src={props.img} alt="" className="h-[100px] mr-4" />
        </div>
      </div>
    </div>
  );
}
