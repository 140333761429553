/** @format */

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useNavigate, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Cookies from "js-cookie";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";
import FileIcon from "../../../../Assets/icons/pdf_file.png";

const PurchaseRequestView = ({}) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [conId, setconId] = useState("");
  const [tName, settName] = useState("Supply Manager");
  const [tCategory, settCategory] = useState("");
  const [DeadName, setDeadName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [costEst, setCostEst] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const location = useLocation();
  const { selectedRecord = {}, statusPR } = location.state || {};
  const [tableData, setTableData] = useState(selectedRecord.pr_items || []);
  const userId = Cookies.get("employee_no");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreviewsecond, setImagePreviewsecond] = useState(null);
  const [imagesecond, setImagesecond] = useState(null);
  const [imagePreviewthired, setImagePreviewthired] = useState(null);
  const [imagethired, setImagethired] = useState(null);

  console.log("selected Record", selectedRecord);
  // Log statusPR to verify its value

  // Define a function to fetch Procument item data
  const fetchProItemData = async () => {
    try {
      // Extract item IDs from procurement_items
      const itemIds = selectedRecord.pr_items.map((item) => item.item_id);

      // Fetch data for each item ID
      const fetchPromises = itemIds.map((itemId) =>
        fetch(`${REACT_APP_API_ENDPOINT}/v1/inventory/item/item/${itemId}`)
      );

      // Await all fetches
      const responses = await Promise.all(fetchPromises);

      // Parse all responses
      const dataPromises = responses.map((response) => response.json());
      const dataArray = await Promise.all(dataPromises);

      // Prepare table data based on fetched data
      const newTableData = dataArray.map((itemData, index) => {
        const procurementItem = selectedRecord.pr_items[index];
        return {
          item_id: itemData.item_id,
          item_name: itemData.item_name,
          item_measure_unit: itemData.item_measure_unit,
          item_category_name: itemData.item_category_name,
          item_sub_category_name: itemData.item_sub_category_name,
          total_shadow_qty: itemData.total_shadow_qty,
          item_qty: procurementItem.item_qty || 0, // Use item_qty from procurement_items if available
        };
      });

      // Update tableData state
      setTableData(newTableData);
    } catch (error) {
      console.error("Error fetching item data:", error);
    }
  };

  // Initialize state with selectedRecord image URLs
  useEffect(() => {
    if (selectedRecord) {
      setImagePreview(selectedRecord.ordered_img);

      // Check if approved_img is null
      if (selectedRecord.approved_img === null) {
        setImagePreviewsecond(null);
      } else {
        setImagePreviewsecond(selectedRecord.approved_img);
      }

      // Add the similar logic for prepared_img if needed
      // if (selectedRecord.prepared_img === null) {
      //   setImagePreviewThird(null);
      // } else {
      //   setImagePreviewThird(selectedRecord.prepared_img);
      // }
    }
  }, [selectedRecord]);

  useEffect(() => {
    if (selectedRecord.pr_items.length > 0) {
      fetchProItemData();
    }
  }, [selectedRecord.pr_items]);

  const handleSubmit = async () => {
    // Body of the PUT request
    const formData = new FormData();

    // Check if StatusPR is Approved or Prepared and append the relevant data
    if (statusPR === "Approved") {
      formData.append("approved_user_id", userId);
      formData.append("approved_date", formattedDate);
      formData.append("approved_img", imagesecond);

      console.log("approved_img", imagesecond);
    } else if (statusPR === "Prepared") {
      formData.append("prepared_user_id", userId);
      formData.append("prepared_date", formattedDate);
      formData.append("prepared_img", imagethired);
      console.log("prepared_img", imagethired);
    }

    try {
      // Make the PATCH request using Axios
      const response = await axios.patch(
        `${REACT_APP_API_ENDPOINT}/v1/supplychain/purchaseRequest/patch/${selectedRecord.pr_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Purchase Request successfully approved by department");
      navigate(-1);
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Purchase Request");
    }
  };

  // Reset function
  const handleReset = () => {
    navigate(-1);
  };
  const handleReject = () => {};

  //First Signature Box
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Signature Box
  const handleDragOversecond = (e) => {
    e.preventDefault();
  };

  const handleDropsecond = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagesecond(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewsecond(reader.result);
    };

    reader.readAsDataURL(file);
  };

  //Theired Signature Box
  const handleDragOverthired = (e) => {
    e.preventDefault();
  };

  const handleDropthired = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagethired(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewthired(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          View Purchase Request
        </p>
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">View Purchase Request</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">PR No</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.pr_id}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Request Title</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.title}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">From</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.department_name}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Subject</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.category}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">To</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={tName}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Request Date</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.pr_date}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Due Date</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.required_date}
              disabled
            />
          </div>
        </div>
      </div>

      <p className="header-1 mb-10">Purchase Request Item Details</p>

      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Available Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Request Quantity
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Item Category
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Item Sub Category
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Item Measure Unit
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
            {tableData.length > 0
              ? tableData.map((item, index) => (
                  <div key={index} className="w-full  flex justify-around ">
                    <div className="w-10 flex text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {index + 1}
                      </p>
                    </div>
                    <div className="w-[350px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.item_name}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.total_shadow_qty}
                      </p>
                    </div>
                    <div className="w-[200px] flex text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.item_qty}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-centel ">
                        {item.item_category_name}
                      </p>
                    </div>
                    <div className="w-[200px] flex text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.item_sub_category_name}
                      </p>
                    </div>

                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.item_measure_unit}
                      </p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className=" flex items-center justify-center mt-5">
        <div>
          <h2 className="text-dialogHeader font-poppins font-semibold text-[26px] text-center">
            Uploaded File
          </h2>
          <hr className=" ml-[20%] w-[60%] border-solid border-b-2 border-black"></hr>
        </div>
      </div>
      <div className="bg-white justify-center items-center rounded-[26px] min-h-[400px] w-[80%] h-[60%] flex-shrink-0 mt-5 ml-[10%]">
        <main className="container mx-auto max-w-screen-lg h-full">
          <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
            Uploaded File
          </h1>

          {/* Check if selectedRecord.pr_doc exists */}
          {selectedRecord.pr_doc ? (
            <div className="flex justify-center">
              <a
                href={`${REACT_APP_API_ENDPOINT}/v1/supplychain/purchaseRequest/file/${selectedRecord.pr_doc}`}
                download={selectedRecord.pr_doc} // Make the file downloadable
                className="cursor-pointer"
              >
                {/* Display the file icon or image */}
                <img
                  className="h-[100px] w-[100px] object-contain"
                  src={FileIcon}
                  alt="File Icon"
                />
              </a>
            </div>
          ) : (
            <p>No file uploaded</p>
          )}
        </main>
      </div>

      <div className="  grid grid-cols-3 ml-6 mt-6 ">
        {/* Drag and drop area */}
        <div className="flex flex-col items-center justify-center mt-6">
          {imagePreview ? (
            <div>
              <img
                src={imagePreview}
                alt="Order By"
                className="w-40 h-40 rounded-lg mb-4"
              />
              <p className="text-black font-semibold ml-9 mt-2 text-[20px]">
                Order By
              </p>
            </div>
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Order By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Second Signature */}
        <div className="flex flex-col items-center justify-center">
          {imagePreviewsecond ? (
            <div className="flex flex-col items-center justify-center">
              <img
                src={imagePreviewsecond}
                alt="Approved By"
                className="w-40 h-40 rounded-lg mb-4"
              />
              <p className="text-black font-semibold ml-5 mt-2 text-[20px]">
                Approved By
              </p>
            </div>
          ) : (
            <div
              className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6"
              onDragOver={handleDragOversecond}
              onDrop={handleDropsecond}
            >
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Approved By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>
        {/* Thiered Signature */}
        {statusPR === "Prepared" && (
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOverthired}
            onDrop={handleDropthired}
          >
            {imagePreviewthired ? (
              <div>
                <img
                  src={imagePreviewthired}
                  alt="Prepared By"
                  className="w-40 h-40 rounded-lg mb-4"
                />
                <p className="text-black font-semibold ml-5 mt-2 text-[20px]">
                  Prepared By
                </p>
              </div>
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Prepared By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="w-full flex items-center justify-end gap-x-6 my-10 px-16">
        <button
          className="text-black font-bold text-2xl py-2 px-4 rounded-lg bg-transparent border border-black"
          onClick={handleReset}
        >
          Cancel
        </button>
        <button
          onClick={() => handleReject()}
          className="bg-[#AA4343] hover:bg-[#bc4749] text-white font-bold text-2xl py-2 px-4 rounded-lg"
        >
          Reject
        </button>
        <button
          onClick={() => handleSubmit()}
          className=" bg-[#00adef] hover:bg-[#495e33] text-white font-bold text-2xl py-2 px-4 rounded-lg"
        >
          {statusPR === "Approved" ? "Approved" : "Prepared"}
        </button>
      </div>
    </div>
  );
};

export default PurchaseRequestView;
