/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DialogBox from "./newPurchaseDialogBox";
import usePermissions from "../../../../components/permissions/permission";

const PRSubjectNav = () => {
  const { hasPermission } = usePermissions();
  const [showDialog, setShowDialog] = useState(false);
  const [dialogData, setDialogData] = useState({}); // State to store both sub_category_name and sub_category_id
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  // Open dialog and set data (sub_category_name and sub_category_id)
  const openDialog = (data) => {
    setDialogData(data);
    setShowDialog(true);
  };

  // Close dialog
  const closeDialog = () => {
    setShowDialog(false);
    setDialogData({}); // Clear dialog data when closed
  };

  const fetchSubjects = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/itemCategory/get-couple-category`
      );
      const data = await response.json();

      // Filter the data to only include items with item_category_name = "RAW"
      const filteredData = data.filter(
        (item) => item.item_category_name === "ELECTRONIC"
      );

      setItemCategoryData(filteredData);
      console.log("Filtered Items:", filteredData);
    } catch (error) {
      console.error("Error fetching Item Category data:", error);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Purchase Requests for Subjects
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6">
        {/* Map over itemCategoryData to render dynamic cards */}
        {itemCategoryData.map((item, index) => {
          // Calculate the permission ID dynamically based on the index (2630 + index)
          const permissionId = 2630 + index;

          // Check if the user has the permission for this item
          if (!hasPermission(permissionId)) {
            return null; // Don't render the card if the user lacks permission
          }

          return (
            <div
              key={index}
              className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card"
            >
              <div className="min-h-[110px] min-w-[150px]">
                <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                  {item.item_category_name} - {item.item_sub_category_name}
                </h2>
              </div>
              <div className="flex items-center">
                <div className="flex justify-end w-full">
                  <button
                    onClick={() =>
                      openDialog({
                        sub_category_name: item.item_sub_category_name,
                        sub_category_id: item.sub_category_id,
                      })
                    }
                    className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          );
        })}

        {/* DialogBox Component */}
        {showDialog && (
          <DialogBox
            onClose={closeDialog}
            status={dialogData.sub_category_name}
            subCategoryId={dialogData.sub_category_id} // Passing sub_category_id to the DialogBox
          />
        )}
      </div>
    </div>
  );
};

export default PRSubjectNav;
