/** @format */

// src/modules/hris/dashboard/card.jsx

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Employee from "../../../Assets/icons/EMPLOYEE IMAGE 1.png";
import Work from "../../../Assets/icons/Work locations image 1.png";
import Hierachy from "../../../Assets/icons/Hierarchy image 2 1.png";
import Salary from "../../../Assets/icons/Salary image 2.png";
import Money from "../../../Assets/icons/Money 2 image 1.png";
import Contact_Popup from "./contact_popup";
import usePermissions from "../../../components/permissions/permission";
import DocumentUploadPopup from "../../hris/dashboard/DocumentUploadPopup";

const cardsData = [
  {
    id: 1,
    header: "Employee",
    imgSrc: Employee,
    count: 10,
    headerClasses: "text-black",
    imgStyles: {
      width: "250px",
      height: "240px",
    },
    permissionId: 1062,
  },
  {
    id: 2,
    header: "Working Locations",
    imgSrc: Work,
    count: 15,
    headerClasses: "text-black",
    imgStyles: {
      width: "240px",
      height: "240px",
    },
    permissionId: 1063,
  },
  {
    id: 3,
    header: "Departments",
    imgSrc: Hierachy,
    count: 15,
    headerClasses: "text-black",
    imgStyles: {
      width: "200px",
      height: "230px",
    },
    permissionId: 1064,
  },
  {
    id: 5,
    header: "Overall Payroll Cost",
    imgSrc: Salary,
    count: 15,
    headerClasses: "text-black",
    imgStyles: {
      width: "240px",
      height: "240px",
    },
    permissionId: 1065,
  },
  {
    id: 6,
    header: "Average Salary Increment",
    imgSrc: Money,
    count: 15,
    headerClasses: "text-black",
    imgStyles: {
      width: "240px",
      height: "240px",
    },
    permissionId: 1066,
  },
];

const Card = () => {
  const { hasPermission } = usePermissions();
  const [showUploadPopup, setShowUploadPopup] = useState(false);

  const handleDownload = () => {
    // Handle file download logic
    console.log("Download button clicked");
  };

  return (
    <div className="bg-[#E5EDF9] h-screen overflow-x-hidden overflow-y-hidden">
      <div className="flex items-center justify-between mt-3">
        <div>
          <p className="text-left text-[40px] font-sans font-semibold ml-6 mt-6 text-headerColor">
            Dashboard
          </p>
        </div>
        {/* {hasPermission(1067) && (
          <div>
            <Contact_Popup />
          </div>
        )} */}
      </div>

      <div>
        <div className="grid grid-cols-3 gap-[60px] p-2 justify-center mt-6 ml-[40px] mb-10">
          {cardsData.map((card) =>
            hasPermission(card.permissionId) ? (
              <Link key={card.id} to={card.linkTo}>
                <div className="bg-white w-[400px] h-[240px] rounded-[40px] shadow-md p-2 flex items-center">
                  <div className="flex flex-col justify-between">
                    <h2
                      className={`text-[30px] font-sans font-semibold ml-4 mb-10 opacity-460 text-headerColor ${card.headerClasses}`}
                    >
                      {card.header}
                    </h2>
                    <p className="text-[20px] ml-4 bg-[#74d4f4] text-[#FFFF] font-bold font-sans p-2 rounded-[30px] max-w-[100px] text-center">
                      {card.count}
                    </p>
                  </div>
                  <img
                    src={card.imgSrc}
                    alt={card.header}
                    className={`${card.imgClasses} ml-auto`}
                    style={card.imgStyles}
                  />
                </div>
              </Link>
            ) : null
          )}
          {hasPermission(1068) && (
            <div className="bg-white w-[400px] h-[240px] rounded-[40px] shadow-md p-2 flex flex-col items-center justify-center">
              <h2 className="text-[30px] font-sans font-semibold mb-4 text-headerColor">
                HR Documents
              </h2>
              {hasPermission(1069) && (
                <button
                  className="bg-[#74d4f4] font-bold text-white w-[180px] px-4 py-2 rounded-[20px] mb-4"
                  onClick={() => setShowUploadPopup(true)}
                >
                  Upload Document
                </button>
              )}
              {hasPermission(1071) && (
                <button
                  className="bg-green-500 font-bold text-white px-4 py-2 rounded-[20px]"
                  onClick={handleDownload}
                >
                  Download Document
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {showUploadPopup && (
        <DocumentUploadPopup onClose={() => setShowUploadPopup(false)} />
      )}
    </div>
  );
};

export default Card;
