/** @format */

import React, { useState } from "react";
import "./updateSupplier.css";
import axios from "axios";
import AsyncSelect from "react-select/async";
import usePermissions from "../../../components/permissions/permission";

export default function UpdateSupplier() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");
  const [Suppliers, setSuppliers] = useState([]);

  const [supplier_id, setSupplier_id] = useState("");
  const [supplier_name, setSupplier_name] = useState("");
  const [supplier_nic, setSupplier_nic] = useState("");
  const [supplier_address, setSupplier_address] = useState("");
  const [supplier_contact, setSupplier_contact] = useState("");
  const [supplier_email, setSupplier_email] = useState("");
  const [supplier_update_date, setSupplier_update_date] = useState("");
  const [supplier_update_user_id, setSupplier_update_user_id] = useState("");

  const SupplierSearchHandler = async (inputValue) => {
    if (inputValue !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/supplier/search/${inputValue}`
        );
        // console.log(res.data)
        const options = res.data.map((supplier) => ({
          value: supplier.supplier_id,
          label: supplier.supplier_name,
        }));
        setSuppliers(options);
        return options;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
  };

  const SupplierSelectHandler = async (supplier) => {
    console.log(supplier);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/supplier/${supplier.value}`
      );
      // console.log(res.data)
      setSupplier_id(res.data[0].supplier_id);
      setSupplier_name(res.data[0].supplier_name);
      setSupplier_nic(res.data[0].supplier_nic);
      setSupplier_address(res.data[0].supplier_address);
      setSupplier_contact(res.data[0].supplier_contact_no);
      setSupplier_email(res.data[0].supplier_email);
      setSupplier_update_date(res.data[0].supplier_update_date);
      setSupplier_update_user_id(res.data[0].supplier_update_user_id);
    } catch (error) {
      window.alert("Error fetching supplier details");
    }
  };

  const UpdateHandler = async () => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/supplier/update/${supplier_id}`,
        {
          supplier_id,
          supplier_name,
          supplier_nic,
          supplier_address,
          supplier_contact,
          supplier_email,
          supplier_update_date,
          supplier_update_user_id,
        }
      );
      // console.log(res.data)
      window.alert("Supplier Updated");
      setSupplier_id("");
      setSupplier_name("");
      setSupplier_nic("");
      setSupplier_address("");
      setSupplier_contact("");
      setSupplier_email("");
      setSupplier_update_date("");
      setSupplier_update_user_id("");
    } catch (error) {
      window.alert("Error updating supplier");
    }
  };

  const DeleteHandler = async () => {
    if (supplier_id === "") {
      window.alert("Please select a supplier to delete");
    } else {
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/supplier/${supplier_id}`
        );
        if (res.status === 200) {
          window.alert("Supplier Deleted");
          setSupplier_id("");
          setSupplier_name("");
          setSupplier_nic("");
          setSupplier_address("");
          setSupplier_contact("");
          setSupplier_email("");
          setSupplier_update_date("");
          setSupplier_update_user_id("");
        }
      } catch (error) {
        window.alert("Error deleting supplier");
      }
    }
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      borderColor: state.isFocused ? "grey" : "red",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  return (
    <div className="p-10 bg-background">
      <p className="header-1">Update Supplier</p>
      <div className="UpdateSupplier-search-div-main">
        <div className="UpdateSupplier-search-div">
          <label className="label"> Find Supplier</label>
          <label className="label">:</label>

          {hasPermission(1600) && (
            <AsyncSelect
              className="w-80"
              styles={customStyles}
              cacheOptions
              loadOptions={SupplierSearchHandler}
              // defaultOptions
              onChange={SupplierSelectHandler}
              value={Suppliers}
            />
          )}
        </div>
        <div className="w-full flex items-center justify-center"></div>
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center"> Supplier Details</p>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1"> Supplier ID</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={supplier_id}
              onChange={(e) => setSupplier_id(e.target.value)}
              disabled
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1"> Supplier Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={supplier_name}
              onChange={(e) => {
                setSupplier_name(e.target.value);
              }}
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1"> Supplier NIC</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={supplier_nic}
              onChange={(e) => {
                setSupplier_nic(e.target.value);
              }}
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1"> Supplier Address</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={supplier_address}
              onChange={(e) => {
                setSupplier_address(e.target.value);
              }}
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1"> Supplier Contact No</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={supplier_contact}
              onChange={(e) => {
                setSupplier_contact(e.target.value);
              }}
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1"> Supplier E-mail</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={supplier_email}
              onChange={(e) => {
                setSupplier_email(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 mb-10 px-[100px]">
          {hasPermission(1610) && (
            <button className="w-[118px] h-[45px] flex items-center justify-center  bg-[#00adef] border rounded-[23px] px-4">
              <p className="text-white" onClick={UpdateHandler}>
                UPDATE
              </p>
            </button>
          )}{" "}
          {hasPermission(1620) && (
            <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4">
              <p className="text-white" onClick={DeleteHandler}>
                DELETE
              </p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
