/** @format */

import React, { useState, useEffect } from "react";
import UploadIcon from "../../../../../Assets/icons/Upload.png";
import fileIcon from "../../../../../Assets/icons/png.png";

const Edit_Upcoming_Exams = ({ onClose, examData }) => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    examination_name: "",
    examination_time: "",
    examination_date: "",
    examination_purpose: "",
    examination_candidates: "",
    examination_progress_file_path: "",
    examination_progress_file_name: "",
  });

  useEffect(() => {
    if (examData) {
      setFormData({
        examination_name: examData.examination_name || "",
        examination_time: examData.examination_time || "",
        examination_date: examData.examination_date || "",
        examination_purpose: examData.examination_purpose || "",
        examination_candidates: examData.examination_candidates || "",
        examination_progress_file_path:
          examData.examination_progress_file_path || "",
        examination_progress_file_name:
          examData.examination_progress_file_name || "",
      });
    }
  }, [examData]);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Safely parse candidates to avoid runtime errors
  const candidateList = formData.examination_candidates
    ? formData.examination_candidates.split(",")
    : [];

  const handleSubmit = async () => {
    const putData = {
      examination_name: formData.examination_name,
      examination_date: formData.examination_date,
      examination_time: formData.examination_time,
      examination_purpose: formData.examination_purpose,
      examination_candidates: formData.examination_candidates
        .split(", ")
        .map((item) => item.trim()),
    };

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/performance/examination/updateexamination?examination_id=${examData.examination_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(putData),
        }
      );

      if (response.ok) {
        alert("Examination updated successfully!");

        // Check if there is a file selected for upload
        if (selectedFile) {
          await uploadExaminationProgress(selectedFile);
        }

        onClose(); // Close the modal after successful update
      } else {
        const errorData = await response.json();
        alert(`Failed to update examination: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error updating examination:", error);
      alert("An error occurred while updating the examination.");
    }
  };

  const uploadExaminationProgress = async (file) => {
    const formData = new FormData();
    formData.append("examination_id", examData.examination_id);
    formData.append("examination_name", formData.examination_name);
    formData.append("examination_progress", file);

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/performance/examination/uploadexaminationprogress`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("File uploaded successfully:", responseData);
        alert("File uploaded successfully!");
      } else {
        const errorData = await response.json();
        alert(`Failed to upload file: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("An error occurred while uploading the file.");
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white rounded-[32px] p-6">
        <p className="font-sans text-left text-[22px] font-bold mb-4 text-[#797C80]">
          Edit Examination Details
          <hr className="line border-t border-gray-300 w-[45%]" />
        </p>

        {/* Form */}
        <div>
          <div className="grid grid-cols-2 gap-[120px] ml-[5%]">
            {/* Section 01 */}
            <div>
              <div className="flex justify-start ">
                <label className="font-sans">Examination Name</label>
              </div>
              <div className="mb-5">
                <input
                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  name="examination_name"
                  value={formData.examination_name}
                  onChange={handleChange}
                />
              </div>

              <div className="">
                <div className="flex justify-start ">
                  <label className="font-sans">Time</label>
                </div>
                <div className="">
                  <input
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                    name="examination_time"
                    value={formData.examination_time}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {/* Section 02 */}
            <div>
              <div className="gap-3 font-sans font-semibold ml-2">
                <div className="flex justify-start">
                  <div className="ml-[-30px]">
                    <p> Dates</p>
                  </div>
                </div>
                <div className="ml-[-120px]">
                  <input
                    type="date"
                    required=""
                    placeholder="Search..."
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                    name="examination_date"
                    value={formData.examination_date}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="mt-5 ml-2">
                <div className="flex justify-start">
                  <div className="ml-[-30px]">
                    <label className="font-sans">Purpose</label>
                  </div>
                </div>
                <div className="ml-[-120px]">
                  <input
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#74d4f4] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                    name="examination_purpose"
                    value={formData.examination_purpose}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Display candidates */}
          <div className="mt-5">
            <h3 className="font-bold mb-2">Candidates:</h3>
            {candidateList.map((candidate, index) => (
              <div
                key={index}
                className="bg-[#74d4f4] mt-1 text-white h-[30px] w-[90px] rounded-md mr-2 ml-5"
              >
                <div className="ml-2">{candidate.trim()}</div>
              </div>
            ))}
          </div>

          <div className="mt-10">
            {formData.examination_progress_file_path ? (
              <div className="flex gap-5 items-center">
                <img
                  src={fileIcon}
                  alt="File Icon"
                  className="w-7 h-7 cursor-pointer"
                />
                <div className="cursor-pointer">
                  {formData.examination_progress_file_name}
                </div>
              </div>
            ) : (
              <div className="bg-white mt-7 rounded-[32px]">
                <div>
                  <p className="text-center font-sans text-green-500 text-[17px] font-semibold opacity-60">
                    Upload Progress of Candidates
                  </p>
                  <p className="font-sans text-[13px] opacity-45 text-center">
                    Select and upload the files of your choice
                  </p>
                </div>
                <hr className="border-t-4 border-gray-400" />
                <div className="mt-5 flex justify-center">
                  <div className="bg-[#E1E6DC] w-[50%] border border-dotted border-black rounded-[32px] p-5">
                    <div className="h-8 flex items-center ml-[45%]">
                      <img src={UploadIcon} alt="upload-icon" />
                    </div>
                    <div className="h-8">
                      <p className="text-[14px] font-sans font-semibold text-center mt-3">
                        Choose a PDF file or drag & drop it here
                      </p>
                    </div>
                    <div className="h-8">
                      <p className="font-sans text-sm opacity-30 text-center">
                        Up to 50MB
                      </p>
                    </div>
                    <div className="h-8 flex justify-center">
                      <label className="text-center block text-black rounded-[30px] bg-white p-1 w-[100px] font-semibold cursor-pointer hover:text-blue-700">
                        Browse
                        <input
                          type="file"
                          className="hidden"
                          onChange={handleFileSelect}
                        />
                      </label>
                    </div>
                    {/* Display selected file */}
                    {selectedFile && (
                      <div className="h-8 mt-3 flex items-center">
                        <img
                          src={fileIcon}
                          alt="pdf-icon"
                          className="w-8 h-8 mr-2"
                        />
                        <p className="font-sans text-sm">{selectedFile.name}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end items-center">
          <button
            onClick={onClose}
            className="font-bold p-1 px-4 mt-4 rounded-[32px text-black mr-5"
          >
            Close
          </button>
          <button
            onClick={handleSubmit}
            className=" bg-[#00adef] mt-4 p-1 w-[4rem] text-white rounded-[20px]"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit_Upcoming_Exams;
